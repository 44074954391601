import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';

import { navigate } from '@reach/router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Auth } from 'aws-amplify';
import { useCampaigns } from '../../features/campaign';
import Config from '../../config';
import { Box } from '../elements';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { formatMoney } from '../../utils/helpers';
import { debug, error } from '../../utils/logging';
import { DimmedLoaderWrapper } from './loader';

let newData = [];
export default function CampaignBalanceTable({
  campaigns,
  pkPayee,
  payee,
  setSelectedCampaignId,
  setTabIndex
}) {

  const [state, setState] = useState({
    columns: [
      // { title: 'ID', field: 'id' },
      {
        title: 'Target',
        field: 'target',
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        },
        editComponent: (props) => <div />,
        render: (rowData) => {
          return <p>{`${rowData.target.entityName}`}</p>;
        }
      },
      {
        title: 'Title',
        field: 'title',
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        },
        editComponent: (props) => <div />
      },
      {
        title: 'Pending Balance',
        field: 'pending',
        render: (rowData) => {
          if (typeof rowData.pending === 'undefined') {
            return <CircularProgress color="secondary" size={15} />;
          }
          return (
            <p>{`$${formatMoney(rowData.pending / 100.0, 2, '.', ',')}`}</p>
          );
        },
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: 'Available Balance',
        field: 'available',
        render: (rowData) => {
          if (typeof rowData.available === 'undefined') {
            return <CircularProgress color="secondary" size={15} />;
          }
          return (
            <p>{`$${formatMoney(rowData.available / 100.0, 2, '.', ',')}`}</p>
          );
        },
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: 'Payment Total',
        field: 'paymentTotal',
        render: (rowData) => {
          if (typeof rowData.paymentTotal === 'undefined') {
            return <CircularProgress color="secondary" size={15} />;
          }
          return (
            <Box
              color="primary.0"
              css={`
                &:hover {
                  cursor: pointer !important;
                }
              `}
              onClick={() => {
                setSelectedCampaignId(rowData.pkCampaign);
                setTabIndex(1);
              }}
            >{`$${formatMoney(
              rowData.paymentTotal / 100.0,
              2,
              '.',
              ','
            )}`}</Box>
          );
        },
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      }
    ]
  });
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(new Date());
 

  const fetchBulkCampaignBalance = async (campaignIds) => {
    return fetcher({
      method: 'POST',
      url: `${Config.getBulkCampaignBalances}`,
      data: { campaignIds },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const fetchBulkPaymentItems = async (campaignIds) => {
    return fetcher({
      method: 'POST',
      url: `${Config.getBulkPaymentItems}`,
      data: { campaignIds },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    const setupData = async (c) => {
      newData = [...c];

      const campaignIds = newData.map((c) => c.pkCampaign);

      const paymentItemsResponse = await fetchBulkPaymentItems(campaignIds);
      const paymentItemData = paymentItemsResponse.data.data;

      const balancesResponse = await fetchBulkCampaignBalance(campaignIds);
      const { data } = balancesResponse;
      const balancesData = data.balances;

      newData.forEach((campaign) => {
        // HANDLE PAYMENTS
        const amountArray = paymentItemData[campaign.pkCampaign];
        const paymentTotal = amountArray
          .map((a) => a.amount)
          .reduce((a, b) => a + b, 0);
        campaign.paymentTotal = paymentTotal;

        // HANDLE BALANCES
        const balances = balancesData[campaign.pkCampaign].data;
        // available amount
        const availableArray = balances.filter((b) => b.status === 'available');
        const availableBalance = availableArray
          .map((a) => a.net)
          .reduce((a, b) => a + b, 0);
        campaign.available = availableBalance;

        // pending amount
        const pendingBalanceArray = balances.filter(
          (b) => b.status === 'pending'
        );
        const pendingBalance = pendingBalanceArray
          .map((p) => p.net)
          .reduce((a, b) => a + b, 0);
        campaign.pending = pendingBalance;
      });
    };

    if (campaigns) {
      setLoading(true);
      setupData(campaigns)
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [campaigns]);

  const theme = createMuiTheme({
    overrides: {
      MuiInput: { root: { height: '30px' } },
      MuiIconButton: {
        root: {
          // color: '#fff',
          color: '#A7C957 !important',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            transition: 'all 0.5s ease-in-out'
          }
        }
        // active: {
        //   color: '#fff'
        // }
      }
    },
    palette: {
      primary: {
        main: '#A7C957',
        light: '#b8d378',
        dark: '#748c3c',
        contrastText: '#fff'
      }
    },
    typography: { htmlFontSize: 16 }
  });

  if (!campaigns) {
    return <Box />;
  }

  if (loading) {
    return (
      <Box minHeight="500px" minWidth="500px">
        <DimmedLoaderWrapper active={loading} disableDimming />
      </Box>
    );
  }

  // debug('***', newData)
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={state.columns}
        components={{
          Header: (props) => <MTableHeader {...props} />,
          Toolbar: (props) => (
            <div style={{ backgroundColor: '#fff' }}>
              <MTableToolbar {...props} />
            </div>
          )
        }}
        data={newData}
        options={{
          grouping: false,
          // actionsColumnIndex: 5,
          search: true,
          rowStyle: {
            '&:nthOfType(odd)': { backgroundColor: theme.palette.action.hover }
          },
          headerStyle: {
            backgroundColor: '#A7C957',
            color: '#FFF'
          },
          searchFieldStyle: {
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87)'
          }
        }}
        search={false}
        title={`Campaign History for: ${
          payee == null ? pkPayee : payee.displayName
        }`}
      />
    </MuiThemeProvider>
  );
}
