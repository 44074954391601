import React from 'react'
import ReactDateTimePicker from 'react-datetime-picker/dist/entry.nostyle'
import styled from 'styled-components'
import { lighten } from 'polished'
import { PropTypes } from 'prop-types'

const BaseDateTimePicker = styled(ReactDateTimePicker)`
  display: inline-flex;
  position: relative;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
`

const reactDateTimePicker = 'react-datetime-picker'
const disabled = `${reactDateTimePicker}--disabled`
const wrapper = `${reactDateTimePicker}__wrapper`

const inputGroup = `${reactDateTimePicker}__inputGroup`

const button = `${reactDateTimePicker}__button`
const calendar = `${reactDateTimePicker}__calendar`
// const clock = `${reactDateTimePicker}__clock`

// Calendar
const reactCalendar = 'react-calendar'
const doubleView = `${reactCalendar}--doubleView`
const navigation = `${reactCalendar}__navigation`
const monthView = `${reactCalendar}__month-view`

const yearView = `${reactCalendar}__year-view`
const decadeView = `${reactCalendar}__decade-view`
const centuryView = `${reactCalendar}__century-view`

const tile = `${reactCalendar}__tile`

const selectRange = `${reactCalendar}--selectRange`

const nowColor = lighten(0.3, 'rgb(220, 220, 0)')
const hasActiveColor = lighten(0.3, 'rgb(0, 110, 220)')
const activeColor = 'rgb(0, 110, 220)'

const MyDateTimePicker = styled(BaseDateTimePicker).attrs({})`
  .${disabled} {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  .${wrapper} {
    display: flex;
    background-color: #dddddd;
    padding: 0 1rem;
  }

  .${inputGroup} {
    /* @digit-width: .54em;
    @colon-width: .217em; */
    /* // own padding + inputs padding + digits width + dots width */
    min-width: 5rem;
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: 2rem;
      height: calc(100% - 2px);
      position: relative;
      padding: 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: fade(red, 10%);
      }

      &--hasLeadingZero {
        margin-left: 0.54em;
        padding-left: calc(~'1px + ' 0.54em);
      }
    }

    &__amPm {
      font: inherit;
      -moz-appearance: menulist;
    }
  }

  .${button} {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-datetime-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-datetime-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  .${calendar} {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }

    .react-calendar {
      border-width: thin;

      width: 350px;
      max-width: 100%;
      background: white;
      /* border: 1px solid rgb(160, 160, 150); */
      font-family: inherit;
      line-height: 1.125em;

      &,
      & *,
      & *:before,
      & *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      button {
        margin: 0;
        border: 0;
        outline: none;

        &:enabled {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .${doubleView} {
        width: 700px;

        .react-calendar__viewContainer {
          display: flex;
          margin: -0.5em;

          > * {
            width: 50%;
            margin: 0.5em;
          }
        }
      }

      .${navigation} {
        height: 44px;
        margin-bottom: 1em;

        button {
          min-width: 44px;
          background: none;

          &:enabled {
            &:hover,
            &:focus {
              background-color: rgb(230, 230, 230);
            }
          }

          &[disabled] {
            background-color: rgb(240, 240, 240);
          }
        }
      }

      .${monthView} {
        &__weekdays {
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.75em;

          &__weekday {
            padding: 0.5em;
          }
        }

        &__weekNumbers {
          font-weight: bold;

          .react-calendar__tile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.75em;
            padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
          }
        }

        &__days {
          &__day {
            &--weekend {
              color: rgb(209, 0, 0);
            }

            &--neighboringMonth {
              color: rgb(117, 117, 117);
            }
          }
        }
      }

      .${yearView}, .${decadeView}, .${centuryView} {
        .react-calendar__tile {
          padding: 2em 0.5em;
        }
      }

      .${tile} {
        max-width: 100%;
        text-align: center;
        padding: 0.75em 0.5em;
        background: none;

        &:disabled {
          background-color: rgb(240, 240, 240);
        }

        &:enabled {
          &:hover,
          &:focus {
            background-color: rgb(230, 230, 230);
          }
        }

        &--now {
          background: ${nowColor};

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${nowColor}`)};
            }
          }
        }

        &--hasActive {
          background: ${hasActiveColor};

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${hasActiveColor}`)};
            }
          }
        }

        &--active {
          background: ${activeColor};
          color: white;

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${activeColor}`)};
            }
          }
        }
      }

      .${selectRange} {
        .react-calendar__tile {
          &--hover {
            background-color: rgb(230, 230, 230);
          }
        }
      }
    }
  }
`

// .${calendar} {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   z-index: 1;
//   width: 350px;
//   max-width: 100vw;
//   background: blue;

//   &--closed {
//     display: none;
//   }

//   .react-calendar {
//     border-width: thin;

//     width: 350px;
//     max-width: 100%;
//     background: white;
//     /* border: 1px solid rgb(160, 160, 150); */
//     font-family: inherit;
//     line-height: 1.125em;

//     &,
//     & *,
//     & *:before,
//     & *:after {
//       -moz-box-sizing: border-box;
//       -webkit-box-sizing: border-box;
//       box-sizing: border-box;
//     }

//     button {
//       margin: 0;
//       border: 0;
//       outline: none;

//       &:enabled {
//         &:hover {
//           cursor: pointer;
//         }
//       }
//     }

//     .${doubleView} {
//       width: 700px;

//       .react-calendar__viewContainer {
//         display: flex;
//         margin: -0.5em;

//         > * {
//           width: 50%;
//           margin: 0.5em;
//         }
//       }
//     }

//     .${navigation} {
//       background: red;
//       height: 44px;
//       margin-bottom: 1em;

//       button {
//         min-width: 44px;
//         background: none;

//         &:enabled {
//           &:hover,
//           &:focus {
//             background-color: rgb(230, 230, 230);
//           }
//         }

//         &[disabled] {
//           background-color: rgb(240, 240, 240);
//         }
//       }
//     }

//     .${monthView} {
//       &__weekdays {
//         text-align: center;
//         text-transform: uppercase;
//         font-weight: bold;
//         font-size: 0.75em;

//         &__weekday {
//           padding: 0.5em;
//         }
//       }

//       &__weekNumbers {
//         font-weight: bold;

//         .react-calendar__tile {
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           font-size: 0.75em;
//           padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
//         }
//       }

//       &__days {
//         &__day {
//           &--weekend {
//             color: rgb(209, 0, 0);
//           }

//           &--neighboringMonth {
//             color: rgb(117, 117, 117);
//           }
//         }
//       }
//     }

//     .${yearView}, .${decadeView}, .${centuryView} {
//       .react-calendar__tile {
//         padding: 2em 0.5em;
//       }
//     }

//     .${tile} {
//       max-width: 100%;
//       text-align: center;
//       padding: 0.75em 0.5em;
//       background: none;

//       &:disabled {
//         background-color: rgb(240, 240, 240);
//       }

//       &:enabled {
//         &:hover,
//         &:focus {
//           background-color: rgb(230, 230, 230);
//         }
//       }

//       &--now {
//         background: ${nowColor};

//         &:enabled {
//           &:hover,
//           &:focus {
//             background: ${lighten(0.1, `${nowColor}`)};
//           }
//         }
//       }

//       &--hasActive {
//         background: ${hasActiveColor};

//         &:enabled {
//           &:hover,
//           &:focus {
//             background: ${lighten(0.1, `${hasActiveColor}`)};
//           }
//         }
//       }

//       &--active {
//         background: ${activeColor};
//         color: white;

//         &:enabled {
//           &:hover,
//           &:focus {
//             background: ${lighten(0.1, `${activeColor}`)};
//           }
//         }
//       }
//     }

//     .${selectRange} {
//       .react-calendar__tile {
//         &--hover {
//           background-color: rgb(230, 230, 230);
//         }
//       }
//     }
//   }
// }

// .${clock} {
//   width: 200px;
//   height: 200px;
//   max-width: 100vw;
//   padding: 25px;
//   background-color: white;
//   border: thin solid rgb(160, 160, 150);

//   position: absolute;
//   top: 100%;
//   left: 0;
//   z-index: 1;

//   &--closed {
//     display: none;
//   }

//   .react-clock {
//   }
// }

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
}

const DateTimePicker = ({ value, onChange }) => (
  <MyDateTimePicker disableClock onChange={onChange} value={value} />
)

DateTimePicker.propTypes = propTypes

export { DateTimePicker }
