import styled from 'styled-components'
import { Text } from './text'

export const Heading = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.title};
`

Heading.defaultProps = {
  as: 'h2',
}
