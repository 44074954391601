export const LS_KEYS = {
  token: 'token',
  userId: 'userId',
  fullName: 'fullName',
  associationDomain: 'associationDomain',
  defaultTeamId: 'defaultTeamId',
  roles: 'roles',
  isSSL: 'isSSL',
}

const getItem = key => {
  const item = localStorage.getItem(key)
  try {
    return JSON.parse(item)
  } catch (e) {
    console.error('Error', e)
    return null
  }
}

const setItem = (key, data) => {
  if (!data) {
    console.error('You gave local storage nothing!')
    return
  }

  try {
    localStorage.setItem(key, JSON.stringify(data))
  } catch (e) {
    console.error('Error setting', e)
  }
}

const removeItem = key => localStorage.removeItem(key)

export default { getItem, setItem, removeItem }
