import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Text } from './text';

const propTypes = {
  newTab: PropTypes.bool
};

const linkVariants = ({ theme, variant, isSelected }) => {
  if (!variant) return '';

  const { space, colors, fontSizes } = theme;

  let styles;

  switch (variant) {
    case 'navbar':
      styles = `
        color: ${
          isSelected ? colors.black : theme.muiTheme.palette.primary.main
        };
        margin: ${space[2]};
        white-space: nowrap;
        transition: color 0.3s;
        ${isSelected ? `border-bottom: 1px solid ${colors.black};` : ''}
        font-weight: ${isSelected ? '700' : 'normal'};

        :hover {
            color: ${colors.black};
            text-decoration: none;
        }
      `;
      break;

    case 'highlight':
      styles = `
          font-size: ${fontSizes[1]};
          color: ${isSelected ? colors.primary[0] : colors.greys[10]};
          margin: ${space[2]};
          transition: color 0.3s;
          ${isSelected ? `border-bottom: 1px solid ${colors.primary[0]};` : ''}
          font-weight: ${isSelected ? '700' : 'normal'};
  
          :hover {
              color: ${colors.primary[0]};
              text-decoration: none;
          }
        `;
      break;

    case 'uploader':
      styles = `
          font-size: ${fontSizes[2]};
          color: ${isSelected ? colors.primary[0] : colors.greys[10]};
          margin: 0;
          transition: color 0.3s;
          ${isSelected ? `border-bottom: 1px solid ${colors.primary[0]};` : ''}
          font-weight: ${isSelected ? '700' : 'normal'};
  
          :hover {
              color: ${colors.primary[0]};
              text-decoration: none;
          }
        `;
      break;

    case 'profile':
      styles = `
        :hover {
          text-decoration: none;
        }
        
      `;
      break;
    default:
      break;
  }

  return styles;
};

const StyledLink = styled(Text)`
  &:hover {
    color: ${({ theme, color }) => theme.colors[color]};
  }

  ${linkVariants}
`;

export const Link = ({ newTab, ...props }) => {
  let tabProps = {};

  if (newTab) {
    tabProps = {
      target: '_blank',
      rel: 'noopener noreferrer'
    };
  }

  return <StyledLink {...props} {...tabProps} />;
};

Link.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  as: 'a',
  newTab: false
};

Link.propTypes = propTypes;
