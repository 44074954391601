import styled from 'styled-components'
import { typography } from 'styled-system'
import { Box } from './box'
import { buttonLinkStyles } from '../../styles'

const disabled = ({ theme }) => `
  &:disabled {
    cursor: not-allowed;
    background: ${theme.colors.greys[2]};
    color: ${theme.colors.greys[4]};
  }`

export const Button = styled(Box)`
  &:hover {
    cursor: pointer;
  }

  ${disabled}
  ${buttonLinkStyles}
  ${typography}
`

Button.defaultProps = {
  as: 'button',
  type: 'button',
}
