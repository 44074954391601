import React, { useReducer, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IoIosCloseCircleOutline,
  IoIosCheckmarkCircleOutline,
} from 'react-icons/io'
import { Box, Button, Input, Text } from '../../components/elements'
import { Message, Loading } from '../../components/compound'
import { useAuthService } from './auth.provider'
import { RegexList, checkString, LengthRequirement } from '../../utils/password'

const propTypes = {
  changeView: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func,
}

const defaultProps = {
  changeView: null,
  fullScreen: false,
  navigate: null,
}

const initialState = {
  token: '',
  userEmail: '',
  newPassword: '',
  passwordConfirmation: '',
  hasCapital: false,
  hasNumber: false,
  hasSymbol: false,
  hasLength: false,
  isSame: false,
  isDisabled: true,
  errorMsg: '',
  success: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'input': {
      return {
        ...state,
        [action.name]: action.payload,
      }
    }

    case 'token': {
      return {
        ...state,
        token: action.payload,
      }
    }

    case 'newPassword': {
      const hasCap = checkString(action.payload, RegexList.capRegex)
      const hasNum = checkString(action.payload, RegexList.numRegex)
      const hasSym = checkString(action.payload, RegexList.symRegex)
      const hasLen = action.payload.length >= LengthRequirement
      const isSame = action.payload === state.passwordConfirmation
      const isDisabled = !hasCap || !hasNum || !hasSym || !hasLen || !isSame
      return {
        ...state,
        newPassword: action.payload,
        hasCapital: hasCap,
        hasNumber: hasNum,
        hasSymbol: hasSym,
        hasLength: hasLen,
        isSame,
        isDisabled,
      }
    }

    case 'passwordConfirmation': {
      const isSame = action.payload === state.newPassword
      const isDisabled =
        !state.hasCapital ||
        !state.hasNumber ||
        !state.hasSymbol ||
        !state.hasLength ||
        !isSame
      return {
        ...state,
        passwordConfirmation: action.payload,
        isSame,
        isDisabled,
      }
    }

    case 'errorMsg': {
      return {
        ...state,
        errorMsg: action.payload,
        success: false,
      }
    }

    case 'email': {
      return {
        ...state,
        userEmail: action.payload,
      }
    }

    case 'reset': {
      return initialState
    }

    case 'success': {
      return {
        ...state,
        success: true,
        errorMsg: '',
      }
    }

    default:
      return state
  }
}

const ResetPassword = ({ changeView, fullScreen, navigate }) => {
  const {
    onResetPassword,
    onReset,
    error,
    clearErrors,
    loading,
    passwordResetSuccessful,
    email,
  } = useAuthService()
  const tokenRef = useRef()

  const [formState, dispatch] = useReducer(reducer, initialState)
  const {
    hasCapital,
    hasNumber,
    hasSymbol,
    hasLength,
    isSame,
    isDisabled,
    errorMsg,
    success,
  } = formState

  useEffect(() => {
    if (tokenRef && tokenRef.current) {
      tokenRef.current.focus()
    }

    // FORCE RESET
    dispatch({
      type: 'reset',
      payload: null,
    })

    dispatch({
      type: 'email',
      payload: email,
    })
  }, [])

  useEffect(() => {
    if (error) {
      let message = ''
      if (!error.response) {
        message = error
      } else if (!error.response.data) {
        message = error.response
      } else {
        message = error
      }
      dispatch({
        type: 'errorMsg',
        payload: message,
      })
    }
  }, [error, errorMsg])

  useEffect(() => {
    if (passwordResetSuccessful) {
      dispatch({
        type: 'success',
      })
    }
  }, [passwordResetSuccessful])

  const handleSubmit = e => {
    e.preventDefault()
    if (!isDisabled) {
      const resetPassword = async () => {
        await onResetPassword(formState)
      }
      resetPassword()
    } else if (hasCapital && hasNumber && hasSymbol && hasLength && isSame) {
      dispatch({
        type: 'errorMsg',
        payload: 'Please review your reset code',
      })
    } else {
      dispatch({
        type: 'errorMsg',
        payload: 'Please fulfil below criteria',
      })
    }
  }

  const handleChange = e => {
    const { value, name } = e.target

    dispatch({
      // type: name === 'Password' ? 'password' : 'input',
      type: name,
      payload: value,
    })
  }

  const viewSwitch = view => {
    clearErrors()

    if (fullScreen) {
      navigate(`/${view}`)
      return
    }

    changeView(view)
  }

  let errorMessage = ''
  if (error || errorMsg) {
    if (errorMsg) {
      errorMessage = errorMsg
    } else {
      errorMessage = error
    }
  }

  const showSuccess = !loading && !errorMessage && success
  if (showSuccess) {
    onReset()
    setTimeout(viewSwitch, 4000, 'login')
  }
  return (
    <Box flexDirection="column" minWidth="10rem" width="100%">
      <Text fontSize={5} fontWeight={[0]} mb={5}>
        Reset Password
      </Text>

      {showSuccess ? (
        <Text>Password was successfully reset. Please login.</Text>
      ) : (
        <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
          <Input
            ref={tokenRef}
            containerProps={{
              width: '100%',
              height: '70px',
            }}
            label="Verification Code"
            name="token"
            onChange={handleChange}
            required
            type="text"
            value={formState.token}
            variant="register"
          />
          <Input
            containerProps={{
              width: '100%',
              mt: 4,
              height: '70px',
            }}
            label="New Password"
            name="newPassword"
            onChange={handleChange}
            required
            type="password"
            value={formState.newPassword}
            variant="register"
          />
          <Input
            containerProps={{
              width: '100%',
              height: '70px',
            }}
            label="Confirm Password"
            name="passwordConfirmation"
            onChange={handleChange}
            required
            type="password"
            value={formState.passwordConfirmation}
            variant="register"
          />

          <Box flexDirection="column" mt={3}>
            <Box alignItems="center">
              <Box color={hasLength ? 'green' : 'red'} mr={2}>
                {hasLength ? (
                  <IoIosCheckmarkCircleOutline />
                ) : (
                  <IoIosCloseCircleOutline />
                )}
              </Box>
              Must be {LengthRequirement} characters or longer
            </Box>

            <Box alignItems="center">
              <Box color={hasCapital ? 'green' : 'red'} mr={2}>
                {hasCapital ? (
                  <IoIosCheckmarkCircleOutline />
                ) : (
                  <IoIosCloseCircleOutline />
                )}
              </Box>
              Must have a capital letter
            </Box>

            <Box alignItems="center">
              <Box color={hasNumber ? 'green' : 'red'} mr={2}>
                {hasNumber ? (
                  <IoIosCheckmarkCircleOutline />
                ) : (
                  <IoIosCloseCircleOutline />
                )}
              </Box>
              Must have a number
            </Box>

            <Box alignItems="center">
              <Box color={hasSymbol ? 'green' : 'red'} mr={2}>
                {hasSymbol ? (
                  <IoIosCheckmarkCircleOutline />
                ) : (
                  <IoIosCloseCircleOutline />
                )}
              </Box>
              Must have a symbol
            </Box>

            <Box alignItems="center">
              <Box color={isSame ? 'green' : 'red'} mr={2}>
                {hasSymbol ? (
                  <IoIosCheckmarkCircleOutline />
                ) : (
                  <IoIosCloseCircleOutline />
                )}
              </Box>
              Must match confirm password
            </Box>
          </Box>

          {errorMessage && (
            <Message mt={5} variant="error">
              {errorMessage}
            </Message>
          )}

          {loading ? (
            <Box mt={5}>
              <Loading dotBackgroundColor="black" />
            </Box>
          ) : (
            <Button
              alignSelf="flex-start"
              disabled={isDisabled}
              mt={5}
              type="submit"
              variant="login"
            >
              Set Password
            </Button>
          )}

          <Box alignItems="center" mt={3}>
            Already a Sportshead?
            <Button
              ml={2}
              onClick={() => viewSwitch('login')}
              type="button"
              variant="regularlink"
            >
              Login
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

ResetPassword.propTypes = propTypes
ResetPassword.defaultProps = defaultProps

export { ResetPassword }
