import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { formatMoney } from '../../utils/helpers';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)', // theme.palette.common.black'',
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

export default function CampaignPaymentsTable({ paymentItems, teams }) {
  const classes = useStyles();

  if (!paymentItems || paymentItems.length == 0) {
    return <div />;
  }

  const rows = paymentItems.map(p => {
    const { campaign } = p;
    return {
      id: campaign.pkCampaign,
      campaign,
      title: campaign.title,
      amount: `$${formatMoney(p.amount / 100.0, 2, '.', ',')}`
    };
  });
  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table" className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{ padding: '0' }}>
              Campaign ID
            </StyledTableCell>
            <StyledTableCell align="left" style={{ padding: '0' }}>
              Target
            </StyledTableCell>
            <StyledTableCell align="left" style={{ padding: '0' }}>
              Title
            </StyledTableCell>
            <StyledTableCell align="left" style={{ padding: '0' }}>
              Amount
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                component="th"
                scope="row"
                style={{ padding: '0' }}
              >
                {row.id}
              </StyledTableCell>
              <StyledTableCell align="left" style={{ padding: '0' }}>
                {row.campaign.target.entityName}
              </StyledTableCell>
              <StyledTableCell align="left" style={{ padding: '0' }}>
                {row.title}
              </StyledTableCell>
              <StyledTableCell align="left" style={{ padding: '0' }}>
                {row.amount}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
