import { Divider } from '@material-ui/core';
import { EditorState } from 'draft-js';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Button, Input } from 'semantic-ui-react';
import { Box, Text } from '../../components/elements';
import { CampaignCoverPreview } from './campaign-cover-preview';
import { useCampaigns } from './campaign.provider';
import { CreateCampaignCover } from './create-campaign-cover';

const CreateCampaignContentView = ({
  onSubmit,
  contentState,
  onEditorChange,
  onTitleChange
}) => {
  const { currentCampaign } = useCampaigns();
  const [imageViewMode, setImageViewMode] = useState(
    currentCampaign &&
      (currentCampaign.image ||
        currentCampaign.video ||
        currentCampaign.fileImage)
      ? 'preview'
      : 'upload'
  );

  const onEditorStateChange = (eState) => {
    onEditorChange(eState);
  };

  return (
    <Box style={{ flexDirection: 'column', flex: 1 }}>
      <Text flexGrow={1} mt="15px" style={{ fontSize: 16, fontWeight: '700' }}>
        Campaign Title
      </Text>
      <Input
        fluid
        onChange={(e, { value }) => onTitleChange(value)}
        placeholder="Campaign Title"
        required
        value={contentState.campaignTitle}
      />
      <Box flexDirection={['column', 'row']} style={{ flex: 1, marginTop: 20 }}>
        <Box width={['100%', '70%']}>
          <Box style={{ flex: 1, flexDirection: 'column' }}>
            <Text mt="15px" style={{ fontSize: 16, fontWeight: '700' }}>
              Multimedia Cover
            </Text>
            {imageViewMode === 'upload' && (
              <CreateCampaignCover
                changeView={(view) => setImageViewMode(view)}
              />
            )}
            {imageViewMode === 'preview' && (
              <CampaignCoverPreview
                changeView={(view) => setImageViewMode(view)}
              />
            )}
          </Box>
        </Box>
        <Divider
          orientation="vertical"
          style={{
            height: 'auto',
            marginRight: 20,
            marginLeft: 10
          }}
        />
        <Box flexDirection="column" marginTop={['20px', '0px']}>
          <Text
            flexGrow={1}
            mt="15px"
            style={{ fontSize: 16, fontWeight: '700' }}
          >
            Story / Description
          </Text>
          <Editor
            editorClassName="story-editor"
            editorState={contentState.editorState ?? EditorState.createEmpty()}
            editorStyle={{ paddingLeft: 10, paddingRight: 10 }}
            onEditorStateChange={(e) => {
              onEditorStateChange(e);
            }}
            placeholder="Tell us your story..."
            wrapperStyle={{
              border: '1px solid rgba(34, 36, 38, 0.15)',
              borderRadius: '0.285714rem'
            }}
          />
        </Box>
      </Box>
      <Button
        color="green"
        disabled={
          !contentState.campaignTitle ||
          contentState.campaignTitle.length < 1 ||
          !contentState.editorState ||
          contentState.editorState.getCurrentContent().getPlainText().length <
            1 ||
          !currentCampaign ||
          (!currentCampaign.video &&
            !currentCampaign.image &&
            !currentCampaign.fileImage)
        }
        onClick={() => {
          onSubmit();
        }}
        style={{ alignSelf: 'flex-end', marginTop: 20 }}
      >
        Next
      </Button>
    </Box>
  );
};

export default CreateCampaignContentView;
