// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Router, Location } from '@reach/router';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import Amplify from 'aws-amplify';

import { SnackbarProvider } from 'notistack';

import { MuiThemeProvider } from '@material-ui/core';
import { Navbar } from '../navigation';
import { AddPayee } from '../addpayee';
import { PayeeManagement } from '../payee-management';
import {
  CampaignViewSwitcher,
  CampaignProvider,
  CampaignManager,
  CampaignBrowser
} from '../campaign';
import { ContactUs } from './contact-us';
import { DonatePage } from '../donations/DonatePage';
import CampaignPage from '../donations/CampaignPage';
import { DonationFail } from '../donations/fail';
import { theme, GlobalStyle } from '../../styles';
import { ModalProvider } from '../../components/modules';
import {
  AuthProvider,
  Login,
  CreateAccount,
  ConfirmAccount,
  ForgotPassword,
  ResetPassword
} from '../auth';
import { Box } from '../../components/elements';
import { Admin } from '../admin';
import { Home } from './home';
import CheckoutPage from '../donations/CheckoutPage';
import { Accounting } from '../payee-management/accounting';
import Config from '../../config';
import { CreateCampaignView } from '../campaign/CreateCampaignView';

Amplify.configure(Config.cognitoSettings);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme.muiTheme}>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <CampaignProvider>
              <Helmet>
                <link
                  href="https://fonts.googleapis.com/css?family=Montserrat:200,400,600,700,900&display=swap"
                  rel="stylesheet"
                />
                <link
                  href="https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap"
                  rel="stylesheet"
                />
              </Helmet>
              <ModalProvider>
                <Box
                  alignItems="center"
                  bg="greys.9"
                  flexDirection="column"
                  id="app-container"
                  minHeight="100vh"
                >
                  <GlobalStyle />
                  <Location>
                    {({ location }) => <Navbar pathname={location.pathname} />}
                  </Location>
                  <Box
                    alignItems="center"
                    as="main"
                    flexDirection="column"
                    mt={80}
                    style={{ flex: 1 }}
                    width="100%"
                  >
                    <Router
                      primary={false}
                      style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column'
                      }}
                    >
                      <Home path="/" />
                      <CheckoutPage path="/campaign/checkout" />
                      <PayeeManagement path="/payee/manage" />
                      <Accounting path="/payee/:payeeId/accounting" />
                      <AddPayee path="/addpayee" />
                      <CreateCampaignView path="/campaign/create/:view" />
                      <CampaignPage path="/campaign/donate/:campaignId" />
                      <DonatePage path="/donation/:campaignId" />
                      <CampaignManager path="/campaign/manage" />
                      <CampaignBrowser path="/campaign/browse" />
                      <Login fullScreen path="/login" />
                      <ForgotPassword fullScreen path="/forgot-password" />
                      <ResetPassword fullScreen path="/reset-password" />
                      <CreateAccount fullScreen path="/create-account" />
                      <ConfirmAccount fullScreen path="/confirm" />
                      <Admin path="/admin" />
                      <DonationFail path="/fail" />
                      <ContactUs path="/contact-us" />
                    </Router>
                  </Box>
                </Box>
              </ModalProvider>
            </CampaignProvider>
          </AuthProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default hot(App);
