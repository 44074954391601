import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import CampaignIcon from '@material-ui/icons/CardGiftcard';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { debug, error } from '../../utils/logging';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function CampaignSelector({
  pkPayee,
  setCampaign,
  closeModal,
  teams
}) {
  const classes = useStyles();
  const [campaigns, setCampaigns] = useState(null);

  const fetchCampaignsViaPayee = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.getAllCampaignsViaPayee}?payeeId=${pkPayee}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    fetchCampaignsViaPayee()
      .then(res => {
        debug(res);
        setCampaigns(res.data.data);
      })
      .catch(err => {
        error(err);
      });
  }, []);

  const renderCampaigns = () => {
    if (!campaigns) {
      return null;
    }
    return campaigns.map(c => {
      return (
        <ListItem
          key={c.pkCampaign}
          button
          color="secondary"
          onClick={() => {
            setCampaign(c);
            closeModal();
          }}
        >
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          <ListItemText
            primary={`${c.pkCampaign} - ${c.title}`}
            // secondary={getTeam(c, teams)}
          />
        </ListItem>
      );
    });
  };

  if (!campaigns || campaigns.length === 0) {
    <div />;
  }
  return (
    <div className={classes.root}>
      <List aria-label="campaigns" color="secondary" component="nav">
        {renderCampaigns()}
      </List>
    </div>
  );
}
