const RegexList = {
  capRegex: /\w*[A-Z]/,
  numRegex: /[0-9]/,
  symRegex: /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/,
}

const LengthRequirement = 8

const checkString = (str, regex) => regex.test(str)

export { RegexList, checkString, LengthRequirement }
