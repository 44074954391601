import React, { useEffect, useState } from 'react';
import { Input } from 'semantic-ui-react';
import { Box, Text } from '../../components/elements';

const TargetGoalInput = ({ onChange, value }) => {
  const [_value, setValue] = useState(value);

  useEffect(() => onChange(_value), [_value]);

  return (
    <Box
      className="campaign-details"
      color="text"
      flexDirection="column"
      width={['100%', '33%']}
    >
      <Text
        fontWeight="700"
        style={{ fontSize: 16 }}
        // mt="20px"
      >
        Target Goal
      </Text>
      <Box className="goal" justifyContent="flex-start">
        <Box flexDirection="column">
          <Text
            color="green"
            fontSize={6}
            fontWeight={3}
            ml="10px"
            mr="10px"
            width="20px"
          >
            $
          </Text>
          <Text
            alignSelf="center"
            color="green"
            fontSize={2}
            fontWeight={3}
            mt="-10px"
          >
            CAD
          </Text>
        </Box>
        <Input
          input={{ style: { textAlign: 'right' } }}
          onChange={(e, { value: __value }) => setValue(__value)}
          placeholder="1000"
          required
          style={{
            width: '100%',
            height: '70px',
            fontSize: '2rem'
          }}
          type="number"
          value={_value}
          variant="goal"
        />
      </Box>
    </Box>
  );
};

export default TargetGoalInput;
