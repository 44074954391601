import LS, { LS_KEYS } from '../utils/local-storage';
import { debug, error } from '../utils/logging';

/* eslint-disable no-console */
export default class Config {
  static get applicationMode() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `production`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `development`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `local`;
    }

    return url;
  }

  static get apiServerHost() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `https://api.fundraiser.sportsheadz.com/prod/`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `https://api.fundraiser.sportsheadz.com/stage/`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `http://localhost:61249/`;
    }

    return url
  }

  static get webServerHost() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `https://fundraiser.sportsheadz.com/`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `https://dev.fundraiser.sportsheadz.com/`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `https://local.fundraiser.sportsheadz.com:8000/`;
    }

    return url;
  }

  static get sportsheadzApiServerHost() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `https://api.sportsheadz.com/prod/`;
    } else {
      url = `https://api.sportsheadz.com/stage/`;
    }

    return url;
  }

  static get cognitoSettings() {
    const settingsProd = {
      Auth: {
        identityPoolId: 'us-east-1:93ca516d-e2c4-4ad6-b44c-4b05604c8bd7',
        region: 'us-east-1',
        userPoolId: 'us-east-1_FAoqNjX2j',
        userPoolWebClientId: '4fdno1j1g5jukptomkdifu3gao',
        mandatorySignIn: true
      },
      Storage: {
        bucket: 'hockeyheadz-storage',
        region: 'us-east-1'
      }
    };

    const settingsDev = {
      Auth: {
        identityPoolId: 'us-east-1:5111a894-2c2c-41b1-a29a-f717eaa9aa31',
        region: 'us-east-1',
        userPoolId: 'us-east-1_Z2PFfqRF1',
        userPoolWebClientId: '624bs7emni9leiuspgcrrs9j8s',
        mandatorySignIn: true
      },
      Storage: {
        bucket: 'hockeyheadz-storage',
        region: 'us-east-1'
      }
    };

    if (window.location.hostname === 'dev.fundraiser.sportsheadz.com') {
      // Todo: change this for a dev version of auth
      return settingsDev;
    }
    debug('process.env.NODE_ENV', process.env.NODE_ENV);

    if (process.env.NODE_ENV === 'production') {
      return settingsProd;
    }

    return settingsDev;
  }

  static get stripeConnectClientId() {
    let url = '';
    if (process.env.NODE_ENV === 'production') {
      url = `ca_GxTk8kTgnn2OAJjQNvOhBKs7iq4XAmDp`;
    }
    if (process.env.NODE_ENV === 'development') {
      url = `ca_GxTk92eEzdFw8TDF4bqwDWYnbggdDN63`;
    }
    if (process.env.NODE_ENV === 'none') {
      url = `ca_GxTk92eEzdFw8TDF4bqwDWYnbggdDN63`;
    }

    return url;
  }

  static get stripePublicKey() {
    let key = '';
    if (process.env.NODE_ENV === 'production') {
      key = `pk_live_hMGDonAGKuDU6mDxpkKqCW3900Uqpiv2Ua`;
    }
    if (process.env.NODE_ENV === 'development') {
      key = `pk_test_vHpA9LSl9fXv3vCbiQsqc4y100mHM7EK55`;
    }
    if (process.env.NODE_ENV === 'none') {
      key = `pk_test_vHpA9LSl9fXv3vCbiQsqc4y100mHM7EK55`;
    }

    return key;
  }

  static get addPayeeEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/payee`;
    return url;
  }

  static get addCampaignEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/create`;
    return url;
  }

  static get editCampaignEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/edit`;
    return url;
  }

  static get getCampaignEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/info`;
    return url;
  }

  static get endCampaignEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/end`;
    return url;
  }

  static get getMyCampaigns() {
    const url = `${Config.apiServerHost}api/campaign/list`;
    return url;
  }

  static get getPublicCampaigns() {
    const url = `${Config.apiServerHost}api/campaign/list/public`;
    return url;
  }

  static get getAllCampaignsViaPayee() {
    const url = `${Config.apiServerHost}api/campaign/list/payee`;
    return url;
  }

  static get getAllPaymentsViaPayee() {
    const url = `${Config.apiServerHost}api/payment/list/payee`;
    return url;
  }

  static get getPaymentEndpoint() {
    const url = `${Config.apiServerHost}api/payment`;
    return url;
  }

  static get getPaymentItems() {
    const url = `${Config.apiServerHost}api/payment/item/list`;
    return url;
  }

  static get getBulkPaymentItems() {
    const url = `${Config.apiServerHost}api/payment/item/bulk-list`;
    return url;
  }

  static get getDashboardLinks() {
    const url = `${Config.apiServerHost}api/campaign/dashboard`;
    return url;
  }

  static get getDonateSessionEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/session`;
    return url;
  }

  static get getDonationsEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/donations`;
    return url;
  }

  static get getAuthEndpoint() {
    const url = `${Config.apiServerHost}api/user/auth`;
    return url;
  }

  static get getS3SignEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/s3sign`;
    return url;
  }

  static get getDashboardLinkEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/dashboard`;
    return url;
  }

  static get getAvailableTargetsEndpoint() {
    const url = `${Config.sportsheadzApiServerHost}api/Fundraiser/targets`;
    return url;
  }

  static get getTeamsEndpoint() {
    const url = `${Config.apiServerHost}api/mbsportsweb/teams`;
    return url;
  }

  static get getAssociationDomainHost() {
    const associationDomain = LS.getItem(LS_KEYS.associationDomain);
    const url = `https://${associationDomain}/`;
    return url;
  }

  static get getAllPayeesEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/payee/list`;
    return url;
  }

  static get getAllAvailablePayeesEndpoint() {
    const url = `${Config.apiServerHost}api/campaign/availablepayee`;
    return url;
  }

  static get getCampaignBalances() {
    const url = `${Config.apiServerHost}api/campaign/campaign-balances`;
    return url;
  }

  static get getBulkCampaignBalances() {
    const url = `${Config.apiServerHost}api/campaign/bulk-campaign-balances`;
    return url;
  }

  static get getCampaignRaised() {
    const url = `${Config.apiServerHost}api/campaign/raised`;
    return url;
  }

  static get getPaymentIntent() {
    const url = `${Config.apiServerHost}api/campaign/payment`;
    return url;
  }
}
