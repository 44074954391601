import React, { useEffect, useState } from 'react';
import { Box } from '../../components/elements';
import { DimmedLoaderWrapper } from '../../components/material/loader';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import { BrowseCardContainer } from './browse-card-container';
import { debug } from '../../utils/logging';

const CampaignBrowser = () => {
  const [campaigns, setCampaigns] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);
      const result = await fetcher({
        method: 'GET',
        url: `${Config.getPublicCampaigns}`
      });
      setCampaigns(result.data.data);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    debug('------------------Running fetch campaigns useEffect');
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (campaigns) {
      setIsLoading(false);
    }
  }, [campaigns]);

  const CampaignList = () => {
    if (campaigns && campaigns.length > 0) {
      return <BrowseCardContainer campaigns={campaigns} />;
    }
    return <Box mt="40px">There are no active fundraising campaigns</Box>;
  };

  return (
    <DimmedLoaderWrapper active={isLoading} disableDimming>
      <Box flexDirection="column" flexWrap="wrap" justifyContent="center">
        {!isLoading && <CampaignList />}
      </Box>
    </DimmedLoaderWrapper>
  );
};

export { CampaignBrowser };
