import React from 'react';
import { Button, Icon, Image, Item, Label } from 'semantic-ui-react';
import { format } from 'date-fns';
import CardMedia from '@material-ui/core/CardMedia';
import { navigate } from '@reach/router';
import { Box } from '../../components/elements';
import { formatMoney, removeTags, truncate } from '../../utils/helpers';
import { theme } from '../../styles';

const BrowseCardContainer = ({ campaigns }) => {
  if (!campaigns) {
    return null;
  }

  const getDescription = (c) => {
    let cleanText = removeTags(c.story);
    cleanText = truncate(cleanText, 300);
    return cleanText;
  };

  const renderBrowseCard = (campaign) => {
    const {
      pkCampaign,
      goal,
      title,
      isPrivate,
      target,
      isComplete,
      image,
      video,
      story
    } = campaign;
    return (
      <Item key={pkCampaign}>
        {image && (
          <Item.Image
            className="browse-card-item"
            size="small"
            src={image || video}
          />
        )}
        {video && (
          <CardMedia
            component="iframe"
            src={video}
            style={{
              width: '100%',
              flex: '1',
              backgroundSize: 'cover',
              maxWidth: '160px',
              maxHeight: '160px',
              marginRight: '1.5em',
              borderRadius: '3px'
            }}
          />
        )}

        <Item.Content>
          <Item.Header
            as="a"
            onClick={() => {
              navigate(`donate/${pkCampaign}`);
            }}
          >
            {title}
          </Item.Header>
          <Item.Meta>
            <span
              style={{
                color: theme.muiTheme.palette.secondary.main,
                fontWeight: 'bold'
              }}
            >
              {target.entityName}
            </span>
          </Item.Meta>
          <Item.Description
            style={{
              maxHeight: '70px',
              overflow: 'hidden'
            }}
          >
            {getDescription(campaign)}
          </Item.Description>
          <Item.Extra>
            <Button
              className="btn-browse-card-view"
              floated="right"
              onClick={() => {
                navigate(`donate/${pkCampaign}`);
              }}
              secondary
              size="medium"
            >
              View
              <Icon name="right chevron" />
            </Button>
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  };

  const renderCampaigns = () => {
    if (campaigns && campaigns.length > 0) {
      return campaigns
        .sort((a, b) => {
          if (a.PkCampaign > b.PkCampaign) return 1;
          if (b.PkCampaign > a.PkCampaign) return -1;

          return 0;
        })
        .map((c) => {
          return renderBrowseCard(c);
        });
    }
  };

  return (
    <Box
      boxShadow="0 0.3125rem 1rem -0.1875rem rgb(0 0 0 / 20%)"
      justifySelf="center"
      margin={['auto', 'auto', 'auto', '1.875rem auto']}
      maxWidth="1000px"
      width="100%"
    >
      <Item.Group
        divided
        style={{
          width: '100%',
          background: 'white',
          padding: '1.875rem',
          borderRadius: '3px'
        }}
      >
        {renderCampaigns()}
      </Item.Group>
    </Box>
  );
};

export { BrowseCardContainer };
