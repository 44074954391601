import React, { useReducer, useEffect } from 'react'
import { Link } from '@reach/router'
import { IoMdPower, IoMdCalendar, IoMdVideocam } from 'react-icons/io'
import { Box, Text, Button } from '../../components/elements'
import { fetcher } from '../../network/fetcher'
import { useAuthService } from '../auth'
import Config from '../../config'
import { theme } from '../../styles'
import ActivityLog from './activity-log'
import { debug, error } from '../../utils/logging';

const { colors } = theme

const initialState = {
  channels: [],
  needsUpdate: true,
  isLoading: false,
  updating: [],
  error: null,
  intervalId: 0,
}

const RECORDING_OFF = 'off'
const RECORDING_STARTING = 'starting'
const RECORDING_ON = 'recording'
const RECORDING_STOPPING = 'stoppingRecording'

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANNELS': {
      return {
        ...state,
        channels: action.payload,
        needsUpdate: false,
        isLoading: false,
        error: null,
      }
    }
    case 'LOADING': {
      let updatingArray = state.updating
      if (action.payload.isLoading) {
        if (action.payload.newUpdate) {
          updatingArray.push(action.payload.newUpdate)
        }
      } else {
        updatingArray = updatingArray.filter(
          i => i !== action.payload.newUpdate,
        )
      }

      return {
        ...state,
        isLoading: action.payload.isLoading,
        updating: updatingArray,
      }
    }
    case 'UPDATE': {
      return {
        ...state,
        needsUpdate: action.payload.needsUpdate,
        updating: action.payload.updating,
      }
    }
    case 'INTERVAL': {
      return {
        ...state,
        intervalId: action.payload,
      }
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.payload,
      }
    }
    default:
      return state
  }
}
const Admin = () => {
  // const { uuid } = useAuthService()
  const { isAdmin } = useAuthService()
  const [adminState, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!isAdmin) {
      window.location = window.location.origin
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isAdmin) {
        dispatch({
          type: 'UPDATE',
          payload: {
            needsUpdate: true,
            updating: adminState.updating,
          },
        })
      }
    }, 10000)
    dispatch({
      type: 'INTERVAL',
      payload: intervalId,
    })
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    async function getAllChannels() {
      try {
        const channelList = await fetcher({
          method: 'GET',
          url: `${Config.allChannelsUrl}`,
        })

        dispatch({
          type: 'CHANNELS',
          payload: channelList.data,
        })
      } catch (error) {
        console.error('Error while validating admin status')
        console.error(error)
      }
      return []
    }
    if (isAdmin && adminState.needsUpdate) {
      getAllChannels()
    }
  }, [adminState.needsUpdate])

  // const changeChannelState = (pkStream, currentStatus) => {
  //   dispatch({
  //     type: 'LOADING',
  //     payload: { isLoading: true, newUpdate: pkStream },
  //   })
  //   async function updateChannelState() {
  //     try {
  //       await fetcher({
  //         method: 'POST',
  //         url: `${Config.updateChannelState}`,
  //         data: {
  //           Id: pkStream,
  //           Online: !currentStatus,
  //         },
  //       })
  //       dispatch({
  //         type: 'UPDATE',
  //         payload: {
  //           needsUpdate: true,
  //           updating: adminState.updating.filter(i => i !== pkStream),
  //         },
  //       })
  //     } catch (error) {
  //       dispatch({
  //         type: 'LOADING',
  //         payload: {
  //           isLoading: false,
  //           newUpdate: pkStream,
  //         },
  //       })
  //       dispatch({
  //         type: 'ERROR',
  //         payload: `Error updating channel`,
  //       })
  //       console.error('Error updating channel')
  //       console.error(error)
  //     }

  //     return []
  //   }
  //   updateChannelState()
  // }

  const changeRecordingState = (pkStream, recordingStatus) => {
    dispatch({
      type: 'LOADING',
      payload: { isLoading: true, newUpdate: pkStream },
    })
    async function updateRecordingState() {
      try {
        await fetcher({
          method: 'POST',
          url: Config.updateRecordingState(pkStream),
          data: {
            Recording: recordingStatus !== 'recording',
          },
        })
        dispatch({
          type: 'UPDATE',
          payload: {
            needsUpdate: true,
            updating: adminState.updating.filter(i => i !== pkStream),
          },
        })
      } catch (error) {
        dispatch({
          type: 'LOADING',
          payload: {
            isLoading: false,
            newUpdate: pkStream,
          },
        })
        dispatch({
          type: 'ERROR',
          payload: `Error updating recording state`,
        })
        console.error('Error updating recording state')
        console.error(error)
      }

      return []
    }
    updateRecordingState()
  }

  const changeCameraState = (cameraId, turnOn) => {
    dispatch({
      type: 'LOADING',
      payload: { isLoading: true },
    })
    async function updateCameraState() {
      try {
        await fetcher({
          method: 'GET',
          url: Config.updateCameraState(cameraId, turnOn),
        })
        dispatch({
          type: 'UPDATE',
          payload: {
            needsUpdate: true,
            updating: adminState.updating,
          },
        })
      } catch (error) {
        dispatch({
          type: 'LOADING',
          payload: {
            isLoading: false,
            newUpdate: null,
          },
        })
        dispatch({
          type: 'ERROR',
          payload: `Error updating camera state`,
        })
        console.error('Error updating camera state')
        console.error(error)
      }

      return []
    }
    updateCameraState()
  }

  const getRecordingCopy = ({ online, recordingStatus }) => {
    if (!online) return 'Turn on this channel to start recording'

    switch (recordingStatus) {
      case RECORDING_OFF:
        return 'This channel is ready for recording'
      case RECORDING_STARTING:
        return 'This channel is starting to record'
      case RECORDING_ON:
        return 'This channel is currently recording'
      case RECORDING_STOPPING:
        return "This channel's recording session is stopping"
      default:
        return ''
    }
  }

  const getRecordingButtonVariant = ({ online, recordingStatus }) => {
    if (!online) return 'recordingDisabled'

    switch (recordingStatus) {
      case RECORDING_OFF:
        return 'recordingOff'
      case RECORDING_STARTING:
        return 'recordingStarting'
      case RECORDING_ON:
        return 'recordingOn'
      case RECORDING_STOPPING:
        return 'recordingStopping'
      default:
        return ''
    }
  }

  const getChannelButtonVariant = ({ online, pkStream }) => {
    if (adminState.isLoading && adminState.updating.includes(pkStream)) {
      return 'updatingOnOffButton'
    }
    return online ? 'onButton' : 'offButton'
  }

  const getChannelButtonText = ({ online, pkStream }) => {
    if (adminState.isLoading && adminState.updating.includes(pkStream)) {
      return '--'
    }
    return online ? 'ON' : 'OFF'
  }

  if (!isAdmin) return null

  debug(adminState.channels)
  return (
    <Box justifyContent="center" py={5}>
      <Box
        alignItems="stretch"
        flexDirection="column"
        maxWidth="80rem"
        px={2}
        width="100vw"
      >
        <Text as="h1" fontSize={5} textAlign="center">
          Stream List
        </Text>
        <Box flexDirection="column">
          {adminState.channels.map(
            ({
              online,
              recordingStatus,
              watchStatus,
              title,
              description,
              pkStream,
              channelLogs,
              camera,
            }) => (
              // adminState.isLoading && adminState.updating.includes(channel.id)
              <>
                <Box
                  key={pkStream}
                  bg="white"
                  border={`1px solid ${colors.greys[3]}`}
                  borderRadius="2px"
                  my={1}
                  p={3}
                >
                  <Button
                    alignItems="center"
                    alignSelf="flex-start"
                    borderRadius="4px"
                    display="flex"
                    flexDirection="column"
                    onClick={() => {
                      debug('No longer actionable')
                      // if (!adminState.isLoading) {
                      //   changeChannelState(pkStream, online)
                      // }
                    }}
                    p={2}
                    variant={getChannelButtonVariant({ pkStream, online })}
                  >
                    <IoMdPower size="3.5rem" />
                    <Text fontSize={2}>
                      {getChannelButtonText({ pkStream, online })}
                    </Text>
                    <Text />
                  </Button>
                  <Box flexDirection="column" px={2}>
                    <Text fontSize={4}>{title}</Text>
                    <Text fontSize={2} lineHeight="1">
                      {description}
                    </Text>
                  </Box>
                  <Box
                    alignItems="flex-end"
                    flex="1"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Text fontSize={1}>
                      Auto Rec: {watchStatus ? 'On' : 'Off'}
                    </Text>
                    <Box alignItems="center">
                      <Box alignItems="center">
                        <Button
                          border={
                            camera && camera.isStreaming
                              ? `1px solid ${colors.greys[3]}`
                              : 'none'
                          }
                          borderRadius="4px"
                          color="black"
                          onClick={() => {
                            debug(
                              `Turn Camera ${
                                camera && camera.isStreaming ? 'Off' : 'On'
                              }!`,
                            )
                            if (camera) {
                              changeCameraState(
                                camera.pkCamera,
                                !camera.isStreaming,
                              )
                            }
                            // changeCameraState(1, false)
                          }}
                        >
                          {/* <IoMdVideocam fill="grey" size="3.5rem" /> */}
                          <IoMdVideocam
                            fill={
                              camera && camera.isStreaming ? 'black' : 'grey'
                            }
                            size="3.5rem"
                          />
                        </Button>
                      </Box>
                      <Box alignItems="center" ml={3} mr={3} mt={2}>
                        <Link color="black" to={`/admin/schedule/${pkStream}`}>
                          <IoMdCalendar fill="black" size="3.5rem" />
                        </Link>
                      </Box>
                      <Button
                        alignItems="center"
                        disabled={!online}
                        display="flex"
                        onClick={() => {
                          if (
                            !adminState.isLoading &&
                            online &&
                            (recordingStatus === 'off' ||
                              recordingStatus === 'recording')
                          ) {
                            changeRecordingState(pkStream, recordingStatus)
                          }
                        }}
                        variant={getRecordingButtonVariant({
                          online,
                          recordingStatus,
                        })}
                      >
                        <Box
                          border={`2px solid ${theme.colors.greys[4]}`}
                          borderRadius="50%"
                          css=""
                          height="1.5rem"
                          mr={2}
                          width="1.5rem"
                        />
                        <Text fontSize={3}>REC</Text>
                      </Button>
                    </Box>
                    <Text fontSize={1}>
                      {getRecordingCopy({ online, recordingStatus })}
                    </Text>
                  </Box>
                </Box>
                <ActivityLog logs={channelLogs} />
              </>
            ),
          )}
        </Box>
      </Box>
    </Box>
  )
}
export { Admin }
