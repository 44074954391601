import React, { useState } from 'react';
import {
  Header,
  Divider,
  Form,
  Card,
  Input,
  TextArea,
  Button
} from 'semantic-ui-react';
import { Box } from '../../components/elements';
import { debug, error } from '../../utils/logging';

const ContactUs = () => {
  const [message, setMessage] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');

  return (
    <Box
      margin={['0 auto 0 auto', '25px auto 0 auto']}
      maxWidth="600px"
      width="100%"
    >
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          padding: 20
        }}
      >
        <Form onSubmit={() => debug('Submitted')} size="medium">
          <Header as="h1" size="large">
            Contact Us
          </Header>
          <Divider style={{ marginBottom: 15 }} />
          <Form.Field
            control={Input}
            label={{ children: 'Name', style: { fontSize: 14 } }}
            onChange={(e, { value }) => setName(value)}
            placeholder="Name"
            required
          />
          <Form.Field
            control={Input}
            label={{ children: 'Email', style: { fontSize: 14 } }}
            onChange={(e, { value }) => setEmail(value)}
            placeholder="Email"
            required
          />
          <Form.Select
            fluid
            label={{ children: 'Subject', style: { fontSize: 14 } }}
            onChange={(e, { value }) => setSubject(value)}
            options={[
              {
                key: 'question',
                text: 'I have a question...',
                value: 'support'
              },
              {
                key: 'request',
                text: `I'd like to join...`,
                value: 'sales'
              }
            ]}
            placeholder="Subject"
            required
          />
          <Form.Field
            control={TextArea}
            label={{ children: 'Message', style: { fontSize: 14 } }}
            onChange={(e, { value }) => setMessage(value)}
            placeholder="Message"
            required
            rows={15}
          />
          <Button color="green" fluid size="large" style={{ marginTop: 25 }}>
            Submit
          </Button>
        </Form>
      </Card>
    </Box>
  );
};

export { ContactUs };
