import React, { useState, useEffect, useRef } from 'react';
import { Link as ReachLink, navigate } from '@reach/router';
import PropTypes from 'prop-types';
import {
  Avatar,
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  withStyles
} from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Box, Text, Link } from '../../components/elements';
import { MobileMenu } from './mobile-menu';
import { links } from './links';
import logo from '../../images/sportsheadz.png';
import { useModal } from '../../components/modules';
import { AuthViewSwitcher, useAuthService } from '../auth';
import { getInitialsFromName } from '../../utils/helpers';
import { theme } from '../../styles';

const propTypes = { pathname: PropTypes.string.isRequired };

const defaultProps = {};

const Navbar = ({ pathname }) => {
  const { openModal, closeModal, isOpen, Modal } = useModal();
  const { onLogout, user } = useAuthService();

  const handleLogout = () => {
    onLogout();
  };

  const navLinks = links.homeLinks;
  const loaded = useRef(false);

  const [modalView, setModalView] = useState('login');
  const [anchorEl, setAnchorEl] = useState(null);
  const screenNarrow = useMediaQuery('(max-width:1140px)');

  useEffect(() => {
    if (loaded.current && modalView && modalView.length > 0) {
      openModal();
    }
    else if (!loaded.current) {
      loaded.current = true;
      const urlParams = new URLSearchParams(window.location.search);
      const viewParam = urlParams.get('view');
      if (viewParam) {
        if (viewParam === modalView) {
          openModal();
        }
        else {
          setModalView(viewParam);
        }
      }
    }
  }, [modalView]);

  const StyledMenu = withStyles((_theme) => ({
    paper: {
      border: '1px solid #d3d4d5',
      background: '#f7fbec'
    }
  }))((props) => (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      elevation={0}
      getContentAnchorEl={null}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      variant="menu"
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((_theme) => ({
    root: {
      '&:focus': { backgroundColor: _theme.palette.primary.contrastText },
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        fontSize: 16,
        color: _theme.palette.primary.main
      },
      '& .MuiListItemIcon-root': { marginLeft: 10 },
      '& .MuiListItemText-primary': { marginRight: 10 }
    }
  }))(MenuItem);

  const menuItemClick = (path, logout) => {
    if (anchorEl) {
      setAnchorEl(null);
    }
    if (logout) {
      handleLogout();
    }
    navigate(path);
  };

  const renderNavLinks = (loggedInUser, side) => {
    const filteredNavLinks = navLinks.filter(
      (link) =>
        (link.access === 'public' || loggedInUser) &&
        link.side === side.toLowerCase()
    );

    return filteredNavLinks.map((link) => (
      <Link
        key={link.to}
        as={ReachLink}
        fontFamily="sports"
        fontSize={17}
        fontWeight={link.to === pathname ? 3 : 1}
        isSelected={link.to === pathname}
        textAlign="center"
        to={link.to}
        variant="navbar"
      >
        {link.title}
      </Link>
    ));
  };

  return (
    <Box
      alignItems="center"
      alignSelf={['stretch', null]}
      as="nav"
      backgroundColor="white"
      boxShadow={2}
      height="5rem"
      justifyContent="center"
      position="fixed"
      width="100%"
      zIndex="navbar"
    >
      <Box
        alignItems="center"
        flex={1}
        justifyContent={['flex-start', 'space-between', 'flex-start']}
        style={{
          paddingLeft: 50,
          paddingRight: 50,
          paddingTop: 16,
          paddingBottom: 16
        }}
      >
        {screenNarrow && (
          <Box>
            <img alt="Sportsheadz logo" src={logo} style={{ height: '55px' }} />
          </Box>
        )}
        <Box
          display={['none', 'none', 'flex']}
          style={{
            flex: screenNarrow ? 0 : 1,
            justifyContent: screenNarrow ? 'flex-start' : 'flex-end',
            marginRight: screenNarrow ? 0 : 160,
            marginLeft: screenNarrow ? 10 : 0
          }}
        >
          {renderNavLinks(user, 'left')}
        </Box>
        <Box style={{ flex: 1 }}>
          <Box display={['none', 'none', 'flex']}>
            {renderNavLinks(user, 'right')}
          </Box>
          <Box
            display={['none', 'none', 'flex']}
            ml={3}
            style={{ flex: 1, alignItems: 'center' }}
          >
            {user ? (
              <Box style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Box
                  css={`
                    &:hover #ddContent {
                      display: block;
                    }

                    &:hover #jaspreetButton {
                    }
                  `}
                  display="inline-block"
                  maxWidth="13.75rem"
                  position="relative"
                >
                  <Button
                    alignItems="center"
                    alignSelf="center"
                    backgroundColor="white"
                    border="none"
                    color="black"
                    display="flex"
                    fontSize="16px"
                    id="jaspreetButton"
                    maxWidth="13.75rem"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    p="16px"
                  >
                    <Box
                      alignItems="center"
                      color={user ? 'yellows.0' : 'greys.4'}
                      mr={2}
                    >
                      <Avatar
                        style={{
                          backgroundColor: theme.muiTheme.palette.success.main,
                          width: 32,
                          height: 32,
                          fontSize: 16
                        }}
                      >
                        {getInitialsFromName(`${user.fullName}`.trim())}
                      </Avatar>
                    </Box>
                    <Text
                      style={{
                        color: 'black',
                        fontSize: 16,
                        fontWeight: '500'
                      }}
                      truncate
                    >
                      My Account
                    </Text>
                    <Icon
                      component={anchorEl ? ExpandLess : ExpandMore}
                      style={{
                        color: theme.muiTheme.palette.success.main,
                        marginLeft: 5,
                        fontSize: 20
                      }}
                    />
                  </Button>
                  <StyledMenu
                    anchorEl={anchorEl}
                    disableScrollLock
                    keepMounted
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                    style={{ padding: 0 }}
                  >
                    {user && (
                      <MenuItem
                        disabled
                        style={{
                          borderBottom: '1px solid #d4d4d5',
                          color: 'black',
                          opacity: 1
                        }}
                      >
                        <Text
                          fontSize="1rem"
                          fontWeight="bold"
                          textAlign="center"
                          width="100%"
                        >
                          {user.fullName}
                        </Text>
                      </MenuItem>
                    )}
                    {user && (
                      <StyledMenuItem
                        onClick={() => menuItemClick('/payee/manage')}
                        style={{ background: 'white' }}
                      >
                        <ListItemIcon>
                          <SettingsIcon color="primary" fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Financials" />
                      </StyledMenuItem>
                    )}
                    <StyledMenuItem
                      onClick={() => menuItemClick('/', true)}
                      style={{ background: 'white' }}
                    >
                      <ListItemIcon>
                        <ExitToAppIcon color="primary" fontSize="medium" />
                      </ListItemIcon>
                      <ListItemText primary="Logout" />
                    </StyledMenuItem>
                  </StyledMenu>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  height: 35,
                  flex: 1,
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  onClick={() =>
                    modalView === 'login' ? openModal() : setModalView('login')
                  }
                  style={{
                    width: 126,
                    backgroundColor: theme.muiTheme.palette.success.main,
                    color: 'white'
                  }}
                  variant="contained"
                >
                  Login
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        {!screenNarrow && (
          <Box
            flex={1}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              position: screenNarrow ? 'relative' : 'absolute',
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: -1
            }}
          >
            <img alt="Sportsheadz logo" src={logo} style={{ height: '55px' }} />
          </Box>
        )}
        {!user && (
          // this is the mobile login button
          <Box display={['flex', 'flex', 'none']} mr={3}>
            <Button
              onClick={() =>
                modalView === 'login' ? openModal() : setModalView('login')
              }
              style={{
                backgroundColor: theme.muiTheme.palette.success.main,
                color: 'white'
              }}
              variant="contained"
            >
              Login
            </Button>
          </Box>
        )}
        <MobileMenu containerProps={{ display: ['flex', 'flex', 'none'] }} />
      </Box>

      <Modal
        closeBtnCb={closeModal}
        containerProps={{ width: ['100%', '435px'] }}
        isOpen={isOpen}
        onBgClick={closeModal}
        onCancel={closeModal}
        onEscapeKey={closeModal}
      >
        <AuthViewSwitcher closeModal={closeModal} currentView={modalView} />
      </Modal>
    </Box>
  );
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export { Navbar };
