import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useSnackbar } from 'notistack';
import { useAuthService } from '../auth/auth.provider';
import { useCampaigns } from '../campaign';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { Box } from '../../components/elements';
import { CircularIndeterminate } from '../../components/material/loader';
import { debug, error } from '../../utils/logging';

const AddPayee = () => {
  const { deserializeAuth, addPayee } = useAuthService();
  const {
    deserializeCampaign,
    setCurrentCampaign,
    setNewPayeeForm,
    fetchPayees
  } = useCampaigns();
  const { enqueueSnackbar } = useSnackbar();

  const [localLoading, setLocalLoading] = useState(false);

  // 1. get querystring value of access_token
  const urlParams = new URLSearchParams(window.location.search);

  const authorization_code = urlParams.get('code');
  debug('successfully captured authorization_code', {authorization_code});

  const encodedState = urlParams.get('state');
  debug('successfully captured state', { encodedState });

  const loadAllSavedData = (saved) => {
    debug('setCurrentCampaign:', saved.currentCampaign);
    setCurrentCampaign(saved.currentCampaign);
    debug('setNewPayeeForm:', saved.newPayeeForm);
    setNewPayeeForm(saved.newPayeeForm);
    debug('all saved');
  };

  const addPayeeFunc = async () => {
    // send to server to add payee
    debug('running useEffect for payee');
    if (authorization_code) {
      try {
        const saved = JSON.parse(encodedState);

        if (!(saved && saved.newPayeeForm && saved.newPayeeForm.displayName)) {
          enqueueSnackbar('Could not find the display name for the new payee', {variant: 'error'});
          return;
        }

        setLocalLoading(true);
        const result = await addPayee(
          authorization_code,
          saved.newPayeeForm.displayName
        );
        enqueueSnackbar(
          `Successfully added new payee: ${saved.newPayeeForm.displayName}`,
          { variant: 'success' }
        );
        debug('Successfully, added payee to db', result);
        fetchPayees();

        saved.newPayeeForm.stripeID = result.data.stripeUserId;
        debug('attempting to load:', saved);
        loadAllSavedData(saved);

        setLocalLoading(false);
        debug('navigating to /payee/manage');
        navigate(`/payee/manage`);
      } catch (err) {
        setLocalLoading(false);
        debug('Unfortunately, we could not add the new payee.', err);
      }
    }
  };

  useEffect(() => {
    addPayeeFunc();
  }, [authorization_code]);

  // Stripe error
  const error = urlParams.get('error');
  const error_description = urlParams.get('error_description');
  if (error) {
    return <Box margin="0 auto">{`${error} - ${error_description}`}</Box>;
  }

  if (localLoading) {
    return (
      <Box>
        <CircularIndeterminate />
      </Box>
    );
  }

  return <h1>Processing payee setup...Please wait.</h1>;
};

export { AddPayee };
