import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import PaymentCard from '../../components/material/paymemt-card';
import { Box, Text } from '../../components/elements';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { formatMoney } from '../../utils/helpers';
import { debug, error } from '../../utils/logging';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: '14px'
  },
  labelRoot: {
    marginTop: '-10px',
    fontSize: '14px'
    // color: 'red',
    // '&$labelFocused': {
    //   color: 'purple',
    // },
  }
}));

const AddPayment = ({ pkPayee, setUpdate, setTabIndex, teams }) => {
  const [reference, setReference] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [available, setAvailable] = useState({});
  const [paid, setPaid] = useState({});
  const classes = useStyles();

  const fetchBalance = async pkCampaign => {
    return fetcher({
      method: 'GET',
      url: `${Config.getCampaignBalances}?campaignId=${pkCampaign}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const fetchPaymentItems = async pkCampaign => {
    return fetcher({
      method: 'GET',
      url: `${Config.getPaymentItems}?campaignId=${campaign.pkCampaign}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    if (campaign) {
      fetchBalance(campaign.pkCampaign)
        .then(res => {
          debug(res.data);
          const balances = res.data.data;

          // available amount
          const availableArray = balances.filter(b => b.status === 'available');
          const availableBalance = availableArray
            .map(a => a.net)
            .reduce((a, b) => a + b, 0);
          const newAvailable = { ...available };
          newAvailable[campaign.pkCampaign] = availableBalance;
          setAvailable(newAvailable);
        })
        .catch(err => {
          debug(err);
        });

      fetchPaymentItems(campaign.pkCampaign)
        .then(res => {
          debug('<<<<<<<<<<<<<<', res.data.data);
          const amountArray = res.data.data;
          const paymentTotal = amountArray
            .map(a => a.amount)
            .reduce((a, b) => a + b, 0);
          const newPaid = { ...paid };
          newPaid[campaign.pkCampaign] = paymentTotal;
          setPaid(newPaid);
        })
        .catch(err => {
          debug(err);
        });
    }
  }, [campaign]);

  const gatherPaymentItems = () => {
    debug({ paymentAmount });
    const numPaymentAmount = Number(paymentAmount);
    debug({ numPaymentAmount });
    const dollarsPaymentAmount = numPaymentAmount * 100;
    debug({ dollarsPaymentAmount });
    const item = {
      Amount: dollarsPaymentAmount,
      FkCampaign: campaign.pkCampaign
    };
    return [item];
  };

  const gatherPayload = () => {
    const payload = {};
    payload.Reference = reference;
    payload.FkPayee = pkPayee;
    payload.PaymentItems = gatherPaymentItems();
    return payload;
  };

  const clearFields = () => {
    setReference(null);
    setPaymentAmount(null);
    setCampaign(null);
  };

  const calculateLeftover = () => {
    try {
      const paidAmount = paid[campaign.pkCampaign];
      const availableAmount = available[campaign.pkCampaign];
      const leftover = (availableAmount - paidAmount) / 100.0;
      const formatted = `$${formatMoney(leftover, 2, '.', ',')}`;
      if (leftover >= 0) {
        return `Payable: ${formatted}`;
      }
      return `Overdrawn: ${formatted}`;
    } catch (error) {
      return '';
    }
  };
  return (
    <Box
      backgroundColor="white"
      boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
      flexDirection="column"
      p="20px"
    >
      <Box>
        <Text fontSize="20px" margin="0 auto">
          Add New Payment
        </Text>
      </Box>

      <Box
        alignSelf="center"
        flexDirection="column"
        maxWidth="800px"
        width="100%"
      >
        <FormControl style={{ width: '200px' }}>
          <TextField
            color="secondary"
            id="referenceNumber"
            InputLabelProps={{
              classes: {
                root: classes.labelRoot
              }
            }}
            InputProps={{ classes: { root: classes.inputRoot } }}
            label="Reference Number"
            onChange={e => setReference(e.target.value)}
            value={reference}
          />
        </FormControl>
        <PaymentCard
          campaign={campaign}
          leftoverAmount={calculateLeftover()}
          paymentAmount={paymentAmount}
          pkPayee={pkPayee}
          setCampaign={setCampaign}
          setPaymentAmount={setPaymentAmount}
          teams={teams}
        />
        <Box justifyContent="flex-end" mt="10px">
          {/* <Button
            ali
            //style={{ marginLeft: '20px' }}
            size="medium"
            variant="outlined"
            color="secondary"
          >
            Add another payment
          </Button> */}
        </Box>
      </Box>

      <Box justifyContent="center">
        <Button
          // style={{ marginLeft: '20px' }}
          color="secondary"
          onClick={async () => {
            const payload = gatherPayload();

            debug({ payload });

            fetcher({
              method: 'POST',
              url: `${Config.getPaymentEndpoint}`,
              data: payload,
              headers: { Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}` }
            })
              .then(res => {
                clearFields();
                // refresh data

                // switch to payment view
                setTabIndex(1);
                debug('wait');
                setTimeout(() => {
                  debug('wait complete');
                  setUpdate(new Date());
                }, 2000);
                // setLocalLoading(false)
                // debug(res.data)
                // setBalances(res.data)
              })
              .catch(err => {
                // setLocalLoading(false)
                debug(err);
              });
          }}
          size="large"
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
export default AddPayment;
