import React from 'react';
import './assets/fomantic/dist/semantic.css';
import ReactDOM from 'react-dom';
// import * as Sentry from '@sentry/browser'
import App from './features/app';

// Sentry.init({
//   dsn:
//     'http://3f99218da0aa4b1ab32e13029b3b8895@ec2-54-198-225-244.compute-1.amazonaws.com/1',
// })

window.addEventListener('load', () => {
  window.isWindowLoaded = true;
});

ReactDOM.render(<App />, document.getElementById('root'));
