/* eslint-disable consistent-return */
import React, { useMemo, useReducer } from 'react';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import Config from '../../config';
import { debug, error } from '../../utils/logging';

const CampaignContext = React.createContext();

const initialState = {
  loading: false,
  error: false,
  currentCampaign: null,
  payees: null,
  availablePayees: null,
  newPayeeForm: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'error': {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case 'fetching': {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case 'add-campaign': {
      return {
        ...state,
        error: null,
        loading: false,
        currentCampaign: action.payload
      };
    }

    case 'set-payees': {
      return {
        ...state,
        error: null,
        loading: false,
        payees: action.payload
      };
    }

    case 'load-state': {
      return {
        ...state,
        error: null,
        loading: false,
        ...action.payload
      };
    }

    case 'set-available-payees': {
      return {
        ...state,
        error: null,
        loading: false,
        availablePayees: action.payload
      };
    }

    case 'set-new-payee-form': {
      return {
        ...state,
        error: null,
        loading: false,
        newPayeeForm: action.payload
      };
    }

    case 'clear-new-payee-form': {
      return {
        ...state,
        error: null,
        loading: false,
        newPayeeForm: null
      };
    }

    default:
      return initialState;
  }
};

function CampaignProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const deserializeCampaign = encodedState => {
    // deserialize encodedState
    const jsonState = atob(encodedState);
    const stateObject = JSON.parse(jsonState);
    debug('DESERIALIZED:', stateObject);
    dispatch({ type: 'load-state', payload: { ...stateObject } });
  };

  const serializeCampaign = () => {
    // serialize current state
    const currentJsonState = JSON.stringify(state);
    const encodedState = new Buffer(currentJsonState).toString('base64');
    debug('SERIALIZED:', encodedState);
    return encodedState;
  };

  const setCurrentCampaign = campaign => {
    const data = campaign;
    dispatch({ type: 'add-campaign', payload: data });
  };

  const getCurrentCampaign = () => {
    return state.currentCampaign;
  };

  const setNewPayeeForm = payeeData => {
    const data = payeeData;
    dispatch({ type: 'set-new-payee-form', payload: data });
  };

  const fetchPayees = async () => {
    const token = LS.getItem(LS_KEYS.token);
    dispatch({ type: 'fetching' });
    fetcher({
      method: 'GET',
      url: `${Config.getAllPayeesEndpoint}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    }).then(result => {
      dispatch({ type: 'set-payees', payload: result.data.data });
    });
  };

  const fetchAvailablePayees = async () => {
    const token = LS.getItem(LS_KEYS.token);
    dispatch({ type: 'fetching' });
    fetcher({
      method: 'GET',
      url: `${Config.getAllAvailablePayeesEndpoint}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    }).then(result => {
      dispatch({ type: 'set-available-payees', payload: result.data.data });
    });
  };

  const value = useMemo(
    () => ({
      ...state,
      setCurrentCampaign,
      getCurrentCampaign,
      fetchPayees,
      fetchAvailablePayees,
      deserializeCampaign,
      serializeCampaign,
      setNewPayeeForm
    }),
    [state]
  );
  return <CampaignContext.Provider value={value} {...props} />;
}

function useCampaigns() {
  const context = React.useContext(CampaignContext);

  if (!context) {
    throw new Error(`useCampaigns must be used within an CampaignProvider`);
  }

  return context;
}

export { CampaignProvider, useCampaigns };
