import React, { Fragment } from 'react';
import { Box, Text } from '../../components/elements';

const CartPriceSummary = ({ donation }) => {
  const { amount } = donation;
  const total = Number(amount);
  const totalAmount = total.toFixed(2);
  return (
    <>
      <Box
        bg="#fff"
        boxShadow="0 1px 5px 1px rgba(0, 0, 0, 0.15)"
        p="10px"
        width="100%"
      >
        <Box
          borderColor="primary.0"
          justifyContent="space-between"
          width="100%"
        >
          <Box flexDirection="column" ml="20px" width="100%">
            <Box justifyContent="space-between">
              <Text>Donation</Text>
              <Text>{`$${donation.amount}.00 CAD`}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        bg="#fff"
        boxShadow="0 1px 5px 1px rgba(0, 0, 0, 0.15)"
        p="10px"
        width="100%"
      >
        <Box flexDirection="column" ml="20px" width="100%">
          <Box fontSize="16px" fontWeight="600" justifyContent="space-between">
            <Text>Total</Text>
            <Text>{`$${totalAmount} CAD`}</Text>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export { CartPriceSummary };
