import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../components/elements'
import { debug, error } from '../../utils/logging'

const propTypes = {
  srcUrl: PropTypes.string.isRequired,
}

const defaultProps = {
  srcUrl: null,
}

const VideoPlayer = ({ srcUrl }) => {
  useEffect(() => {
    debug('running useeffect in video-player')

    debug('before video part start')
    const stillSignedIn = async () => {
      debug('before video part end---- we should have cookie by now')

      debug('waiting for one second')
      setTimeout(() => {
        debug('done waiting')
        if (srcUrl) {
          debug('start new video srcUrl')
          window.jw = window
            .jwplayer('player')
            .setup({
              //playlist: playlist,
              playlist: [
                {
                  file: srcUrl,
                },
              ],
              autostart: 'true',
              withCredentials: true,
            })
            .on('ready', () => {
              try {
                // createResetButtonFunction()
                debug('setting drag actions')
                //dragElement(getVideo());
              } catch (e) {
                error(
                  '[VideoPlayer] Encountered Error setting Drag actions ',
                  // eslint-disable-next-line comma-dangle
                  e,
                )
              }
            })
        }
      }, 1000)
    }
    stillSignedIn()
  }, [srcUrl])

  return (
    <>
      <Box id="player" width="100%" />
    </>
  )
}

VideoPlayer.propTypes = propTypes
VideoPlayer.defaultProps = defaultProps

export { VideoPlayer }
