import React, { useState } from 'react'
import { Box, Text, Input } from '../../components/elements'
import Button from '@material-ui/core/Button'
import { useSnackbar } from 'notistack'
import { useCampaigns } from '../../features/campaign/campaign.provider'
import { debug, error } from '../../utils/logging';

const YouTube = ({ changeView }) => {
  const [youtubeLink, setYoutubeLink] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const { currentCampaign, setCurrentCampaign } = useCampaigns()

  const viewSwitch = view => {
    debug('switching to', view)
    changeView(view)
  }

  return (
    <Box
      bg="white"
      mt="20px"
      width={['100%', '100%', '600px']}
      ml="auto"
      mr="auto"
      //boxShadow="0 0 1rem 0rem rgba(0,0,0,0.3)"
      flexDirection="column"
    >
      <Box
        className="campaign-details"
        //p="50px"
        mr="auto"
        ml="auto"
        width={['100%']}
        flexDirection="column"
        color="text"
      >
        <Box
          ml="auto"
          mr="auto"
          justifyContent="center"
          flexDirection="column"
          width="100%"
        >
          <Box flexDirection="column" width="100%">
            <Text ml="auto" mr="auto" fontWeight="700" fontSize={4}>
              Add a YouTube Video
            </Text>
            <Text mt="10px" ml="auto" mr="auto" fontWeight="400" fontSize={3}>
              If you would like to use a video from your phone, upload to
              YouTube first.
            </Text>
            <Input
              mt="20px"
              p="5px"
              mt="10px"
              border="1px solid lightgray"
              type="text"
              placeholder="Paste a YouTube link"
              onChange={e => {
                setYoutubeLink(e.target.value)
              }}
              value={youtubeLink}
            />
            <Button
              style={{
                marginTop: '15px',
                height: '45px',
                fontSize: '16px',
              }}
              size="large"
              variant="contained"
              color="secondary"
              onClick={async () => {
                debug('youtube button submit')
                if (!youtubeLink) {
                  enqueueSnackbar('Please enter a YouTube link', {
                    variant: 'warning',
                  })
                }

                let sourceVid = youtubeLink
                if (youtubeLink.includes('v=')) {
                  const urlParams = new URLSearchParams(
                    `?${youtubeLink.split('?')[1]}`,
                  )
                  const youtubeId = urlParams.get('v')
                  sourceVid = `https://www.youtube.com/embed/${youtubeId}`
                }
                const savedData = { ...currentCampaign, video: sourceVid }
                await setCurrentCampaign(savedData)
                viewSwitch('preview')
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export { YouTube }
