import React, { useEffect, useState } from 'react';
import { Box, Text, Img, Button } from '../../components/elements';
import { debug, error } from '../../utils/logging';

const Albums = ({ setSelectedImage }) => {
  const [accessToken, setAccessToken] = useState('');
  const [albums, setAlbums] = useState(null);
  const [photos, setPhotos] = useState(null);
  const [selectedAlbum, setSelectedAlbum] = useState(null);

  useEffect(() => {
    if (accessToken) {
      const path = '/me/albums';
      const method = 'get';
      const params = {
        access_token: accessToken,
        fields: 'picture, name'
      };
      FB.api(path, method, params, (response) => {
        debug(response);
        setAlbums(response.data);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    debug('checking facebook login status');
    FB.getLoginStatus(function (response) {
      debug(response);
      if (response.status === 'connected') {
        // The user is logged in and has authenticated your
        // app, and response.authResponse supplies
        // the user's ID, a valid access token, a signed
        // request, and the time the access token
        // and signed request each expire.
        const uid = response.authResponse.userID;
        const { accessToken } = response.authResponse;
        setAccessToken(accessToken);
      }
      else {
        FB.login(
          function (response) {
            debug(response);
            if (response.authResponse) {
              debug('Welcome!  Fetching your information.... ');
              FB.api('/me', function (response) {
                debug(`Good to see you, ${response.name}.`);
              });
              setAccessToken(response.authResponse.accessToken);
            }
            else {
              debug('User cancelled login or did not fully authorize.');
            }
          },
          { scope: 'user_photos' }
        );
      }
    });
  }, []);

  useEffect(() => {
    if (selectedAlbum) {
      const path = `/${selectedAlbum.id}/photos`;
      const method = 'get';
      const params = {
        access_token: accessToken,
        fields: 'images, created_time'
      };
      FB.api(path, method, params, (response) => {
        debug(response);
        setPhotos(response.data);
      });
    }
  }, [selectedAlbum]);

  const clearState = () => {
    setAccessToken('');
    setAlbums(null);
    setPhotos(null);
    setSelectedAlbum(null);
  };

  const handleSelection = (album) => {
    debug(album);
    setSelectedAlbum(album);
  };

  const renderPhotos = () => {
    if (!photos || photos.length <= 0) return null;
    return photos.map((p) => {
      const key = p.id;
      const src = p.images[p.images.length - 1].source;
      const largestImage = p.images[0].source;

      return (
        <Box
          key={key}
          borderRadius="4px 0 0 0"
          flexDirection="column"
          height="100px"
          m="10px"
          onClick={() => {
            debug('photo click');
            clearState();
            setSelectedImage(largestImage);
          }}
          width="160px"
        >
          <Img
            alt="album"
            borderRadius="4px 0 0 0"
            height="100%"
            maxHeight="100px"
            src={src}
            width="100%"
          />
        </Box>
      );
    });
  };

  const renderAlbums = () => {
    if (!albums || albums.length <= 0) return null;
    return albums.map((a) => {
      const key = a.id;
      const src = a.picture.data.url;

      return (
        <Box
          key={a.id}
          borderRadius="4px 0 0 0"
          flexDirection="column"
          height="120px"
          m="10px"
          onClick={() => {
            debug('album click');
            handleSelection(a);
          }}
          width="160px"
        >
          <Img
            alt="album"
            borderRadius="4px 0 0 0"
            height="100%"
            maxHeight="100px"
            src={src}
            width="100%"
          />
          <Text alignSelf="center" flex={1}>
            {a.name}
          </Text>
        </Box>
      );
    });
  };

  return (
    <Box flexDirection="column">
      <Text alignSelf="center" fontSize="1.875rem" fontWeight="600">
        {photos ? 'Pick a Photo' : 'Pick an Album'}
      </Text>
      <Box
        className="album-collection"
        flexWrap="wrap"
        justifyContent="center"
        mt="20px"
        width="100%"
      >
        {photos && renderPhotos()}
        {!photos && albums && renderAlbums()}
      </Box>
    </Box>
  );
};
export { Albums };
