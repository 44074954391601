import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Box, Input } from '../../components/elements';
import { useAuthService } from '../auth';
import PayeeEditableTable from '../../components/material/payeeEditableTable';
import { useModal } from '../../components/modules/modal';
import NewPayee from './newpayee';
import { useCampaigns } from '../campaign/campaign.provider';
import { CircularIndeterminate } from '../../components/material/loader';
import { debug, error } from '../../utils/logging';

const PayeeManagement = () => {
  const { serialize, deserialize, onLogin } = useAuthService();
  const [inputbox, setInputBox] = useState('');
  const { openModal, closeModal, isOpen, Modal } = useModal();
  const {
    payees,
    fetchPayees,
    currentCampaign,
    newPayeeForm,
    loading
  } = useCampaigns();

  const urlParams = new URLSearchParams(window.location.search);
  const sso_token = urlParams.get('sso');

  useEffect(() => {
    if (sso_token) {
      debug('authorizing via sso...', sso_token);
      onLogin({ sso_token }, () => navigate('/'));
    }
  }, [sso_token]);

  useEffect(() => {
    fetchPayees();
  }, [currentCampaign, newPayeeForm]);

  const renderTable = () => {
    if (payees) {
      return <PayeeEditableTable payees={payees} />;
    }
    return <Box>No payees found.</Box>;
  };

  const renderLoading = () => {
    return <CircularIndeterminate />;
  };

  return (
    <Box className="main-container">
      <Box flexDirection="column" width="100%">
        <Box className="payee-header" justifyContent="center">
          <Box
            justifyContent="center"
            ml="40px"
            mr="40px"
            mt="20px"
            width="100%"
          >
            {loading ? renderLoading() : renderTable()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export { PayeeManagement };
