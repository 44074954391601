import { Avatar, Paper, useMediaQuery } from '@material-ui/core';
import { ContentState, Editor, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useRef, useState } from 'react';
import {
  Divider,
  Button,
  Header,
  Image,
  Progress,
  Transition
} from 'semantic-ui-react';
import { navigate } from '@reach/router';
import { useInViewport } from 'react-in-viewport';
import moment from 'moment';
import { Box, Text } from '../../components/elements';
import { DimmedLoaderWrapper } from '../../components/material/loader';
import { useModal } from '../../components/modules';
import Config from '../../config';
import { fetcher } from '../../network/fetcher';
import { theme } from '../../styles';
import { formatMoney } from '../../utils/helpers';
import { DonationList } from './donation-list';
import { ShareContent } from './share-content';
import { debug, error } from '../../utils/logging';

const OPTIONS = {
  root: null,
  rootMargin: '-80px 0px 0px 0px',
  threshold: 0.5
};

const CampaignPage = ({ campaignId }) => {
  const [campaign, setCampaign] = useState(null);
  const [raisedTotal, setRaisedTotal] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [donations, setDonations] = useState(null);
  const [editorState, setEditorState] = useState(null);
  const { openModal, closeModal, isOpen, Modal } = useModal();

  const screenNarrow = useMediaQuery('(max-width:992px)');

  const fetchCampaign = async () => {
    fetcher({
      method: 'GET',
      url: `${Config.getCampaignEndpoint}?id=${campaignId}`
    }).then((result) => {
      debug(`fetch campaign ${campaignId}`, { result });
      setCampaign(result.data.data);
    });

    fetcher({
      method: 'GET',
      url: `${Config.getCampaignRaised}?campaignId=${campaignId}`
    }).then((result) => {
      debug(`fetch balances ${campaignId}`, { result });
      setRaisedTotal(result.data.data);
      setIsReady(true);
    });

    fetcher({
      method: 'GET',
      url: `${Config.getDonationsEndpoint}?campaignId=${campaignId}`
    }).then((result) => {
      debug(`fetch donations ${campaignId}`, { result });
      setDonations(result.data.data);
    });
  };

  useEffect(() => {
    debug('running useEffect from CampaignDonation');
    fetchCampaign();
  }, [campaignId]);

  useEffect(() => {
    if (campaign) {
      debug('running useEffect from CampaignDonation - Share');
      const urlParams = new URLSearchParams(window.location.search);
      const shouldOpenShare = urlParams.get('share');
      if (shouldOpenShare === 'open') {
        openModal();
      }

      const blocksFromHtml = htmlToDraft(campaign.story);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [campaign]);

  const renderMessages = () => {
    if (!donations) return null;
    return donations
      .filter((d) => {
        return d.message;
      })
      .map((d) => (
        <Paper style={{ margin: '10px' }}>
          <Box
            key={d.pkDonation}
            border={`1px solid ${theme.muiTheme.palette.secondary.main}`}
            flexDirection="column"
            p="15px"
          >
            <Text fontSize="13px" fontWeight="600">
              {moment(new Date(d.dateCreated)).format('MM/DD/YYYY, h:mm a')}
            </Text>
            <Text>{d.message}</Text>
            <Text
              color={theme.muiTheme.palette.secondary.main}
              fontSize="13px"
              fontWeight="600"
            >
              {d.name}
            </Text>
          </Box>
        </Paper>
      ));
  };

  if (!campaign || !editorState) {
    return <DimmedLoaderWrapper active={!campaign} disableDimming />;
  }

  const DonationTracker = ({ onEnterViewport, onLeaveViewport }) => {
    const buttonGroupRef = useRef();
    useInViewport(
      buttonGroupRef,
      OPTIONS,
      { disconnectOnLeave: false },
      { onLeaveViewport, onEnterViewport }
    );

    const finalBalance = raisedTotal / 100.0;
    const percentage = (finalBalance / (campaign.goal * 1.0)) * 100;
    return (
      <Box
        maxWidth={['100%', '100%', '100%', 315, 385]}
        minWidth={['100%', '100%', '100%', 315, 385]}
        style={{
          marginLeft: screenNarrow ? 0 : 24,
          borderRadius: screenNarrow ? 0 : 6,
          marginBottom: screenNarrow ? 20 : 0,
          padding: '24px',
          backgroundColor: 'white',
          flex: 1,
          flexDirection: 'column',
          height: 'fit-content',
          boxShadow: '0 0.3125rem 1rem -0.1875rem rgb(0 0 0 / 20%)',
          position: 'sticky',
          top: '10rem'
        }}
      >
        {screenNarrow && (
          <Header style={{ fontSize: 32 }}>{campaign.title}</Header>
        )}
        {isReady && (
          <Box className="target">
            <Text fontSize="24px" fontWeight="900">
              {`$${formatMoney(finalBalance, 2, '.', ',')}`}
            </Text>
            <Text alignSelf="flex-end" fontSize="1rem" margin="5px">
              raised of ${campaign.goal} target
            </Text>
          </Box>
        )}
        {isReady && (
          <Progress
            color="green"
            percent={Math.floor(percentage)}
            progress
            size="medium"
            style={{ marginBottom: 10 }}
          />
        )}
        {isReady && <Divider />}
        <section ref={buttonGroupRef}>
          <Button
            color="olive"
            fluid
            onClick={() => openModal()}
            size="large"
            style={{ marginTop: 10 }}
          >
            Share
          </Button>
          <Button
            color="green"
            disabled={!campaign || campaign.isComplete}
            fluid
            onClick={() => navigate(`/donation/${campaignId}`)}
            size="large"
            style={{ marginTop: 15 }}
          >
            Donate
          </Button>
        </section>
        <DonationList donations={donations} />
      </Box>
    );
  };

  const CampaignDetails = () => {
    const [trackerInViewport, setTrackerInViewport] = useState(true);

    return (
      <>
        <Transition.Group animation="fade down" duration={200}>
          {screenNarrow && !trackerInViewport && (
            <Box
              style={{
                position: 'fixed',
                padding: '2rem',
                backgroundColor: 'white',
                width: '100%',
                opacity: 1,
                zIndex: 2,
                boxShadow:
                  '0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)'
              }}
            >
              <Box style={{ flexDirection: 'row' }}>
                <Button
                  color="green"
                  fluid
                  onClick={() => navigate(`/donation/${campaignId}`)}
                  size="large"
                  style={{ marginRight: 12 }}
                >
                  Donate
                </Button>
                <Button
                  color="olive"
                  fluid
                  onClick={() => openModal()}
                  size="large"
                  style={{ marginLeft: 12 }}
                >
                  Share
                </Button>
              </Box>
            </Box>
          )}
        </Transition.Group>
        <Box
          maxWidth={['100%', '100%', 768, 578, 700]}
          minWidth={['100%', '100%', 768, 578, 700]}
          style={{
            flexDirection: 'column',
            flex: 1
          }}
        >
          <Modal
            closeBtnCb={closeModal}
            containerProps={{
              width: ['100%', '700px'],
              height: ['100%', 'auto'],
              minHeight: 'unset'
            }}
            isOpen={isOpen}
            onBgClick={closeModal}
            onCancel={closeModal}
            onEscapeKey={closeModal}
          >
            <ShareContent
              campaignId={campaignId}
              closeModal={closeModal}
              shareUrl={`${Config.webServerHost}campaign/donate/${campaignId}`}
            />
          </Modal>
          {campaign.video ? (
            <Box
              as="iframe"
              height="395px"
              src={campaign.video}
              style={{
                marginBottom: screenNarrow ? 0 : 20,
                borderRadius: screenNarrow ? 0 : 6,
                maxHeight: 395
              }}
              width="100%"
            />
          ) : (
            <Image
              src={
                campaign.image
                  ? campaign.image
                  : 'https://secureservercdn.net/198.71.233.179/n8t.f9f.myftpupload.com/wp-content/uploads/2019/09/logo.png'
              }
              style={{
                marginBottom: screenNarrow ? 0 : 20,
                borderRadius: screenNarrow ? 0 : 6,
                maxHeight: 395,
                objectFit: 'contain'
              }}
            />
          )}
          <Box
            marginLeft={[0, 0, 0, 88, 100]}
            maxWidth={['100%', '100%', 768, 490, 600]}
            minWidth={['100%', '100%', 768, 490, 600]}
            style={{
              flexDirection: 'column',
              right: 0
            }}
          >
            <Box display={['flex', 'flex', 'flex', 'none']}>
              <DonationTracker
                onEnterViewport={() => setTrackerInViewport(true)}
                onLeaveViewport={() => setTrackerInViewport(false)}
              />
            </Box>
            <Box
              style={{
                paddingLeft: screenNarrow ? 16 : 0,
                paddingRight: screenNarrow ? 16 : 0,
                flexDirection: 'column'
              }}
            >
              <Box>
                <Avatar
                  style={{
                    backgroundColor: theme.muiTheme.palette.success.main,
                    width: 32,
                    height: 32
                  }}
                />
                <Text alignSelf="center" margin="0 10px">
                  {campaign.owner.fullName} is organizing this fundraiser.
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontSize="14px">
                  Created {new Date(campaign.dateCreated).toDateString()}
                </Text>
              </Box>
              <Divider />
              <Editor
                editorClassName="donation-editor"
                editorState={editorState}
                onChange={() => {}}
                readOnly
                toolbarHidden
                wrapperClassName="readonly-editor-wrapper"
              />
              <Box style={{ marginBottom: 15, marginTop: 5 }}>
                <Button
                  color="green"
                  disabled={!campaign || campaign.isComplete}
                  fluid
                  onClick={() => navigate(`/donation/${campaignId}`)}
                  size="large"
                >
                  Donate
                </Button>
                <Button
                  color="olive"
                  fluid
                  onClick={() => openModal()}
                  size="large"
                >
                  Share
                </Button>
              </Box>
              <Divider />
              <Box style={{ marginTop: 15, flexDirection: 'column' }}>
                <Header>{`Comments (${
                  donations ? donations.filter((d) => d.message).length : '0'
                })`}</Header>
                {renderMessages()}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      style={{
        margin: screenNarrow ? 'auto auto' : '1.25rem auto',
        paddingLeft: screenNarrow ? 0 : '1rem',
        paddingRight: screenNarrow ? 0 : '1rem',
        flexDirection: 'column'
      }}
    >
      {!screenNarrow && (
        <Header style={{ fontSize: 32, marginTop: 10 }}>
          {campaign.title}
        </Header>
      )}
      <Box style={{ flexDirection: 'row', margin: '0 auto' }}>
        <CampaignDetails />
        <Box display={['none', 'none', 'none', 'flex']}>
          <DonationTracker />
        </Box>
      </Box>
    </Box>
  );
};

export default CampaignPage;
