import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import { Box, Text, Input, Button } from '../../components/elements';
import { useCampaigns } from '.';
import { useAuthService } from '../auth';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import { CircularIndeterminate } from '../../components/material/loader';
import { ManageCardContainer } from './manage-card-container';
import { debug, error } from '../../utils/logging';

const CampaignManager = () => {
  const { user, onLogin } = useAuthService();
  const [campaigns, setCampaigns] = useState(null);
  const [dashboardLinks, setDashboardLinks] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCampaigns = async () => {
    try {
      setIsLoading(true);
      const result = await fetcher({
        method: 'GET',
        url: `${Config.getMyCampaigns}`,
        withCredentials: true,
        headers: {
          // eslint-disable-next-line prettier/prettier
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      });
      const cams = result.data.data;
      setCampaigns(cams);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    debug('------------------Running fetch campaigns useEffect');
    fetchCampaigns();
  }, [lastUpdated]);

  const renderCampaigns = () => {
    if (campaigns && campaigns.length > 0) {
      return (
        <ManageCardContainer
          campaigns={campaigns}
          setLastUpdated={setLastUpdated}
        />
      );
    }
    return <Box mt="40px">There are no fundraising campaigns</Box>;
  };

  const renderLoading = () => {
    return <CircularIndeterminate />;
  };

  return (
    <>
      <Box flexWrap="wrap" justifyContent="center">
        {isLoading ? renderLoading() : renderCampaigns()}
      </Box>
    </>
  );
};

export { CampaignManager };
