import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';
import { useCampaigns } from '../../features/campaign';
import Config from '../../config';
import { Box } from '../elements';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { formatMoney } from '../../utils/helpers';
import CampaignPaymentsTable from './campaignPaymentsTable';
import { debug, error } from '../../utils/logging';

export default function ChequeTable({
  pkPayee,
  payee,
  payments,
  update,
  setSelectedCampaignId,
  selectedCampaignId,
  teams
}) {
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    columns: [
      // { title: 'ID', field: 'id' },
      {
        title: 'Date',
        field: 'date',
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        },
        editComponent: (props) => <div />
      },
      {
        title: 'Reference',
        field: 'reference',
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      },
      {
        title: 'Total Amount',
        field: 'total',
        render: (rowData) => {
          debug('RD', rowData);
          return <p>{`$${formatMoney(rowData.total / 100.0, 2, '.', ',')}`}</p>;
        },
        cellStyle: {
          width: 200,
          maxWidth: 200
        },
        headerStyle: {
          width: 200,
          maxWidth: 200
        }
      }
    ]
  });
  useEffect(() => {
    if (payments) {
      if (selectedCampaignId) {
        const filteredPayments = payments.filter((p) =>
          p.paymentItems.some((pi) => pi.fkCampaign === selectedCampaignId)
        );

        const newData = [...filteredPayments];
        setData(newData);
      }
      else {
        const newData = [...payments];
        setData(newData);
      }
    }
  }, [selectedCampaignId, pkPayee, update, payments]);

  const theme = createMuiTheme({
    overrides: {
      MuiInput: { root: { height: '30px' } },
      MuiIconButton: {
        root: {
          // color: '#fff',
          color: '#A7C957 !important',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            transition: 'all 0.5s ease-in-out'
          }
        },
        active: { color: '#fff' }
      }
    },
    palette: {
      primary: {
        main: '#A7C957',
        light: '#b8d378',
        dark: '#748c3c',
        contrastText: '#fff'
      }
    },
    typography: { htmlFontSize: 16 }
  });

  debug('data', data);
  if (!data) {
    return <Box />;
  }
  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        columns={state.columns}
        components={{
          Header: (props) => <MTableHeader {...props} />,
          Toolbar: (props) => (
            <div style={{ backgroundColor: '#fff' }}>
              <MTableToolbar {...props} />
              {selectedCampaignId && (
                <Box m="20px">
                  <Chip
                    color="secondary"
                    label={`Campaign ID: ${selectedCampaignId}`}
                    onDelete={() => {
                      debug('delete');
                      setSelectedCampaignId(null);
                    }}
                    // onClick={()=>{debug('click')}}
                    variant="outlined"
                  />
                </Box>
              )}
            </div>
          )
        }}
        data={data}
        detailPanel={[
          {
            tooltip: '',
            render: (rowData) => {
              debug('rowData', rowData);

              return (
                <div
                  style={{
                    fontSize: 16,
                    textAlign: 'center'
                  }}
                >
                  <CampaignPaymentsTable
                    paymentItems={rowData.paymentItems}
                    teams={teams}
                  />
                </div>
              );
            }
          }
        ]}
        options={{
          sorting: true,
          grouping: false,
          // actionsColumnIndex: 4,
          search: true,
          headerStyle: {
            backgroundColor: '#A7C957',
            color: '#FFF'
          },
          searchFieldStyle: {
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.87);'
          }
        }}
        // actions={[
        //   {
        //     icon: 'settings',
        //     tooltip: 'Dashboard',
        //     onClick: (event, rowData) =>
        //       window.open('https://dashboard.stripe.com', '_blank'),
        //   },
        //   {
        //     icon: 'attach_money',
        //     tooltip: 'Accounting',
        //     onClick: (event, rowData) => {
        //       debug('Accounting clicked')
        //       navigate('/payee/accounting')
        //     },
        //   },
        // ]}
        search
        title={`Payments for Payee: ${
          payee == null ? pkPayee : payee.displayName
        } `}
      />
    </MuiThemeProvider>
  );
}
