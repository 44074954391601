import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import { useCampaigns } from '../campaign';
import { useAuthService } from '../auth';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { Box } from '../../components/elements';
import { debug, error } from '../../utils/logging';

const useStyles = makeStyles(theme => ({
  inputRoot: {
    fontSize: '14px'
  },
  labelRoot: {
    marginTop: '-10px',
    fontSize: '14px'
    // color: 'red',
    // '&$labelFocused': {
    //   color: 'purple',
    // },
  }
}));

export default function NewPayee({ onCancel }) {
  const urlParams = new URLSearchParams(window.location.search);
  const newstripeid = urlParams.get('newstripeid');

  const classes = useStyles();
  const [displayName, setDisplayName] = React.useState('');
  const [stripeID, setStripeID] = React.useState('');
  const [restrictions, setRestrictions] = React.useState('');
  const {
    currentCampaign,
    serializeCampaign,
    deserializeCampaign,
    fetchPayees,
    newPayeeForm,
    setNewPayeeForm
  } = useCampaigns();
  const { serializeAuth, deserializeAuth } = useAuthService();

  useEffect(() => {
    if (newPayeeForm) {
      debug('loading form with previous data:', newPayeeForm);
      setDisplayName(newPayeeForm.displayName);
      setStripeID(newPayeeForm.stripeID);
      setRestrictions(newPayeeForm.restrictions);
    }
  }, [newstripeid, newPayeeForm]);

  const handleDisplayNameChange = event => {
    setDisplayName(event.target.value);
  };

  const handleStripeIDChange = event => {
    setStripeID(event.target.value);
  };

  const handleRestrictionsChange = event => {
    setRestrictions(event.target.value);
  };

  const savePayeeInfo = async (displayName, stripeID, restrictions) => {
    fetcher({
      method: 'POST',
      url: `${Config.addPayeeInfoEndpoint}`,
      data: { displayName, stripeID, restrictions },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    })
      .then(res => {
        fetchPayees();
        onCancel();
      })
      .catch(err => {
        debug(err);
      });
  };

  const getAllSavedData = formData => {
    const saved = {
      currentCampaign,
      newPayeeForm: formData
    };
    const jsonSaved = JSON.stringify(saved);
    debug('all saved data:', jsonSaved);
    return jsonSaved;
  };

  return (
    <form autoComplete="off" noValidate>
      <Box flexDirection="column" width="100%">
        <Box m="10px">
          <FormControl style={{ width: '100%' }}>
            <TextField
              defaultValue=""
              id="display-name"
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot
                }
              }}
              InputProps={{ classes: { root: classes.inputRoot } }}
              label="Display Name"
              onChange={handleDisplayNameChange}
              value={displayName}
            />
          </FormControl>
        </Box>
        <Box m="10px">
          <FormControl
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            <TextField
              defaultValue=""
              fullWidth
              id="stripe-account"
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot
                }
              }}
              InputProps={{ classes: { root: classes.inputRoot } }}
              label="Stripe Account"
              onChange={handleStripeIDChange}
              value={stripeID}
            />
            <Button
              color="secondary"
              onClick={() => {
                const payeeFormData = {
                  displayName,
                  stripeID,
                  restrictions
                };
                setNewPayeeForm(payeeFormData);
                const encodedState = getAllSavedData(payeeFormData);
                window.location = `https://connect.stripe.com/oauth/authorize?redirect_uri=${Config.webServerHost}addpayee&response_type=code&client_id=${Config.stripeConnectClientId}&state=${encodedState}&scope=read_write`;
                // window.location = `https://connect.stripe.com/express/oauth/authorize?    redirect_uri=${Config.webServerHost}addpayee    &client_id=${Config.stripeConnectClientId}    &state=${encodedState}    &suggested_capabilities[]=card_payments`
              }}
              size="medium"
              style={{ marginLeft: '20px' }}
              variant="contained"
            >
              Connect
            </Button>
          </FormControl>
        </Box>
        <Box m="10px">
          <FormControl
            style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
          >
            <TextField
              defaultValue=""
              fullWidth
              id="restrictions"
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot
                }
              }}
              InputProps={{ classes: { root: classes.inputRoot } }}
              label="Restrictions"
              onChange={handleRestrictionsChange}
              value={restrictions}
            />
            <Button
              color="secondary"
              size="medium"
              style={{ marginLeft: '20px' }}
              variant="contained"
            >
              Restrict
            </Button>
          </FormControl>
        </Box>

        <Button
          color="primary"
          onClick={() => {
            debug(displayName, stripeID, restrictions);
            savePayeeInfo(displayName, stripeID, restrictions);
          }}
          size="medium"
          style={{ marginTop: '20px' }}
          variant="contained"
        >
          Save
        </Button>
      </Box>
    </form>
  );
}
