import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import { Box, Button, Input, Text } from '../../components/elements';
import { Message, Loading } from '../../components/compound';
import { useAuthService } from './auth.provider';

const propTypes = {
  changeView: PropTypes.func,
  closeModal: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func
};

const defaultProps = {
  changeView: null,
  closeModal: null,
  fullScreen: false,
  navigate: null
};
const initialState = {
  Email: '',
  Password: ''
};

const Login = ({ changeView, closeModal, fullScreen, navigate }) => {
  const { onReset, onLogin, error, clearErrors, loading } = useAuthService();
  const emailRef = useRef();

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    onReset();
    setFormState(initialState);
    if (emailRef && emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    if (fullScreen) {
      onLogin(formState, () => navigate('/'));
      return;
    }

    onLogin(formState, closeModal);
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setFormState({
      ...formState,
      [name]: value
    });
  };

  const viewSwitch = view => {
    clearErrors();
    setFormState(initialState);

    if (fullScreen) {
      navigate(`/${view}`);
      return;
    }

    changeView(view);
  };

  return (
    <Box flexDirection="column" minWidth="10rem" width="100%">
      <Text fontSize={5} fontWeight={[0]} mb={5} mx="auto">
        Login
      </Text>

      <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
        <Input
          ref={emailRef}
          containerProps={{
            width: '100%',
            height: '70px',
            alignItems: 'start'
          }}
          label="Email"
          name="Email"
          onChange={handleChange}
          variant="register"
        />
        <Input
          containerProps={{
            mt: 4,
            width: '100%',
            height: '70px',
            alignItems: 'start'
          }}
          label="Password"
          name="Password"
          onChange={handleChange}
          type="password"
          variant="register"
        />

        {loading ? (
          <Box mt={5}>
            <Loading dotBackgroundColor="black" />
          </Box>
        ) : (
          <Box
            className="testbox"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Button mt={5} type="submit" variant="login" width="100%">
              Login
            </Button>
            <Button
              // color={theme.colors.blue}
              // color={theme.colors.greys[4]}
              ml={2}
              onClick={() => viewSwitch('forgot-password')}
              type="button"
              variant="forgot-password"
            >
              Forgot your password?
            </Button>
          </Box>
        )}
      </Box>

      {error && (
        <Message mt={5} variant="error">
          {error}
        </Message>
      )}
      {/* <Text alignItems="center" display="flex" ml={[2, 3]} mt={3}>
        New to Sportsheadz?
        <Button
          ml={2}
          onClick={() => viewSwitch('create-account')}
          type="button"
          variant="regularlink"
        >
          Sign up
        </Button>
      </Text> */}

      {/* <Text alignItems="center" display="flex" mt={3}>
          Submit confirmation code:
          <Button
            ml={2}
            onClick={() => viewSwitch('confirm')}
            type="button"
            variant="secondary"
          >
            Confirm your account
          </Button>
        </Text> */}
    </Box>
  );
};

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export { Login };
