import React from 'react';
import { navigate } from '@reach/router';
import { Button, useMediaQuery } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Box, MarketingBanner, Text } from '../../components/elements';
import { CircularIndeterminate } from '../../components/material/loader';
import hero from '../../images/site-hero.jpg';
import eraise1 from '../../images/eraise-1.png';
import eraise2 from '../../images/eraise-2.png';
import eraise3 from '../../images/eraise-3.jpg';
import eraise4 from '../../images/eraise-4.png';
import { useAuthService } from '../auth/auth.provider';
import { theme } from '../../styles';

const Home = () => {
  const { loading } = useAuthService();

  const screenNarrow = useMediaQuery('(max-width:767px)');
  const heroRenderBreakpoints = [
    useMediaQuery('(max-width:1921px)'),
    useMediaQuery('(max-width:2800px)'),
    useMediaQuery('(max-width:3200px)')
  ];

  if (loading) {
    return (
      <Box flexDirection="column">
        <Box flexGrow={1} justifyContent="center">
          <CircularIndeterminate />
        </Box>
      </Box>
    );
  }
  return (
    <Box
      flexDirection="column"
      ml="auto"
      mr="auto"
      style={{ overflowWrap: 'break-word' }}
      width={['100%', '100%']}
    >
      <Box
        style={{
          position: 'relative',
          width: '100%',
          display: 'block',
          height: '60vw',
          padding: '70px 45px',
          minHeight: 700,
          maxHeight: 700
        }}
      >
        <Box
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0
          }}
        >
          <img
            alt="Sportsheadz site hero"
            src={hero}
            style={{
              width: '100%',
              maxWidth: '100%',
              objectFit: 'cover',
              objectPosition: heroRenderBreakpoints[0]
                ? '50% 50%'
                : heroRenderBreakpoints[1]
                ? '50% -150px'
                : heroRenderBreakpoints[2]
                ? '50% -250px'
                : '50% -420px',
              maxHeight: 1020
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: 'rgba(0,0,0,.3)'
            }}
          />
        </Box>
        <Box
          padding={['90px 0', '60px 0', '140px 0']}
          style={{
            position: 'relative',
            margin: '60px auto',
            width: '100%',
            zIndex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            flex: 1,
            flexDirection: 'column'
          }}
        >
          <Text
            fontSize={['28px', '50px', '64px']}
            lineHeight={['30px', '50px', '70px']}
            style={{ color: 'white', fontWeight: 'bold' }}
          >
            Support local youth sports.
          </Text>
          <Text
            fontSize={['18px', '26px', '26px']}
            style={{ color: 'white', fontWeight: 'bold' }}
          >
            Getting started is easy. No fee to start a campaign.
          </Text>
          <Button
            endIcon={
              <ChevronRight style={{ fontSize: screenNarrow ? 24 : 40 }} />
            }
            onClick={() => navigate('/campaign/browse')}
            style={{
              marginTop: 20,
              width: screenNarrow ? 180 : 280,
              maxWidth: '100%',
              height: screenNarrow ? 45 : 60,
              fontSize: screenNarrow ? 16 : 28,
              fontWeight: '400',
              letterSpacing: '0.1px',
              backgroundColor: theme.muiTheme.palette.success.main,
              color: 'white'
            }}
            variant="contained"
          >
            Campaigns
          </Button>
        </Box>
      </Box>
      <Box
        style={{ flex: 1, flexDirection: 'column', backgroundColor: '#000' }}
      >
        <MarketingBanner
          backgroundColor="#fbf8f6"
          image={eraise1}
          imageHeight={415}
          imageStyle={{ borderRadius: 50 }}
          text={`With our passion for sports, we are focused on creating more 
          engagement with the youth. At the same time, we want to make it easier 
          for everyone to be able to enjoy the sports they love. That is why we 
          have developed SportsHeadz eRAISE – a suite of online tools to leverage 
          social media sharing and introduce contact-less fundraising for your associations.`}
          title="Next-level fundraising."
        />
        <MarketingBanner
          backgroundColor="white"
          image={eraise2}
          imageHeight={415}
          invert
          text={`Our platform drives your success. We do not charge any fee to start 
          an eRAISE campaign. Instead - SportsHeadz charges a small transaction fee on donations. 
          We want everyone to have access to the sports they love and they equipment they need, 
          and that's why we're motivated to work with you to maximize the exposure and success 
          of your unique fundraising experience.`}
          title="Free for organizers."
        />
        <MarketingBanner
          backgroundColor="#fbf8f6"
          image={eraise4}
          imageHeight={385}
          text={`Choose from one of our flexible fundraising options; 'Classic' and 'Store'.\n\n
          With 'Classic' you can create your fundraising campaign, share it with the world, and 
          track donations as they are received.\n\n
          Or; with our new 'Store' option, your campaign can provide products for purchase, and
          track purchases as they are made!`}
          title="Flexible experience."
        />
        <MarketingBanner
          backgroundColor="white"
          image={eraise3}
          imageHeight={300}
          imageStyle={{ borderRadius: 50 }}
          invert
          text={`Our fundraising experience integrates closely with the rest of the SportsHeadz 
          services. This means fundraising campaigns can appear on team websites, as well as have 
          notifications of new campaigns be delivered to mobile users. SportsHeadz is proud to offer many services 
          across youth sports - and to use these services to help enable everyone to play the sports
          they love.`}
          title="Powerful integrations."
        />
        <Box style={{ padding: '70px 45px', backgroundColor: '#fbf8f6' }}>
          <Box
            flexDirection={['column', 'column', 'row']}
            style={{
              margin: '0 auto',
              maxWidth: 1100,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box
              style={{
                flexDirection: 'column',
                textAlign: 'center',
                padding: '0 30px'
              }}
            >
              <Text
                fontSize={['26px', '40px', '50px']}
                lineHeight={['28px', '44px', '55px']}
                style={{ color: 'black', fontWeight: 'bold' }}
              >
                Make a difference in your community today.
              </Text>
              <Text
                fontSize={['18px', '20px', '26px']}
                style={{ color: 'black', fontWeight: '400', marginTop: 25 }}
              >
                Browse our public campaigns, or start a new campaign for free!
              </Text>
              <Button
                endIcon={
                  <ChevronRight style={{ fontSize: screenNarrow ? 24 : 40 }} />
                }
                onClick={() => navigate('/campaign/browse')}
                style={{
                  marginTop: 20,
                  width: screenNarrow ? 180 : 280,
                  maxWidth: '100%',
                  height: screenNarrow ? 45 : 60,
                  fontSize: screenNarrow ? 16 : 28,
                  fontWeight: '400',
                  letterSpacing: '0.1px',
                  alignSelf: 'center',
                  backgroundColor: theme.muiTheme.palette.success.main,
                  color: 'white'
                }}
                variant="contained"
              >
                View all
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export { Home };
