import React from 'react';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { Box, Text } from '../../components/elements';
import { formatMoney } from '../../utils/helpers';

const DonationList = ({ donations }) => {
  if (!donations || donations.length <= 0) {
    return <Box />;
  }

  const isMoreThan5 = donations.length > 5;

  const renderDonarList = () => {
    return donations
      .filter((f, index) => {
        if (isMoreThan5) {
          return index < 5;
        }
        return true;
      })
      .map((d, index) => {
        return (
          <Box key={d.id} flexDirection="column">
            {index < 4 && <Divider style={{ margin: '10px 0' }} />}
            <Text className="name">{d.name}</Text>
            <Box fontSize="14px" lineHeight="15px">
              <Text className="amount" fontWeight="600">
                ${formatMoney(d.amount / 100.0, 2, '.', ',')}
              </Text>
              <Text className="days" fontColor="darkgrey" ml="4px">
                &bull; {moment(new Date(d.dateCreated)).fromNow()}
              </Text>
            </Box>
          </Box>
        );
      });
  };

  return (
    <Box flexDirection="column" mt="10px">
      {renderDonarList()}
      {isMoreThan5 && (
        <Button
          color="secondary"
          size="large"
          style={{
            marginTop: '10px',
            width: '280px',
            alignSelf: 'center'
          }}
          variant="outlined"
        >
          See all
        </Button>
      )}
    </Box>
  );
};
export { DonationList };
