import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Img, Box } from '../../components/elements';
import { debug, error } from '../../utils/logging';

const useStyles = makeStyles({ root: { maxWidth: 345 } });

export default function TargetCard(props) {
  const { target, selectedTarget, onClick } = props;
  const classes = useStyles();

  const { id, name, image, entity } = target;
  debug(id, { props });
  const isSelected = selectedTarget && selectedTarget.id === id;
  return (
    <Box
      css={({ theme }) => `
      .target-card:hover {
        background-color: rgba(243,194,10, 0.2) !important;
        cursor: pointer;
      }

              `}
      height="100px"
      m="10px"
      onClick={() => onClick(target)}
      width={['100%', '100%', '263px']}
    >
      <Card
        className={(classes.root, 'target-card')}
        style={{
          width: '100%',
          background: isSelected ? 'rgba(243,194,10, 0.2)' : 'transparent'
        }}
      >
        <Box>
          <Img
            alt="avatar"
            height="6.25rem"
            src={
              image ||
              'https://hockeyheadz-storage.s3.amazonaws.com/public/90-Avatar-1602034632.png'
            }
            width="6.25rem"
          />
          <CardContent>
            {/* <Typography color="textSecondary" component="p" variant="body2">
              {`ID: ${entity.toUpperCase()}${id}`}
            </Typography> */}
            <Typography component="h2" gutterBottom variant="body1">
              {name}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
