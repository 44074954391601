export const links = {
  homeLinks: [
    {
      to: '/',
      title: 'Home',
      access: 'public',
      side: 'left'
    },
    {
      to: '/campaign/browse',
      title: 'Browse',
      access: 'public',
      side: 'left'
    },
    {
      to: '/campaign/manage',
      title: 'My Campaigns',
      access: 'authenticated',
      side: 'left'
    },
    {
      to: '/contact-us',
      title: 'Contact Us',
      access: 'public',
      side: 'right'
    },
    {
      to: '/campaign/create/details',
      title: 'Create Campaign',
      access: 'authenticated',
      side: 'right'
    }
  ]
};
