import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import { TagFacesTwoTone } from '@material-ui/icons';
import { Box, Text, Input } from '../../components/elements';
import { useCampaigns } from '.';
import TargetCard from './targetcard';
import TargetFilter from '../../components/material/target-filter';
import Config from '../../config';
import { fetcher } from '../../network/fetcher';
import { useAuthService } from '../auth';
import {
  CircularIndeterminate,
  DimmedLoaderWrapper
} from '../../components/material/loader';
import { debug, error } from '../../utils/logging';

const CampaignTargetSelector = ({ closeModal }) => {
  const {
    // defaultTeamId,
    // token,
    // filteredTeam,
    // teams,
    // associationDomain,
    loading
  } = useAuthService();
  const [availableTargets, setAvailableTargets] = useState(null); // useState([])
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const [targetFilter, setTargetFilter] = useState({
    showTeams: true,
    showPlayers: false
  });
  const { setCurrentCampaign, getCurrentCampaign } = useCampaigns();

  const createTarget = (name, image, id, entity) => {
    return {
      name,
      image,
      id,
      entity
    };
  };

  const AddToTargets = (targets, target) => {
    if (targets) {
      const index = targets.findIndex(
        (t) => t.id === target.id && t.entity === target.entity
      );
      const alreadyExist = index >= 0;
      if (!alreadyExist) {
        targets.push(target);
      }
    }
  };

  const buildTargets = (data) => {
    const targets = [];
    const { teams } = data;
    teams.forEach((team) => {
      debug(team);

      // Add teams to target collection
      const teamTarget = team.team;
      const target = createTarget(
        teamTarget.name,
        teamTarget.image,
        teamTarget.pkTeam,
        'team'
      );
      AddToTargets(targets, target);

      // Add association to target collection
      const { association } = teamTarget;
      const associationTarget = createTarget(
        association.name,
        association.image,
        association.pkAssociation,
        'association'
      );
      AddToTargets(targets, associationTarget);

      const teamMembers = team.members;
      teamMembers.forEach((memberTarget) => {
        const targetUser = createTarget(
          memberTarget.name,
          memberTarget.avatar,
          memberTarget.pkUser,
          'user'
        );
        AddToTargets(targets, targetUser);
      });
    });

    targets.sort((a, b) => {
      if (a.entity === b.entity) {
        return 0;
      }
      if (a.entity > b.entity) {
        return 1;
      }
      return -1;
    });
    return targets;
  };

  const fetchTargets = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.getAvailableTargetsEndpoint}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    setLocalLoading(true);
    fetchTargets()
      .then((res) => {
        setLocalLoading(false);

        const targets = buildTargets(res.data);
        setAvailableTargets(targets);
      })
      .catch((err) => {
        setLocalLoading(false);
      });
  }, []);

  // useEffect(() => {
  //   if (defaultTeamId && teams && filteredTeam) {
  //     setLocalLoading(true)
  //     fetcher({
  //       method: 'GET',
  //       url: `${Config.getAvailableTargetsEndpoint}?team=${filteredTeam.ID}`,
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //       .then(res => {
  //         setLocalLoading(false)
  //         debug(res.data.data)
  //         let availableTargetsResult = res.data.data
  //         availableTargetsResult.forEach(r => {
  //           if (r.Image) {
  //             r.Image = `https://${associationDomain}${r.Image}`
  //           } else {
  //             r.Image = `https://${associationDomain}/public/images/common/logo.png`
  //           }
  //         })
  //         setAvailableTargets(availableTargetsResult)
  //       })
  //       .catch(err => {
  //         setLocalLoading(false)
  //         debug(err)
  //       })
  //   }
  // }, [defaultTeamId, teams, filteredTeam])

  const handleChange = () => {
    debug('handle change for input');
  };

  const renderTargetCards = () => {
    if (availableTargets) {
      const filteredTargets = availableTargets.filter((a) => {
        if (!targetFilter.showTeams && a.entity === 'team') {
          return false;
        }
        if (!targetFilter.showPlayers && a.entity === 'user') {
          return false;
        }
        return true;
      });

      debug('filteredTargets', filteredTargets);

      return filteredTargets.map((a) => (
        <TargetCard
          key={`${a.entity}${a.id}`}
          onClick={(t) => setSelectedTarget(t)}
          selectedTarget={selectedTarget}
          target={a}
        />
      ));
    }
    return <div>No targets found</div>;
  };

  if (loading || localLoading) {
    //   <Box flexDirection="column">
    //     <Box alignItems="center" flexGrow={1} justifyContent="center">
    //       <CircularIndeterminate />
    //     </Box>
    //   </Box>
    return (
      <Box style={{ height: 150 }}>
        <DimmedLoaderWrapper active disableDimming />
      </Box>
    );
  }

  // if (!filteredTeam) {
  //   return <div>No team selected</div>;
  // }
  // return (
  //   <Box border="1px solid purple">
  //     <Box bg="blue" width="200px" height="200px"></Box>
  //   </Box>
  // )
  return (
    <Box flexDirection="column" height="100%">
      <Box className="filtering" justifyContent="flex-end" margin="20px 30px">
        <TargetFilter
          setTargetFilter={setTargetFilter}
          targetFilter={targetFilter}
        />
      </Box>
      <Box
        flexGrow="1"
        flexWrap="wrap"
        justifyContent="flex-start"
        maxHeight="600px"
        minHeight="240px"
        overflowY="auto"
        width="100%"
      >
        {renderTargetCards()}
      </Box>
      <Box height="105px" justifyContent="center">
        <Box justifyContent="center" width="200px">
          {/* <Button
            disabled={!selectedTarget}
            onClick={async () => {
              if (selectedTarget) {
                debug('Save button was clicked')
                const savedData = {
                  ...getCurrentCampaign(),
                  target: selectedTarget,
                }
                await setCurrentCampaign(savedData)
                debug('saving currentCampaign to reducer', savedData)
                closeModal()
              }
            }}
            type="button"
            variant="next"
          >
            Select
          </Button> */}

          <Button
            color="secondary"
            disabled={!selectedTarget}
            onClick={async () => {
              if (selectedTarget) {
                debug('Save button was clicked');
                const savedData = {
                  ...getCurrentCampaign(),
                  target: selectedTarget
                };
                await setCurrentCampaign(savedData);
                debug('saving currentCampaign to reducer', savedData);
                closeModal();
              }
            }}
            size="large"
            style={{
              marginTop: '15px',
              height: '45px',
              fontSize: '16px'
            }}
            variant="contained"
          >
            Select
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export { CampaignTargetSelector };
