import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Input, Text } from '../../components/elements'
import { Message, Loading } from '../../components/compound'
import { useAuthService } from './auth.provider'

const propTypes = {
  changeView: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func,
}

const defaultProps = {
  changeView: null,
  fullScreen: false,
  navigate: null,
}

const ForgotPassword = ({ changeView, fullScreen, navigate }) => {
  const {
    onReset,
    onForgotPassword,
    error,
    clearErrors,
    loading,
  } = useAuthService()
  const emailRef = useRef()

  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    onReset()
    if (emailRef && emailRef.current) {
      emailRef.current.focus()
    }

    // FORCE RESET
    setEmail('')
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    onForgotPassword(email)
    setSuccess(true)
  }

  const viewSwitch = view => {
    clearErrors()

    if (fullScreen) {
      navigate(`/${view}`)
      return
    }

    changeView(view)
  }

  const handleChange = e => {
    const { value } = e.target
    setEmail(value)
  }

  return (
    <Box flexDirection="column" minWidth="10rem" width="100%">
      <Text fontSize={5} fontWeight={[0]} mb={5}>
        Reset Password
      </Text>

      {success ? (
        <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
          <Text>
            The password reset instructions have been sent to your email!
          </Text>
          <Button
            justifyContent="center"
            mt={5}
            onClick={() => viewSwitch('reset-password')}
            type="button"
            variant="login"
          >
            Next
          </Button>
        </Box>
      ) : (
        <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
          <Input
            ref={emailRef}
            containerProps={{
              width: '100%',
              height: '70px',
            }}
            label="Email"
            name="Email"
            onChange={handleChange}
            required
            type="email"
            value={email}
            variant="register"
          />

          {error && (
            <Message mt={5} variant="error">
              {error}
            </Message>
          )}

          {loading ? (
            <Box mt={5}>
              <Loading dotBackgroundColor="black" />
            </Box>
          ) : (
            <Button
              alignSelf="flex-start"
              //   disabled={isDisabled}
              mt={5}
              type="submit"
              variant="login"
            >
              Submit
            </Button>
          )}

          <Box alignItems="center" mt={3}>
            Remembered your password?
            <Button
              ml={2}
              onClick={() => viewSwitch('login')}
              type="button"
              variant="regularlink"
            >
              Login
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

ForgotPassword.propTypes = propTypes
ForgotPassword.defaultProps = defaultProps

export { ForgotPassword }
