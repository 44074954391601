import React, { Fragment, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Img, Text } from '../../components/elements';
import DetailedCardForm from '../stripe/detailedcardform';
import CartProduct from '../stripe/cart-product';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import { CircularIndeterminate } from '../../components/material/loader';
import { CartPriceSummary } from '../stripe/cart-price-summary';
import TrustLogo from '../../images/powered-by-stripe.png';
import { debug, error } from '../../utils/logging';

const CheckoutPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const amount = urlParams.get('amount');
  const campaignId = urlParams.get('campaignId');
  const message = urlParams.get('message');

  const [campaign, setCampaign] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stripePromise, setStripePromise] = useState(null);
  const [logo1DisplayValue, setLogo1DisplayValue] = useState('block');
  const [logo2DisplayValue, setLogo2DisplayValue] = useState('block');

  const [logo1, setLogo1] = useState('');
  const [logo2, setLogo2] = useState('');

  useEffect(() => {
    fetcher({
      method: 'GET',
      url: `${Config.getCampaignEndpoint}?id=${campaignId}`
    }).then((result) => {
      debug(`fetch campaign ${campaignId}`, { result });
      setCampaign(result.data.data);

      const { domain } = result.data.data.target;
      debug(result);

      if (domain) {
        const logoSrc1 = `https://${domain}/public/images/common/logo.png`;
        const logoSrc2 = `https://${domain}/domains/${domain}/logo.png`;
        fetcher({
          method: 'GET',
          url: logoSrc1
        })
          .then((res) => {
            setLogo1(logoSrc1);
          })
          .catch((err) => {
            setLogo2(logoSrc2);
          });
      }

      const loadStripeResult = loadStripe(Config.stripePublicKey);
      setStripePromise(loadStripeResult);
    });
  }, []);

  const AssociationLogo = () => {
    return (
      <Box justifyContent="center">
        {logo1 && (
          <Img
            alignSelf="center"
            alt="association logo"
            height="100px"
            onError={(err) => {
              debug(`Bad assoc image: ${err}`);
              setLogo1DisplayValue('none');
            }}
            src={logo1}
            style={{ display: logo1DisplayValue }}
            width="150px"
          />
        )}
        {logo2 && (
          <Img
            alignSelf="center"
            alt="association logo"
            height="100px"
            onError={(err) => {
              debug(`Bad assoc image: ${err}`);
              setLogo2DisplayValue('none');
            }}
            onSuccess={(err) => {
              debug(`Worked!`);
            }}
            src={logo2}
            style={{ display: logo2DisplayValue }}
            width="150px"
          />
        )}
      </Box>
    );
  };

  if (!(amount && campaignId)) {
    return <Box>No campaign or amount found.</Box>;
  }

  if (!campaign || isLoading || !stripePromise) {
    return (
      <Box>
        <CircularIndeterminate />
      </Box>
    );
  }

  const donation = {
    amount,
    tip: 10,
    message
  };

  return (
    <Box
      flexDirection="column"
      m="0 auto"
      width={['100%', '100%', '100%', '100%', '1200px']}
    >
      <Box mt="20px" width="100%">
        <Box flexDirection="column" justifyContent="center" m="0 auto">
          <AssociationLogo domain={campaign.target.domain} />
          <Text textAlign="center">{campaign.target.domain}</Text>
        </Box>
      </Box>
      <Box
        flexDirection={[
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'row'
        ]}
      >
        <Box
          className="left-side"
          justifyContent="center"
          p={['5px', '5px', '20px 50px']}
        >
          <DetailedCardForm
            campaign={campaign}
            donation={donation}
            stripePromise={stripePromise}
          />
        </Box>
        <Box
          className="right-side"
          flex={1}
          flexDirection="column"
          justifyContent="flex-start"
          p={['5px', '5px', '20px 50px']}
        >
          <CartProduct campaign={campaign} donation={donation} />
          <CartPriceSummary donation={donation} />
          <Box
            alignSelf="center"
            display={['none', 'none', 'flex']}
            maxWidth="400px"
            mt="40px"
            width="100%"
          >
            <Img
              alt="trust logo"
              height="50px"
              maxWidth="400px"
              onError={(err) => {
                debug(`Bad trust image: ${err}`);
              }}
              src={TrustLogo}
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutPage;
