import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { PurpleSwitch } from '../../components/material/purple-switch';
import { Box, Text, Input } from '../../components/elements';
import { useCampaigns, CampaignTargetSelector } from '.';
import { useModal } from '../../components/modules/modal';
import { debug, error } from '../../utils/logging';

const CreateCampaignDetails = ({ changeView }) => {
  const { enqueueSnackbar } = useSnackbar();
  // access refs
  const campaignTitleInputRef = useRef();
  const campaignGoalInputRef = useRef();
  const campaignTargetTextRef = useRef();

  // initialize reducer
  const { setCurrentCampaign, getCurrentCampaign } = useCampaigns();
  const { openModal, closeModal, isOpen, Modal } = useModal();

  // setup local state
  const [details, setDetails] = useState({});

  // load from reducer
  const loadInitialValues = () => {
    const initial = getCurrentCampaign();
    debug('loadInitialValues initial', initial);
    let newDetails = {};
    if (initial) {
      if (
        initial.title &&
        campaignTitleInputRef &&
        campaignTitleInputRef.current
      ) {
        campaignTitleInputRef.current.value = initial.title;
        newDetails = { ...newDetails, title: initial.title };
        debug('loading title from reducer', initial.title);
      }
      if (
        initial.goal &&
        campaignGoalInputRef &&
        campaignGoalInputRef.current
      ) {
        campaignGoalInputRef.current.value = initial.goal;
        newDetails = { ...newDetails, goal: initial.goal };
        debug('loading goal from reducer', initial.goal);
      }
      if (
        initial.target &&
        campaignTargetTextRef &&
        campaignTargetTextRef.current
      ) {
        const val = `${initial.target.name}`;
        campaignTargetTextRef.current.innerHTML = val;
        newDetails = { ...newDetails, target: initial.target };
        debug('loading target from reducer', val);
      }
      newDetails.isPrivate = initial.isPrivate;
      setDetails(newDetails);
    }
  };

  const reducerValue = getCurrentCampaign();
  debug('reducerValue', reducerValue);

  useEffect(() => {
    debug('running useEffect');
    // load values from reducer
    loadInitialValues();
  }, [reducerValue]);

  // handle all changes from inputs
  const handleChange = e => {
    if (e.target.name === 'campaign-title') {
      const isSame = e.target.value === details.title;
      if (!isSame) {
        setDetails({
          ...details,
          title: e.target.value
        });
        debug('setDetails', details);
        if (campaignTitleInputRef && campaignTitleInputRef.current) {
          campaignTitleInputRef.current.value = e.target.value;
        }
      }
    } else if (e.target.name === 'campaign-goal') {
      const isSame = e.target.value === details.goal;
      if (!isSame) {
        setDetails({
          ...details,
          goal: e.target.value
        });
        debug('setDetails', details);
        if (campaignGoalInputRef && campaignGoalInputRef.current) {
          campaignGoalInputRef.current.value = e.target.value;
        }
      }
    } else if (e.target.name === 'campaign-private') {
      const isSame = e.target.checked === details.isPrivate;
      if (!isSame) {
        setDetails({
          ...details,
          isPrivate: e.target.checked
        });
        debug('setIsPrivate', details);
      }
    }
  };

  const saveLocalStateToReducer = async (bypassValidation = false) => {
    if (campaignTitleInputRef && campaignTitleInputRef.current) {
      const { goal, title, target } = details;
      if (!bypassValidation) {
        if (!goal) {
          enqueueSnackbar('Please enter a goal amount', {
            variant: 'warning'
          });
          return false;
        }
        if (!title) {
          enqueueSnackbar('Please enter a title', {
            variant: 'warning'
          });
          return false;
        }
        if (!target) {
          enqueueSnackbar('Please select a recipient', {
            variant: 'warning'
          });
          return false;
        }
      }
      const savedData = { ...getCurrentCampaign(), ...details };
      await setCurrentCampaign(savedData);
      debug('saving currentCampaign to reducer', savedData);
      return true;
    }
  };

  const submitAndGoToNext = async () => {
    debug('Next button was clicked');
    const valid = await saveLocalStateToReducer();
    if (valid) {
      viewSwitch('cover');
    }
  };

  const viewSwitch = view => {
    debug('switching to', view);
    changeView(view);
  };

  return (
    <>
      <Modal
        closeBtnCb={closeModal}
        containerProps={{
          width: ['100%', '900px'],
          height: ['100%', 'auto'],
          minHeight: 'unset'
        }}
        isOpen={isOpen}
        onBgClick={closeModal}
        onCancel={closeModal}
        onEscapeKey={closeModal}
      >
        <CampaignTargetSelector closeModal={closeModal} />
      </Modal>
      <Box
        bg="white"
        boxShadow="0 0 1rem 0rem rgba(0,0,0,0.3)"
        flexDirection="column"
        ml="auto"
        mr="auto"
        mt="20px"
        width={['100%', '100%', '500px']}
      >
        <Box
          className="campaign-details"
          color="text"
          flexDirection="column"
          p="50px"
          width="100%"
        >
          <Text
            fontSize={5}
            fontWeight="700"
            // mt="20px"
          >
            Enter your goal here
          </Text>
          <Box className="goal" justifyContent="flex-start">
            <Box flexDirection="column">
              <Text
                color="green"
                fontSize={6}
                fontWeight={3}
                ml="10px"
                mr="10px"
                width="20px"
              >
                $
              </Text>
              <Text
                alignSelf="center"
                color="green"
                fontSize={2}
                fontWeight={3}
                mt="-10px"
              >
                CAD
              </Text>
            </Box>
            <Input
              ref={campaignGoalInputRef}
              containerProps={{
                width: '200px',
                height: '70px'
              }}
              name="campaign-goal"
              onChange={handleChange}
              placeholder="1000"
              required
              type="number"
              variant="goal"
            />
          </Box>

          <Box
            flexDirection="column"
            justifyContent="center"
            ml="auto"
            mr="auto"
            width="100%"
          >
            <Input
              ref={campaignTitleInputRef}
              containerProps={{
                width: '100%',
                mt: '20px',
                height: '75px',
                fontSize: '2rem'
              }}
              label="Campaign Title"
              name="campaign-title"
              onChange={handleChange}
              placeholder="Enter campaign title here"
              required
              variant="campaign-details"
            />

            <Text flexGrow={1} fontSize={2} mt="15px">
              Who are you raising money for?
            </Text>
            <Box className="campaign-target">
              <Text
                ref={campaignTargetTextRef}
                borderBottom="1px solid #CBD5E0"
                flexGrow={1}
                fontWeight="700"
                lineHeight={3}
                mt="10px"
                pl="2.4rem"
              />

              <Button
                color="secondary"
                onClick={async () => {
                  await saveLocalStateToReducer(true);
                  openModal();
                }}
                size="large"
                style={{
                  justifyContent: 'center',
                  marginLeft: '10px',
                  marginTop: '15px',
                  fontSize: '16px'
                }}
                variant="outlined"
              >
                Select
              </Button>
            </Box>

            <Box mt="30px">
              <FormControlLabel
                control={(
                  <PurpleSwitch
                    checked={details.isPrivate || false}
                    name="campaign-private"
                    onChange={handleChange}
                  />
                )}
                label="Private"
              />
            </Box>

            <Text fontSize={2} mt="30px">
              The platform is free for organizers. Transaction fee applies. By
              continuing, you agree to the Sportheadz terms and you acknowledge
              receipt of our privacy policy.
            </Text>
            <Button
              color="secondary"
              onClick={submitAndGoToNext}
              size="large"
              style={{
                marginTop: '15px',
                height: '45px',
                fontSize: '16px'
              }}
              variant="contained"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { CreateCampaignDetails };
