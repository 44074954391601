import React, { Fragment } from 'react';
import { Box, Text, Img } from '../../components/elements';
import DefaultProductImage from '../../images/image-placeholder.png';

const Product = ({ campaign, donation }) => {
  return (
    <Box
      bg="#fff"
      boxShadow="0 1px 5px 1px rgba(0, 0, 0, 0.15)"
      p="10px"
      width="100%"
    >
      <Box borderColor="primary.0" justifyContent="space-between">
        {campaign.image ? (
          <Img height="100px" src={campaign.image} width="150px" />
        ) : (
          <Img height="100px" src={DefaultProductImage} width="150px" />
        )}
        <Box flexDirection="column" ml="20px">
          <Text fontWeight="bold">{campaign.title}</Text>
          <Text fontSize="12px">{campaign.target.name}</Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Product;
