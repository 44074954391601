import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider
} from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import { useCampaigns } from '../../features/campaign';
import Config from '../../config';
import { Box } from '../elements';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { debug, error } from '../../utils/logging';

export default function PayeeEditableTable({ payees }) {
  const { currentCampaign, setNewPayeeForm, fetchPayees } = useCampaigns();
  const [state, setState] = useState({
    columns: [
      // { title: 'ID', field: 'id' },
      {
        title: 'Display Name',
        field: 'displayName',
        cellStyle: {
          width: 320,
          maxWidth: 320,
          fontSize: 16
        },
        headerStyle: {
          width: 320,
          maxWidth: 320,
          fontSize: 16
        }
      },
      {
        title: 'Stripe Account',
        field: 'stripeUserId',
        cellStyle: {
          width: 320,
          maxWidth: 320,
          fontSize: 16
        },
        headerStyle: {
          width: 320,
          maxWidth: 320,
          fontSize: 16
        },
        editComponent: (props) => <div />
      },
      {
        cellStyle: { fontSize: 16 },
        headerStyle: { fontSize: 16 }
      }
    ],
    data: [
      {
        id: '32',
        diaplayName: 'Some guy fund1',
        stripeAccount: 'acct_2234234324'
      },
      {
        id: '33',
        diaplayName: 'Some guy fund2',
        stripeAccount: 'acct_2234234325'
      },
      {
        id: '34',
        diaplayName: 'Some guy fund3',
        stripeAccount: 'acct_2234234326'
      },
      {
        id: '35',
        diaplayName: 'Some guy fund4',
        stripeAccount: 'acct_2234234327'
      }
    ]
  });
  const [dashboardLinks, setDashboardLinks] = useState(null);

  const theme = createMuiTheme({
    overrides: {
      MuiInput: { root: { height: '30px' } },
      MuiIconButton: {
        root: {
          // color: '#fff',
          color: '#A7C957 !important',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            transition: 'all 0.5s ease-in-out'
          }
        },
        active: { color: '#fff' }
      }
    },
    palette: {
      primary: {
        main: '#A7C957',
        light: '#b8d378',
        dark: '#748c3c',
        contrastText: '#fff'
      }
    },
    typography: { htmlFontSize: 16 }
  });

  const getAllSavedData = (formData) => {
    const saved = {
      currentCampaign,
      newPayeeForm: formData
    };
    const jsonSaved = JSON.stringify(saved);
    debug('all saved data:', jsonSaved);
    return jsonSaved;
  };

  const updatePayee = async (newData) => {
    return fetcher({
      method: 'PUT',
      url: `${Config.addPayeeEndpoint}?id=${newData.pkPayee}&displayname=${newData.displayName}&isdelete=false`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const deletePayee = async (oldData) => {
    return fetcher({
      method: 'PUT',
      url: `${Config.addPayeeEndpoint}?id=${oldData.pkPayee}&displayname=${oldData.displayName}&isdelete=true`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <MaterialTable
        actions={[
          // {
          //   icon: 'settings',
          //   tooltip: 'Dashboard',
          //   onClick: (event, rowData) =>
          //     window.open('https://dashboard.stripe.com', '_blank')
          // },
          {
            icon: 'attach_money',
            tooltip: 'Accounting',
            onClick: (event, rowData) => {
              debug('Accounting clicked');
              navigate(`/payee/${rowData.pkPayee}/accounting`);
            }
          }
        ]}
        columns={state.columns}
        components={{
          Header: (props) => <MTableHeader {...props} />,
          Toolbar: (props) => (
            <div style={{ backgroundColor: '#fff' }}>
              <MTableToolbar {...props} />
            </div>
          )
        }}
        data={payees}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve) => {
              debug('onRowAdd', { newData });
              const { displayName } = newData;

              const payeeFormData = { displayName };
              setNewPayeeForm(payeeFormData);
              const encodedState = getAllSavedData(payeeFormData);
              window.location = `https://connect.stripe.com/oauth/authorize?redirect_uri=${Config.webServerHost}addpayee&response_type=code&client_id=${Config.stripeConnectClientId}&state=${encodedState}&scope=read_write`;

              resolve();
              //   setTimeout(() => {
              //     resolve()
              //     setState(prevState => {
              //       const data = [...prevState.data]
              //       data.push(newData)
              //       return { ...prevState, data }
              //     })
              //   }, 600)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              debug('onRowUpdate', { newData });

              debug('make network call');
              updatePayee(newData)
                .then((res) => {
                  debug(res);
                  fetchPayees();
                  resolve();
                })
                .catch((err) => {
                  error(err);
                  resolve();
                });

              // setTimeout(() => {
              //   resolve()
              //   if (oldData) {
              //     setState(prevState => {
              //       const data = [...prevState.data]
              //       data[data.indexOf(oldData)] = newData
              //       return { ...prevState, data }
              //     })
              //   }
              // }, 600)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve) => {
              debug('onRowDelete', { oldData });

              debug('make network call');
              deletePayee(oldData)
                .then((res) => {
                  debug(res);
                  fetchPayees();
                  resolve();
                })
                .catch((err) => {
                  error(err);
                  resolve();
                });
              // setTimeout(() => {
              //   resolve()
              //   setState(prevState => {
              //     const data = [...prevState.data]
              //     data.splice(data.indexOf(oldData), 1)
              //     return { ...prevState, data }
              //   })
              // }, 600)
            })
        }}
        options={{
          grouping: false,
          actionsColumnIndex: 3,
          search: false,
          headerStyle: {
            backgroundColor: '#A7C957',
            color: '#FFF',
            fontSize: 16
          },
          searchFieldStyle: { fontSize: '16px' }
        }}
        search={false}
        style={{ fontSize: 16 }}
        title="Payee Management"
        // detailPanel={[
        //   {
        //     tooltip: 'Show Name',
        //     render: rowData => {
        //       return (
        //         <div
        //           style={{
        //             fontSize: 16,
        //             textAlign: 'center',
        //             color: 'white',
        //             backgroundColor: '#43A047',
        //           }}
        //         >
        //           {JSON.stringify(rowData)}
        //         </div>
        //       )
        //     },
        //   },
        // ]}
      />
    </MuiThemeProvider>
  );
}
