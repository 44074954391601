import React, { useEffect, useState } from 'react'
import { Box, Text } from '../../components/elements'
import { fetcher } from '../../network/fetcher'
import Config from '../../config'
import DonationsTable from '../../components/material/donationsTable'
import { debug, error } from '../../utils/logging';

const Billing = ({ campaign }) => {
  const [donations, setDonations] = useState(null)
  useEffect(() => {
    fetcher({
      method: 'GET',
      url: `${Config.getDonationsEndpoint}?campaignId=${campaign.pkCampaign}`,
    }).then(result => {
      debug(`fetch donations ${campaign.pkCampaign}`, { result })
      setDonations(result.data.data)
    })
  }, [])

  if (!donations) {
    return <Box>There are no donations for this campaign</Box>
  }
  return (
    <Box flexDirection="column">
      <DonationsTable donations={donations}></DonationsTable>
    </Box>
  )
}
export { Billing }
