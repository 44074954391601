import React, { useReducer, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  IoIosCloseCircleOutline,
  IoIosCheckmarkCircleOutline,
} from 'react-icons/io'
import { Box, Button, Input, Text } from '../../components/elements'
import { Message, Loading } from '../../components/compound'
import { useAuthService } from './auth.provider'
import { RegexList, checkString, LengthRequirement } from '../../utils/password'

const propTypes = {
  changeView: PropTypes.func,
  fullScreen: PropTypes.bool,
  navigate: PropTypes.func,
}

const defaultProps = {
  changeView: null,
  fullScreen: false,
  navigate: null,
}

const initialState = {
  Email: '',
  Username: '',
  Password: '',
  ConfirmPassword: '',
  hasCapital: false,
  hasNumber: false,
  hasSymbol: false,
  hasLength: false,
  passwordMatch: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'input': {
      return {
        ...state,
        [action.name]: action.payload,
      }
    }

    case 'password': {
      return {
        ...state,
        Password: action.payload,
        hasCapital: checkString(action.payload, RegexList.capRegex),
        hasNumber: checkString(action.payload, RegexList.numRegex),
        hasSymbol: checkString(action.payload, RegexList.symRegex),
        hasLength: action.payload.length >= LengthRequirement,
        passwordMatch: action.payload === state.ConfirmPassword,
      }
    }

    case 'confirmPassword': {
      return {
        ...state,
        ConfirmPassword: action.payload,
        passwordMatch: action.payload === state.Password,
      }
    }

    case 'reset': {
      return {
        Email: '',
        Username: '',
        Password: '',
        ConfirmPassword: '',
        hasCapital: false,
        hasNumber: false,
        hasSymbol: false,
        hasLength: false,
        passwordMatch: false,
      }
    }

    default:
      return state
  }
}

const CreateAccount = ({ changeView, fullScreen, navigate }) => {
  const {
    onReset,
    onCreateAccount,
    error,
    clearErrors,
    loading,
    user,
    isWaitingConfirmSignUp,
  } = useAuthService()
  const emailRef = useRef()

  const [formState, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (emailRef && emailRef.current) {
      emailRef.current.focus()
    }

    onReset()
    // FORCE RESET
    dispatch({
      type: 'reset',
      payload: null,
    })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    onCreateAccount(formState)
  }

  const handleChange = e => {
    const { value, name } = e.target

    let action = 'input'
    switch (name) {
      case 'Password':
        action = 'password'
        break
      case 'ConfirmPassword':
        action = 'confirmPassword'
        break
      default:
        break
    }
    dispatch({
      type: action,
      name,
      payload: value,
    })
  }

  const viewSwitch = view => {
    clearErrors()

    if (fullScreen) {
      navigate(`/${view}`)
      return
    }

    changeView(view)
  }

  const {
    hasCapital,
    hasNumber,
    hasSymbol,
    hasLength,
    passwordMatch,
  } = formState
  const isDisabled =
    !hasCapital || !hasNumber || !hasSymbol || !hasLength || !passwordMatch
  // const showSuccess = user && !loading && !error
  if (isWaitingConfirmSignUp && !loading && !error) {
    viewSwitch('confirm')
  }

  return (
    <Box flexDirection="column" minWidth="10rem" width="100%">
      <Text fontSize={5} fontWeight={[0]} mb={5}>
        Create Account
      </Text>
      <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
        <Input
          ref={emailRef}
          containerProps={{
            width: '100%',
            height: '70px',
          }}
          label="Email"
          name="Email"
          onChange={handleChange}
          required
          type="email"
          value={formState.Email}
          variant="register"
        />
        <Input
          containerProps={{
            width: '100%',
            mt: 4,
            height: '70px',
          }}
          label="Password"
          name="Password"
          onChange={handleChange}
          required
          type="password"
          value={formState.Password}
          variant="register"
        />
        <Input
          containerProps={{
            width: '100%',
            mt: 4,
            height: '70px',
          }}
          label="Confirm Password"
          name="ConfirmPassword"
          onChange={handleChange}
          required
          type="password"
          value={formState.ConfirmPassword}
          variant="register"
        />
        <Input
          containerProps={{
            width: '100%',
            mt: 4,
            height: '70px',
          }}
          label="First Name"
          name="FirstName"
          onChange={handleChange}
          required
          value={formState.FirstName}
          variant="register"
        />
        <Input
          containerProps={{
            width: '100%',
            mt: 4,
            height: '70px',
          }}
          label="Last Name"
          name="LastName"
          onChange={handleChange}
          required
          value={formState.LastName}
          variant="register"
        />
        <Box flexDirection="column" mt={3}>
          <Box alignItems="center">
            <Box color={hasLength ? 'green' : 'red'} mr={2}>
              {hasLength ? (
                <IoIosCheckmarkCircleOutline />
              ) : (
                <IoIosCloseCircleOutline />
              )}
            </Box>
            Must be {LengthRequirement} characters or longer
          </Box>

          <Box alignItems="center">
            <Box color={hasCapital ? 'green' : 'red'} mr={2}>
              {hasCapital ? (
                <IoIosCheckmarkCircleOutline />
              ) : (
                <IoIosCloseCircleOutline />
              )}
            </Box>
            Must have a capital letter
          </Box>

          <Box alignItems="center">
            <Box color={hasNumber ? 'green' : 'red'} mr={2}>
              {hasNumber ? (
                <IoIosCheckmarkCircleOutline />
              ) : (
                <IoIosCloseCircleOutline />
              )}
            </Box>
            Must have a number
          </Box>

          <Box alignItems="center">
            <Box color={hasSymbol ? 'green' : 'red'} mr={2}>
              {hasSymbol ? (
                <IoIosCheckmarkCircleOutline />
              ) : (
                <IoIosCloseCircleOutline />
              )}
            </Box>
            Must have a symbol
          </Box>

          <Box alignItems="center">
            <Box color={passwordMatch ? 'green' : 'red'} mr={2}>
              {passwordMatch ? (
                <IoIosCheckmarkCircleOutline />
              ) : (
                <IoIosCloseCircleOutline />
              )}
            </Box>
            Password must match
          </Box>
        </Box>

        {error && (
          <Message mt={5} variant="error">
            {error}
          </Message>
        )}

        {loading ? (
          <Box mt={5}>
            <Loading dotBackgroundColor="black" />
          </Box>
        ) : (
          <Button
            alignSelf="flex-start"
            disabled={isDisabled}
            mt={5}
            type="submit"
            variant="login"
          >
            Sign up
          </Button>
        )}

        <Box alignItems="center" mt={3}>
          Already a Sportshead?
          <Button
            ml={2}
            onClick={() => viewSwitch('login')}
            type="button"
            variant="regularlink"
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

CreateAccount.propTypes = propTypes
CreateAccount.defaultProps = defaultProps

export { CreateAccount }
