import React, { useEffect, useState } from 'react'
import { Box, Text, Input } from '../../components/elements'
import { TextField } from '@material-ui/core'
import { useCampaigns } from '.'
import { Button } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { debug, error } from '../../utils/logging';

const CreateCampaignStory = ({ changeView }) => {
  const { setCurrentCampaign, currentCampaign } = useCampaigns()
  //const [story, setStory] = useState('')
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    if (currentCampaign && currentCampaign.story) {
      const blocksFromHtml = htmlToDraft(currentCampaign.story)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      )
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }, [currentCampaign])

  const viewSwitch = view => {
    debug('switching to', view)
    changeView(view)
  }

  const theme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        root: {
          fontSize: '20px !important',
          zIndex: 999,
        },
      },
      MuiInputBase: {
        input: {
          backgroundColor: '#f7f5f5',
        },
        inputMultiline: {
          padding: '20px',
        },
      },
      MuiInput: {
        root: {
          fontSize: '16px',
        },
      },
    },
  })

  const onEditorStateChange = editorState => {
    setEditorState(editorState)
  }

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <Box
          bg="white"
          mt="20px"
          width={['100%', '500px']}
          ml="auto"
          mr="auto"
          boxShadow="0 0 1rem 0rem rgba(0,0,0,0.3)"
          flexDirection="column"
        >
          <Box className="campaign-details" p="50px" flexDirection="column">
            <Text ml="auto" mr="auto" fontWeight="700" fontSize={4} mb="20px">
              Tell Your Story
            </Text>
            {/* <TextField
              style={{ fontSize: '16px' }}
              id="outlined-multiline-static"
              label="Explain why you are raising money..."
              multiline
              rows="20"
              value={story}
              onChange={e => setStory(e.target.value)}
              //variant="outlined"
              color="secondary"
              size="large"
            /> */}

            <Box>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="story-editor"
                onEditorStateChange={e => {
                  onEditorStateChange(e)
                }}
              />
            </Box>

            <Button
              fullWidth
              style={{
                marginTop: '15px',
                height: '45px',
                fontSize: '16px',
              }}
              size="large"
              variant="contained"
              color="secondary"
              onClick={async () => {
                debug('Next button was clicked')

                //save campaign with new story value
                const html = draftToHtml(
                  convertToRaw(editorState.getCurrentContent()),
                )
                const savedData = { ...currentCampaign, story: html }
                await setCurrentCampaign(savedData)
                viewSwitch('payee')
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
        <Box mt="20px" justifyContent="center">
          <Text
            css={`
              text-decoration-line: underline;
              cursor: pointer;
            `}
            onClick={() => {
              viewSwitch('preview')
            }}
          >
            Back
          </Text>
        </Box>
      </MuiThemeProvider>
    </React.Fragment>
  )
}

export { CreateCampaignStory }
