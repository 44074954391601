import React, { useEffect } from 'react'
import { Box, Text, Input, Button } from '../../components/elements'
import { navigate } from '@reach/router'
import { debug, error } from '../../utils/logging';

const CreateCampaignSummary = ({ changeView }) => {
  useEffect(() => {}, [])

  const handleChange = () => {
    debug('handle change for input')
  }

  const viewSwitch = view => {
    debug('switching to', view)
    changeView(view)
  }

  return (
    <React.Fragment>
      <Box
        bg="white"
        mt="20px"
        width={['100%', '600px']}
        ml="auto"
        mr="auto"
        //height="800px"
        boxShadow="0 0 1rem 0rem rgba(0,0,0,0.3)"
        flexDirection="column"
      >
        <Button
          fontSize={6}
          width="50px"
          onClick={() => {
            debug('Next button was clicked')
            viewSwitch('payee')
          }}
        >
          &lt;
        </Button>
        <Box
          className="campaign-details"
          // border="1px solid purple"
          p="20px"
          mr="auto"
          ml="auto"
          width={['100%', '400px']}
          flexDirection="column"
        >
          <Text>Summary goes here</Text>
          <Button
            width="100%"
            mt="15px"
            onClick={() => {
              navigate('/campaign/manage')
            }}
            type="button"
            variant="next"
          >
            Return to Manager
          </Button>
        </Box>
      </Box>
    </React.Fragment>
  )
}

export { CreateCampaignSummary }
