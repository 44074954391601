import React from 'react'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CampaignIcon from '@material-ui/icons/CardGiftcard'
import ChequeIcon from '@material-ui/icons/Receipt'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { debug, error } from '../../utils/logging';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    //maxWidth: 500,
  },
})

export default function IconLabelTabs({ tabIndex, setTabIndex }) {
  const classes = useStyles()

  const handleChange = (event, newValue) => {
    debug('tab index', newValue)
    setTabIndex(newValue)
  }

  return (
    <Paper square className={classes.root}>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
      >
        <Tab icon={<CampaignIcon />} label="Campaigns"></Tab>
        <Tab icon={<ChequeIcon />} label="Payments"></Tab>
        <Tab icon={<AddCircleIcon />} label="Create"></Tab>
      </Tabs>
    </Paper>
  )
}
