import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import { Box } from '../elements';

// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     '& > * + *': {
//       marginLeft: theme.spacing(2),
//     },
//   },
// }))

function CircularIndeterminate() {
  // const classes = useStyles()

  return (
    <Box margin="35vh auto 0 auto">
      <CircularProgress color="secondary" />
    </Box>
  );
}

const DimmedLoaderWrapper = ({ children, active, disableDimming }) => {
  if (!active) {
    return children ?? null;
  }
  const loader = (
    <Loader active className="green" indeterminate size="large">
      Loading
    </Loader>
  );
  return (
    <Segment style={{ flex: 1 }}>
      <Dimmer active={active && !disableDimming}>{loader}</Dimmer>
      {disableDimming && <>{loader}</>}
      {children ?? null}
    </Segment>
  );
};

export { CircularIndeterminate, DimmedLoaderWrapper };
