/* eslint-disable object-curly-newline */
import { Divider } from '@material-ui/core';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import Axios from 'axios';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Icon,
  List,
  Radio,
  Segment,
  Step
} from 'semantic-ui-react';
import { Box, Text } from '../../components/elements';
import Config from '../../config';
import { fetcher } from '../../network/fetcher';
import { useCampaigns } from './campaign.provider';
import CreateCampaignContentView from './CreateCampaignContentView';
import SelectTargetView from './SelectTargetView';
import TargetGoalInput from './TargetGoalInput';
import { debug } from '../../utils/logging';

export const CreateCampaignView = () => {
  const [activeItem, setActiveItem] = useState('Introduction');
  const [campaignVisibility, setCampaignVisibility] = useState('public');
  const [isSelectTargetOpen, setSelectTargetOpen] = useState(false);
  const [stepsComplete, setStepsComplete] = useState(0);

  const [contentViewState, setContentViewState] = useState({});

  const {
    fetchAvailablePayees,
    availablePayees,
    currentCampaign,
    setCurrentCampaign
  } = useCampaigns();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (e, { title }) => setActiveItem(title);

  const uploadToS3 = async (file, signedRequest) => {
    debug('uploading to s3 with', signedRequest);
    const options = { headers: { 'Content-Type': file.type } };
    return Axios.put(signedRequest, file, options);
  };

  const validateCampaign = (campaign) => {
    if (!campaign) {
      enqueueSnackbar('Please start your campaign', {
        variant: 'error'
      });
      return false;
    }
    const { title, target, payee, goal } = campaign;
    if (!title) {
      enqueueSnackbar('Incomplete campaign: Missing title', {
        variant: 'error'
      });
      return false;
    }
    if (!target) {
      enqueueSnackbar('Incomplete campaign: Missing target', {
        variant: 'error'
      });
      return false;
    }
    if (!payee) {
      enqueueSnackbar('Incomplete campaign: Missing payee', {
        variant: 'error'
      });
      return false;
    }
    if (!goal) {
      enqueueSnackbar('Incomplete campaign: Missing goal', {
        variant: 'error'
      });
      return false;
    }
    return true;
  };

  const fileUploadSubmit = async () => {
    debug('fileUploadSubmit');
    const response = await fetcher({
      method: 'GET',
      url: `${Config.getS3SignEndpoint}?filename=${currentCampaign.fileImage.file.name}&filetype=${currentCampaign.fileImage.file.type}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });

    const { url } = response.data;
    const result = await uploadToS3(currentCampaign.fileImage.file, url);
    debug(result);

    const s3FullpathUrl = url.split('?')[0];
    const newCampaign = { ...currentCampaign, image: s3FullpathUrl };
    debug(newCampaign);
    setCurrentCampaign(newCampaign);
  };

  useEffect(() => {
    const upload = async () => {
      if (!currentCampaign.image && currentCampaign.fileImage) {
        try {
          await fileUploadSubmit();
        } catch (err) {
          debug(err);
        }
      }
    };
    debug('onLoad');
    upload();
  }, [currentCampaign]);

  useEffect(() => {
    let baseState = { ...currentCampaign };
    if (contentViewState.editorState) {
      const html = draftToHtml(
        convertToRaw(contentViewState.editorState.getCurrentContent())
      );
      baseState = { ...baseState, story: html };
    }
    if (contentViewState.campaignTitle) {
      baseState = { ...baseState, title: contentViewState.campaignTitle };
    }
    setCurrentCampaign(baseState);
  }, [contentViewState]);

  useEffect(() => {
    fetchAvailablePayees();
  }, []);

  const SelectTargetInput = () => {
    return (
      <Box
        style={{
          flexDirection: 'column'
        }}
        width={['100%', '67%']}
      >
        <Text
          flexGrow={1}
          mt="15px"
          style={{ fontSize: 16, fontWeight: '700' }}
        >
          Campaign Target
        </Text>
        <Box className="campaign-target">
          <Text
            borderBottom="1px solid #CBD5E0"
            flexGrow={1}
            fontWeight="700"
            lineHeight={3}
            mt="10px"
            textAlign="center"
          >
            {currentCampaign && currentCampaign.target
              ? currentCampaign.target.name
              : 'Please select a target...'}
          </Text>
          <Button
            color="green"
            onClick={() => {
              setSelectTargetOpen(true);
            }}
            size="large"
            style={{
              justifyContent: 'center',
              marginLeft: '10px',
              marginTop: '15px',
              fontSize: '16px'
            }}
            variant="outlined"
          >
            Browse
          </Button>
        </Box>
      </Box>
    );
  };

  const CreateCampaignMenu = () => {
    return (
      <Step.Group attached="top">
        <Step
          active={activeItem === 'Introduction'}
          completed={stepsComplete > 0}
          link
          onClick={handleClick}
          title="Introduction"
        >
          <Icon name="info" />
          <Step.Content>
            <Step.Title>Introduction</Step.Title>
            <Step.Description>Tell us about your campaign</Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={activeItem === 'Content'}
          completed={stepsComplete > 1}
          disabled={stepsComplete < 1}
          link
          onClick={handleClick}
          title="Content"
        >
          <Icon name="images" />
          <Step.Content>
            <Step.Title>Content</Step.Title>
            <Step.Description>Add some text & images</Step.Description>
          </Step.Content>
        </Step>

        <Step
          active={activeItem === 'Finalize'}
          completed={stepsComplete > 2}
          disabled={stepsComplete < 2}
          link
          onClick={handleClick}
          title="Finalize"
        >
          <Icon name="payment" />
          <Step.Content>
            <Step.Title>Finalize</Step.Title>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  };

  return (
    <Box style={{ flex: 1, justifyContent: 'center' }}>
      <Segment
        basic
        style={{
          flex: 1,
          maxWidth: 1150,
          marginTop: 0,
          marginLeft: -1,
          marginRight: -1
        }}
      >
        <Form size="huge">
          <CreateCampaignMenu />
          <Segment
            style={{
              marginTop: 0,
              marginLeft: -1,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              width: 'calc(100% + 2px)'
            }}
          >
            {activeItem === 'Introduction' && (
              <Box>
                <SelectTargetView
                  onClose={(value) => {
                    setCurrentCampaign({ ...currentCampaign, target: value });
                    setSelectTargetOpen(false);
                  }}
                  open={isSelectTargetOpen}
                />
                {/* <CampaignTargetSelector closeModal={closeModal} /> */}
                <Box style={{ flex: 1, flexDirection: 'column' }}>
                  <Box flexDirection={['column', 'row']}>
                    <SelectTargetInput />
                    <Box display={['none', 'flex']}>
                      <Divider
                        orientation="vertical"
                        style={{ height: 100, marginLeft: 20, marginRight: 20 }}
                      />
                    </Box>
                    <Box display={['flex', 'none']}>
                      <Divider
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          width: '100%'
                        }}
                      />
                    </Box>
                    <TargetGoalInput
                      onChange={(value) =>
                        setCurrentCampaign({ ...currentCampaign, goal: value })
                      }
                      value={
                        currentCampaign && currentCampaign.goal
                          ? currentCampaign.goal
                          : ''
                      }
                    />
                  </Box>

                  <Divider style={{ marginTop: 15, marginBottom: 15 }} />
                  <Box style={{ flexDirection: 'column' }}>
                    <Text
                      fontWeight="700"
                      style={{ fontSize: 16 }}
                      // mt="20px"
                    >
                      Campaign Visibility
                    </Text>
                    <Form.Field>
                      <Radio
                        checked={campaignVisibility === 'public'}
                        label="Public"
                        name="checkboxRadioGroup"
                        onChange={() => setCampaignVisibility('public')}
                        radio
                        value="this"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        checked={campaignVisibility === 'unlisted'}
                        label="Unlisted"
                        name="checkboxRadioGroup"
                        onChange={() => setCampaignVisibility('unlisted')}
                        radio
                        value="that"
                      />
                    </Form.Field>
                    <Button
                      color="green"
                      disabled={
                        !currentCampaign ||
                        !currentCampaign.target ||
                        !currentCampaign.goal ||
                        currentCampaign.goal.length < 1
                      }
                      onClick={() => {
                        if (stepsComplete < 1) {
                          setStepsComplete(1);
                        }
                        setActiveItem('Content');
                      }}
                      style={{ alignSelf: 'flex-end' }}
                    >
                      Next
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            {activeItem === 'Content' && (
              <CreateCampaignContentView
                contentState={contentViewState}
                onEditorChange={(state) =>
                  setContentViewState({
                    ...contentViewState,
                    editorState: state
                  })
                }
                onSubmit={() => {
                  setStepsComplete(2);
                  setActiveItem('Finalize');
                }}
                onTitleChange={(title) =>
                  setContentViewState({
                    ...contentViewState,
                    campaignTitle: title
                  })
                }
              />
            )}
            {activeItem === 'Finalize' && (
              <Box style={{ flexDirection: 'column' }}>
                <Box flexDirection={['column', 'row']}>
                  <Box
                    style={{
                      textAlign: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                    width={['100%', '60%']}
                  >
                    <Header as="h1">
                      <Icon name="rocket" />
                      <Header.Content>
                        Almost there!
                        <Header.Subheader>
                          Get ready to launch.
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <Text>
                      Please select your payee account. This is the account that
                      will receive the donations, manage the transactions, and
                      receive the payout.
                    </Text>
                  </Box>
                  <Divider
                    orientation="vertical"
                    style={{ height: 'auto', marginLeft: 15, marginRight: 15 }}
                  />
                  <Box
                    marginTop={['20px', '0px']}
                    style={{ flex: 1, flexDirection: 'column' }}
                  >
                    <Text
                      flexGrow={1}
                      mt="15px"
                      style={{ fontSize: 16, fontWeight: '700' }}
                    >
                      Payee Account
                    </Text>
                    <List divided fluid selection style={{ width: '100%' }}>
                      {availablePayees &&
                        availablePayees.map((payee) => {
                          return (
                            <List.Item
                              active={
                                currentCampaign &&
                                currentCampaign.payee &&
                                payee.pkPayee === currentCampaign.payee.payeeId
                              }
                              onClick={() =>
                                setCurrentCampaign({
                                  ...currentCampaign,
                                  payee: { payeeId: payee.pkPayee }
                                })
                              }
                            >
                              <List.Icon
                                color={
                                  currentCampaign &&
                                  currentCampaign.payee &&
                                  payee.pkPayee ===
                                    currentCampaign.payee.payeeId
                                    ? 'green'
                                    : undefined
                                }
                                name={
                                  currentCampaign &&
                                  currentCampaign.payee &&
                                  payee.pkPayee ===
                                    currentCampaign.payee.payeeId
                                    ? 'check square outline'
                                    : 'square outline'
                                }
                                size="large"
                              />
                              <List.Content>
                                <List.Header>{payee.displayName}</List.Header>
                              </List.Content>
                            </List.Item>
                          );
                        })}
                    </List>
                  </Box>
                </Box>
                <Button
                  color="green"
                  disabled={
                    !currentCampaign ||
                    !currentCampaign.target ||
                    !currentCampaign.goal ||
                    currentCampaign.goal.length < 1 ||
                    !currentCampaign.payee ||
                    !contentViewState.campaignTitle ||
                    contentViewState.campaignTitle.length < 1 ||
                    !contentViewState.editorState ||
                    contentViewState.editorState
                      .getCurrentContent()
                      .getPlainText().length < 1 ||
                    !currentCampaign ||
                    (!currentCampaign.video &&
                      !currentCampaign.image &&
                      !currentCampaign.fileImage)
                  }
                  onClick={async () => {
                    debug(currentCampaign);
                    if (validateCampaign(currentCampaign)) {
                      const result = await fetcher({
                        method: 'POST',
                        url: `${Config.addCampaignEndpoint}`,
                        data: {
                          ...currentCampaign,
                          isPrivate: campaignVisibility === 'unlisted'
                        },
                        headers: {
                          Authorization: `Bearer ${(await Auth.currentSession())
                            .getIdToken()
                            .getJwtToken()}`
                        }
                      });
                      debug(result);
                      setCurrentCampaign(null);
                      navigate(`/campaign/donate/${result.data.campaignId}`);
                    }
                  }}
                  style={{ alignSelf: 'flex-end', marginTop: 20 }}
                >
                  Finish
                </Button>
              </Box>
            )}
          </Segment>
        </Form>
      </Segment>
    </Box>
  );
};
