import React, { useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { Box } from '../elements'
import { useCampaigns } from '../../features/campaign'

export default function PayeeRadioButtons({ availablePayees }) {
  const { setCurrentCampaign, currentCampaign } = useCampaigns()
  const [payee, setPayee] = React.useState(null) //this will store pkPayee

  useEffect(() => {
    if (currentCampaign && currentCampaign.payee) {
      //set campaign's payee
      const campaignPayee = availablePayees.filter(
        p => p.pkPayee === currentCampaign.payee.payeeId,
      )[0]
      setPayee(campaignPayee.pkPayee)
    }
  }, [currentCampaign])

  const handleChange = event => {
    const pkPayeeSelected = event.target.value
    setPayee(pkPayeeSelected)

    const pkPayeeNum = parseInt(pkPayeeSelected, 10)
    const selectedPayee = availablePayees.filter(
      p => p.pkPayee === pkPayeeNum,
    )[0]

    const payee = { payeeId: selectedPayee.pkPayee }
    const newCurrentCampaign = {
      ...currentCampaign,
      payee,
    }
    setCurrentCampaign(newCurrentCampaign)
  }

  //   : 24
  // stripeUserId: "acct_1GVi01GCgEIx2V3g"
  // refreshToken: "rt_H3pfRlkZPwiqgBK36JwKRcPVIMNf2Oak1LwnVi6GbBhFVSFG"
  // accessToken: "sk_test_pzZyUw7OlblMNoIuqUFPZW9f00y3PbVPC0"
  // stripePublishableKey: "pk_test_MdN8Z7h5dGdCILOX9245EePP00IxyalczL"
  // liveMode: false
  // fkUser: 5

  const renderOptions = () => {
    return availablePayees.map(a => (
      <FormControlLabel
        key={a.pkPayee}
        value={a.pkPayee}
        control={<Radio />}
        label={a.displayName}
      />
    ))
  }

  const renderAvailablePayees = () => {
    if (availablePayees) {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">Available Payees</FormLabel>
          <RadioGroup
            aria-label="payee"
            name="payee"
            value={payee}
            onChange={handleChange}
          >
            {renderOptions()}
            {/* <FormControlLabel value="female" control={<Radio />} label="Female" />
          <FormControlLabel value="male" control={<Radio />} label="Male" />
          <FormControlLabel value="other" control={<Radio />} label="Other" />
          <FormControlLabel value="disabled" disabled control={<Radio />} label="(Disabled option)" /> */}
          </RadioGroup>
        </FormControl>
      )
    } else {
      return <div>No payees available</div>
    }
  }

  return (
    <Box justifyContent="center" mt="10px">
      {renderAvailablePayees()}
    </Box>
  )
}
