import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Box } from '../elements';
import { PurpleSwitch } from './purple-switch';

export default function TargetFilter({ targetFilter, setTargetFilter }) {
  const handleChange = (event) => {
    setTargetFilter({
      ...targetFilter,
      [event.target.name]: event.target.checked
    });
  };

  return (
    <FormGroup>
      <Box>
        <FormControlLabel
          control={(
            <PurpleSwitch
              checked={targetFilter.showTeams}
              name="showTeams"
              onChange={handleChange}
            />
          )}
          label="Show Teams"
        />
        {/* <FormControlLabel
          control={
            <PurpleSwitch
              checked={targetFilter.showPlayers}
              name="showPlayers"
              onChange={handleChange}
            />
          }
          label="Show Players"
        /> */}
      </Box>
    </FormGroup>
  );
}
export { PurpleSwitch };
