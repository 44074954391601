import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Box, Text } from '../../components/elements';
import IconLabelTabs from '../../components/material/icon-label-tabs';
import CampaignBalanceTable from '../../components/material/campaignBalanceTable';
import AddPayment from './addPayment';
import ChequeTable from '../../components/material/chequeTable';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { useAuthService } from '../auth/auth.provider';
import { debug, error } from '../../utils/logging';

const Accounting = ({ payeeId }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [campaigns, setCampaigns] = useState(null);
  const [payments, setPayments] = useState(null);
  const [update, setUpdate] = useState(new Date());
  const [payee, setPayee] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const { teams } = useAuthService();

  debug('teams::::', teams);

  const fetchAllCampaignsViaPayee = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.getAllCampaignsViaPayee}?payeeId=${payeeId}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const fetchAllPaymentsViaPayee = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.getAllPaymentsViaPayee}?payeeId=${payeeId}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const fetchPayee = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.addPayeeEndpoint}?id=${payeeId}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    fetchAllCampaignsViaPayee()
      .then(result => {
        setCampaigns(result.data.data);
      })
      .catch(err => {
        debug(err);
      });

    fetchAllPaymentsViaPayee()
      .then(result => {
        setPayments(result.data.data);
      })
      .catch(err => {
        debug(err);
      });

    fetchPayee()
      .then(res => {
        debug(res);
        setPayee(res.data.data);
      })
      .catch(err => {
        debug(err);
      });
  }, [payeeId, update]);

  const renderCampaignView = () => {
    return (
      <CampaignBalanceTable
        campaigns={campaigns}
        payee={payee}
        pkPayee={payeeId}
        setSelectedCampaignId={setSelectedCampaignId}
        setTabIndex={setTabIndex}
        teams={teams}
      />
    );
  };

  const renderChequeView = () => {
    return (
      <ChequeTable
        payee={payee}
        payments={payments}
        pkPayee={payeeId}
        selectedCampaignId={selectedCampaignId}
        setSelectedCampaignId={setSelectedCampaignId}
        teams={teams}
        update={update}
      />
    );
  };

  const renderAddChequeView = () => {
    return (
      <AddPayment
        payee={payee}
        pkPayee={payeeId}
        setTabIndex={setTabIndex}
        setUpdate={setUpdate}
        teams={teams}
      />
    );
  };

  debug(payeeId);
  return (
    <Box flexDirection="column" margin="40px auto" maxWidth="90%">
      <IconLabelTabs setTabIndex={setTabIndex} tabIndex={tabIndex} />
      {tabIndex === 0 && renderCampaignView()}
      {tabIndex === 1 && renderChequeView()}
      {tabIndex === 2 && renderAddChequeView()}
    </Box>
  );
};
export { Accounting };
