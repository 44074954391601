import React, { useRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import Config from '../../config';
import { Box, Text, Input } from '../../components/elements';
import { debug, error } from '../../utils/logging';

const EditCampaign = ({ campaign, closeModal, setLastUpdated }) => {
  const campaignTitleInputRef = useRef();
  const campaignGoalInputRef = useRef();
  const [details, setDetails] = useState(null);
  const [story, setStory] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    debug('running useEffect *********************');
    // load values from reducer
    loadInitialValues(campaign);
  }, [campaign]);

  // load from reducer
  const loadInitialValues = (campaign) => {
    const initial = campaign;
    debug('loadInitialValues initial', initial);
    let newDetails = {};
    if (initial) {
      if (
        initial.title &&
        campaignTitleInputRef &&
        campaignTitleInputRef.current
      ) {
        campaignTitleInputRef.current.value = initial.title;
        newDetails = { ...newDetails, title: initial.title };
        debug('loading title from reducer', initial.title);
      }
      if (
        initial.goal &&
        campaignGoalInputRef &&
        campaignGoalInputRef.current
      ) {
        campaignGoalInputRef.current.value = initial.goal;
        newDetails = { ...newDetails, goal: initial.goal };
        debug('loading goal from reducer', initial.goal);
      }
      if (initial.story) {
        setStory(initial.story);
        newDetails = { ...newDetails, story: initial.story };
        debug('loading story from reducer', initial.story);
      }
      if (initial.pkCampaign) {
        newDetails = { ...newDetails, pkCampaign: initial.pkCampaign };
        debug('loading pkCampaign from reducer', initial.pkCampaign);
      }

      setIsComplete(initial.isComplete);
      newDetails = { ...newDetails, isComplete: initial.isComplete };

      setDetails(newDetails);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === 'campaign-title') {
      const isSame = e.target.value === details.title;
      if (!isSame) {
        setDetails({
          ...details,
          title: e.target.value
        });
        if (campaignTitleInputRef && campaignTitleInputRef.current) {
          campaignTitleInputRef.current.value = e.target.value;
        }
      }
    }
    else if (e.target.name === 'campaign-goal') {
      const isSame = e.target.value === details.goal;
      if (!isSame) {
        setDetails({
          ...details,
          goal: Number(e.target.value)
        });
        if (campaignGoalInputRef && campaignGoalInputRef.current) {
          campaignGoalInputRef.current.value = e.target.value;
        }
      }
    }
    else if (e.target.name === 'campaign-story') {
      const isSame = e.target.value === details.story;
      if (!isSame) {
        setStory(e.target.value);
        setDetails({
          ...details,
          story: e.target.value
        });
      }
    }
    else if (e.target.name === 'isCompleteCheck') {
      const isSame = event.target.checked === details.isComplete;
      if (!isSame) {
        setIsComplete(event.target.checked);
        setDetails({
          ...details,
          isComplete: event.target.checked
        });
      }
    }
  };

  return (
    <Box
      className="campaign-details"
      color="text"
      flexDirection="column"
      p="50px"
      width="100%"
    >
      <Text
        fontSize={5}
        fontWeight="700"
        // mt="20px"
        // color={isComplete ? 'lightgrey' : '#5d8000'}
      >
        Enter your goal here
      </Text>
      <Box className="goal" justifyContent="flex-start">
        <Box flexDirection="column">
          <Text
            color={isComplete ? 'lightgrey' : '#5d8000'}
            fontSize={6}
            fontWeight={3}
            ml="10px"
            mr="10px"
            width="20px"
          >
            $
          </Text>
          <Text
            alignSelf="center"
            color={isComplete ? 'lightgrey' : '#5d8000'}
            fontSize={2}
            fontWeight={3}
            mt="-10px"
          >
            CAD
          </Text>
        </Box>
        <Input
          ref={campaignGoalInputRef}
          containerProps={{
            width: '200px',
            height: '70px'
          }}
          disabled={isComplete}
          name="campaign-goal"
          onChange={handleChange}
          placeholder="1000"
          required
          style={{ color: isComplete ? 'lightgrey' : '#5d8000' }}
          type="number"
          variant="goal"
        />
      </Box>
      <Box
        flexDirection="column"
        justifyContent="center"
        ml="auto"
        mr="auto"
        width="100%"
      >
        <Input
          ref={campaignTitleInputRef}
          color={isComplete ? 'lightgrey' : '#1A202C'}
          containerProps={{
            width: '100%',
            mt: '20px',
            height: '75px',
            fontSize: '2rem',
            color: isComplete ? 'lightgrey' : '#1A202C'
          }}
          disabled={isComplete}
          label="Title"
          name="campaign-title"
          onChange={handleChange}
          placeholder="Enter campaign title here"
          required
          style={{ color: isComplete ? 'lightgrey' : '#1A202C' }}
          variant="campaign-details"
        />

        <Box
          as="label"
          color={isComplete ? 'lightgrey' : '#1A202C'}
          fontSize="2"
          mt="10px"
        >
          Description
        </Box>
        <TextField
          color="secondary"
          disabled={isComplete}
          // label="Explain why you are raising money..."
          id="outlined-multiline-static"
          multiline
          name="campaign-story"
          onChange={handleChange}
          rows="20"
          size="large"
          style={{ fontSize: '16px' }}
          value={story}
        />

        {/* <FormControlLabel
          control={(
            <Checkbox
              checked={isComplete}
              name="isCompleteCheck"
              onChange={handleChange}
            />
          )}
          label="End Campaign"
          style={{ marginTop: '20px' }}
        /> */}
        <Button
          color="secondary"
          onClick={async () => {
            debug('details', details);

            try {
              const result = await fetcher({
                method: 'PUT',
                url: `${Config.editCampaignEndpoint}`,
                data: details,
                headers: {
                  Authorization: `Bearer ${(await Auth.currentSession())
                    .getIdToken()
                    .getJwtToken()}`
                }
              });
              debug(result);
              enqueueSnackbar('Successfully edited campaign', {variant: 'success'});
              if (setLastUpdated) {
                debug('setting update flag');
                setLastUpdated(new Date());
              }
              closeModal();
            } catch (error) {
              debug(error);
              enqueueSnackbar('Problem with updating campaign', {variant: 'error'});
            }
          }}
          size="large"
          style={{
            marginTop: '15px',
            height: '45px',
            fontSize: '16px'
          }}
          variant="contained"
        >
          UPDATE
        </Button>
      </Box>
    </Box>
  );
};
export { EditCampaign };
