import React from 'react'
import { PropTypes } from 'prop-types'
import ReactTimePicker from 'react-time-picker/dist/entry.nostyle'
import styled from 'styled-components'

const BaseDatePicker = styled(ReactTimePicker)`
  display: inline-flex;
  position: relative;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  display: inline-flex;
  position: relative;
`

const reactTimePicker = 'react-time-picker'
const disabled = `${reactTimePicker}--disabled`
const wrapper = `${reactTimePicker}__wrapper`
const inputGroup = `${reactTimePicker}__inputGroup`
const divider = `${inputGroup}__divider`
const input = `${inputGroup}__input`
const button = `${reactTimePicker}__button`
const clock = `${reactTimePicker}__clock`

const MyTimePicker = styled(BaseDatePicker).attrs({})`
  .${disabled} {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  .${wrapper} {
    display: flex;
    border: thin solid gray;
  }

  .${inputGroup} {
    @digit-width: .54em;
    @colon-width: .217em;
    // own padding + inputs padding + digits width + dots width
    min-width: calc(~'(4px * 3) + ' @digit-width * 6 ~' + ' @colon-width * 2);
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;
    box-sizing: content-box;

    &__amPm {
      font: inherit;
      -moz-appearance: menulist;
    }
  }

  .${divider} {
    padding: 1px 0;
    white-space: pre;
  }

  .${input} {
    min-width: @digit-width;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:invalid {
      background: fade(red, 10%);
    }

    &--hasLeadingZero {
      margin-left: -@digit-width;
      padding-left: calc(~'1px + ' @digit-width);
    }
  }

  .${button} {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-time-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-time-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  .${clock} {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid rgb(160, 160, 150);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }
  }
`

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
}

const TimePicker = ({ value, onChange }) => (
  <MyTimePicker onChange={onChange} value={value} />
)

TimePicker.propTypes = propTypes

export { TimePicker }
