import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as ReachLink } from '@reach/router';
import { MenuItem } from '@material-ui/core';
import { Box, Link, Text } from '../../components/elements';
import { links } from './links';
import { useAuthService } from '../auth';
import { isInRole } from '../../utils/helpers';

const propTypes = { containerProps: PropTypes.shape({}) };

const defaultProps = { containerProps: {} };

const MobileMenu = ({ containerProps, pathname }) => {
  const [isOpen, toggleMenu] = useState(false);
  const { onLogout, user } = useAuthService();

  // const navLinks = links.public

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleLogout = async () => {
    await onLogout();
    toggleMenu(!isOpen);
  };

  const navLinks = links.homeLinks;
  // const navAdminLinks = isInRole('Administrator') ? links.admin : [];
  // const navWebmasterLinks = isInRole('Webmaster') ? links.webmaster : [];

  return (
    <Box as="nav" role="navigation" {...containerProps}>
      <Box
        css={`
          & input {
            display: flex;
            width: 40px;
            height: 32px;
            position: absolute;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
          }

          & span {
            width: 1.875rem;
            height: 2px;
            z-index: 1;
            margin-bottom: 5px;
            background: #000;
            transform-origin: 5px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
              opacity 0.55s ease;
          }

          & span:first-child {
            transform-origin: 0% 0%;
          }

          & span:nth-last-child(2) {
            transform-origin: 0% 100%;
          }

          & input:checked ~ span {
            opacity: 1;
            transform: rotate(45deg) translate(-3px, -16px);
            background: #36383f;
          }
          & input:checked ~ span:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }

          & input:checked ~ span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, 15px);
          }
        `}
        flexDirection="column"
        position="relative"
      >
        <input
          checked={isOpen}
          onChange={() => toggleMenu(!isOpen)}
          type="checkbox"
        />
        <span />
        <span />
        <span />
      </Box>
      <Box
        as="ul"
        bg="#f7fbec"
        css={`
          position: absolute;
          width: 100vw;
          height: 100vh;
          box-shadow: 0 0 10px #85888c;
          top: 0;

          transform: translateX(100vw);
          -webkit-font-smoothing: antialiased;
          transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

          ${isOpen && 'transform: translateX(-20rem)'}
        `}
        flexDirection="column"
        id="menu"
        pl={4}
        pt={8}
      >
        {user && (
          <MenuItem style={{ borderBottom: '1px solid #d4d4d5' }}>
            <Text fontSize="1.5rem" fontWeight="bold" width="100%">
              {user.fullName}
            </Text>
          </MenuItem>
        )}
        {navLinks.map((link) => (
          <Box key={link.to} as="li" color="primary.0">
            <Link
              as={ReachLink}
              color="text"
              fontFamily="sports"
              fontSize={2}
              fontWeight={1}
              onClick={() => {
                toggleMenu(!isOpen);
              }}
              py={3}
              textStyle="caps"
              to={link.to}
            >
              {link.title}
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

MobileMenu.propTypes = propTypes;
MobileMenu.defaultProps = defaultProps;

export { MobileMenu };
