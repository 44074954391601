import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Input, Text } from '../../components/elements'
import { Message } from '../../components/compound'
import { useAuthService } from './auth.provider'

const propTypes = {
  changeView: PropTypes.func,
}

const defaultProps = {
  changeView: null,
}

const ConfirmAccount = ({ changeView }) => {
  const { onConfirmAccount, error, isConfirmed, email, user } = useAuthService()
  const usernameRef = useRef()

  const [formState, setFormState] = useState({
    Email: email,
    ConfirmationCode: '',
    User: user,
  })

  useEffect(() => {
    if (usernameRef && usernameRef.current) {
      usernameRef.current.focus()
    }
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    onConfirmAccount(formState)
  }

  const handleChange = e => {
    const { value, name } = e.target
    setFormState({
      ...formState,
      [name]: value,
    })
  }

  return (
    <Box flexDirection="column" minWidth="10rem" width="100%">
      <Text fontSize={5} fontWeight={[0]} mb={5}>
        Confirm your account
      </Text>

      {isConfirmed ? (
        <Box flexDirection="column">
          <Text>Your account is now confirmed. Thank you</Text>
          <Button
            alignSelf="flex-start"
            mt={4}
            onClick={() => changeView('login')}
            variant="login"
          >
            Click here to login
          </Button>
        </Box>
      ) : (
        <Box as="form" flexDirection="column" onSubmit={handleSubmit}>
          <Input
            containerProps={{
              width: '100%',
              mt: 4,
              height: '70px',
            }}
            label="Confirmation Code"
            name="ConfirmationCode"
            onChange={handleChange}
            required
            variant="register"
          />

          {error && (
            <Message mt={5} variant="error">
              {error}
            </Message>
          )}
          <Button alignSelf="flex-start" mt={5} type="submit" variant="login">
            Confirm Account
          </Button>
        </Box>
      )}
    </Box>
  )
}

ConfirmAccount.propTypes = propTypes
ConfirmAccount.defaultProps = defaultProps

export { ConfirmAccount }
