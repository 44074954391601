import axios from 'axios'
import Config from '../config'

function getBaseURL() {
  return Config.apiServerHost
}

axios.defaults.baseURL = getBaseURL()

/*

Examples:

Send a POST request

fetcher({
  method: 'post',
  url: '/user/12345',
  data: {
    firstName: 'Fred',
    lastName: 'Flintstone'
  }
})

Send a GET request

fetcher({
  method: 'get',
  url: 'http://bit.ly/2mTM3nY',
  responseType: 'stream'
})

*/

/**
 * fetcher
 *
 * @param {config} - contains url and method properties
 */

function fetcher(config) {
  return axios(config)
}

export { fetcher }
