import LS, { LS_KEYS } from './local-storage';

const chunk = (input, size) =>
  input.reduce(
    (arr, item, idx) =>
      idx % size === 0
        ? [...arr, [item]]
        : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]],
    []
  );

const timeout = (ms) => new Promise((res) => setTimeout(res, ms));

const formatMoney = (number, decPlaces, decSep, thouSep) => {
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === 'undefined' ? '.' : decSep);
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  const sign = number < 0 ? '-' : '';
  const i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );
  var j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, `$1${thouSep}`) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : '')
  );
};

const removeTags = (str) => {
  if (str === null || str === '') return str;

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
};

const truncate = (input, maxLength) => input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

const getInitialsFromName = (name) => {
  if (name == null || name.length === 0) {
    return '';
  }

  let avatarTitle = name[0];
  const familyNameIndex = name.indexOf(' ');

  if (familyNameIndex !== -1) {
    avatarTitle += name[familyNameIndex + 1];
  }

  return avatarTitle;
};

const isInRole = (requiredRole) => {
  return true;
  // const roleArray = LS.getItem(LS_KEYS.roles);
  // if (!roleArray || roleArray.length <= 0) {
  //   return false;
  // }
  // return roleArray.includes(requiredRole);
};

// const getTeam = (campaign, teams) => {
//   if (!teams || teams.length === 0) {
//     return 'Unknown team';
//   }

//   const foundTeams = teams.filter(t => t.ID === campaign.target.teamId);
//   if (!foundTeams || foundTeams.length === 0) {
//     return 'Unknown team';
//   }
//   return foundTeams[0].Name;
// };

export {
  chunk,
  timeout,
  formatMoney,
  getInitialsFromName,
  removeTags,
  truncate
};
