import { keyframes, css } from 'styled-components';

export const buttonLinkStyles = ({ theme, variant, size = 'medium' }) => {
  const baseStyles = `
    display: inline-flex;
    text-decoration: none;
    border: none;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-size: ${theme.fontSizes[2]};
    font-weight: ${theme.fontWeights.base};
    letter-spacing: ${theme.letterSpacings.base};
    background: transparent;
    border-radius: ${theme.radii[1]};
    transition: all 0.2s ease;
  `;

  const recordingStyles = `
    border-radius: 4px;
    border: 1px solid ${theme.colors.greys[4]};
    padding: 0.4rem 1.6rem;

    color: ${theme.colors.greys[4]};
  `;

  const recordingOff = css`
    ${recordingStyles}
    color: ${theme.colors.reds[5]};
    border-color: ${theme.colors.reds[5]};

    div {
      border-color: ${theme.colors.reds[5]};
      background: ${theme.colors.white};
    }
  `;

  const recordingOn = css`
    ${recordingStyles}
    color: ${theme.colors.white};
    border-color: ${theme.colors.reds[5]};
    background: ${theme.colors.reds[5]};

    div {
      border-color: ${theme.colors.white};
      background: ${theme.colors.white};
    }
  `;

  const recordingStateAnimation = keyframes`
    0% {
      ${recordingOff}
    }
    50% {
      ${recordingOn};
    }
    100% {
      ${recordingOff}
    }
  `;

  const channelStateAnimation = keyframes`
    0% {
      background: ${theme.colors.green};
    }
    50% {
      background: ${theme.colors.greys[4]};
    }
    100% {
      background: ${theme.colors.green};
    }
  `;

  let elSize;

  switch (size) {
    case 'giant':
    case 'large':
    case 'medium': {
      elSize = `
        padding: 5px 15px;
      `;

      break;
    }
    case 'small':
      elSize = `
        padding: 3px 10px;
      `;
      break;

    default:
      return ``;
  }

  switch (variant) {
    case 'primary': {
      return `
        ${baseStyles}
        ${elSize}
        background: ${theme.colors.greys[8]};
        color: ${theme.colors.greys[0]}

        &:hover:not(:disabled) {
          background: ${theme.colors.greys[6]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[7]};
        }
      `;
    }

    case 'secondary': {
      return `
        ${baseStyles}
        ${elSize}
        border: 1px solid ${theme.colors.greys[8]};
        color:  ${theme.colors.greys[8]};

        &:hover:not(:disabled) {
          background: ${theme.colors.greys[1]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[2]};
        }
      `;
    }

    case 'tertiary': {
      return `
        ${baseStyles}
        ${elSize}
        padding-top: 7px;
        padding-bottom: 7px;

        &:hover:not(:disabled) {
          background: ${theme.colors.greys[1]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[2]};
        }
      `;
    }

    case 'tertiary-white': {
      return `
          ${baseStyles}
          font-weight: 600;
          color: white;
          ${elSize}
          padding-top: 7px;
          padding-bottom: 7px;
  
          &:hover:not(:disabled) {
            background: ${theme.colors.greys[10]};
          }
  
          &:focus,
          &:active {
            background: ${theme.colors.greys[11]};
          }
        `;
    }

    case 'tertiary-dark': {
      return `
        ${baseStyles}
        ${elSize}
        padding-top: 7px;
        padding-bottom: 7px;

        &:hover:not(:disabled) {
          color: ${theme.colors.greys[8]};
          background: ${theme.colors.greys[1]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[2]};
        }
      `;
    }

    case 'yellowBg': {
      return `
        ${baseStyles}
        font-weight: 400;
        color: white;
        background: ${theme.colors.yellows[0]};
        ${elSize}
        font-size: 17px;
        padding: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-transform: none;

        &:hover:not(:disabled) {
          background: ${theme.colors.greys[10]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[11]};
        }
      `;
    }

    case 'ondemand': {
      return `
        ${baseStyles}
        ${elSize}
        background: ${theme.colors.blues[1]};
        color: ${theme.colors.greys[0]};

        &:hover:not(:disabled) {
          background: ${theme.colors.blues[3]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.blues[2]};
        }
      `;
    }

    case 'regularlink': {
      return `
        ${baseStyles}
        ${elSize}
        color: ${theme.colors.primary};
        border: 1px solid ${theme.colors.primary};
        border-radius: 4px;
        margin-left: 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        &:hover:not(:disabled) {
          background: ${theme.colors.primary};
          color: ${theme.colors.greys[3]};
        }

        &:focus,
        &:active {
          background: none;
        }
      `;
    }

    case 'link': {
      return `
        ${baseStyles}
        ${elSize}
        background: red;
      `;
    }

    case 'navbar': {
      return `
        background: none;
        color: ${theme.colors.greys[0]};
        font-weight: 600;
        border: none;
        padding: 0;

        &:focus,
        &:hover,
        &:active {
          text-decoration: underline;
        }
      `;
    }

    case 'search': {
      return `
        ${elSize}
        width: 50px;
        border-radius: ${theme.radii.searchBarButton};
        background: ${theme.colors.greys[11]};
        color: white;

        &:focus,
        &:active,
        &:hover:not(:disabled) {
          background: ${theme.colors.greys[3]};
          color: ${theme.colors.greys[7]};
        }
      `;
    }

    case 'login': {
      return `
        ${baseStyles}
        ${elSize}
        background: ${theme.muiTheme.palette.success.main};
        color: ${theme.colors.greys[0]};
        border-radius: 15px;
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
        font-size: 18px;
        height: 38.5px;
        &:hover:not(:disabled) {
          background: ${theme.colors.greys[6]};
        }

        &:focus,
        &:active {
          background: ${theme.colors.greys[7]};
        }
      `;
    }

    case 'forgot-password': {
      return `
        ${baseStyles}
        ${elSize}
        text-transform: uppercase;
        color: ${theme.colors.greys[11]};
        border-radius: 15px;
        width: 90%;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        margin-top: -10px;
        margin-bottom: 30px;
        height: 38.5px;
        

        &:focus,
        &:hover,
        &:active {
          text-decoration: underline;
        }
      `;
    }

    case 'highlight-submit': {
      return `
        ${baseStyles}
        ${elSize}
        text-transform: uppercase;
        color: ${theme.colors.greys[11]};
        border-radius: 15px;
        width: 90%;
        justify-content: center;
        text-align: center;
        margin: 0 auto;
        margin-top: -10px;
        margin-bottom: 30px;
        height: 38.5px;
        

        &:focus,
        &:hover,
        &:active {
          text-decoration: underline;
        }
      `;
    }

    case 'warning': {
      return `
      ${baseStyles}
      ${elSize}
      color: ${theme.colors.reds[0]};
      background: ${theme.colors.reds[4]};

      &:hover:not(:disabled) {
        background: ${theme.colors.reds[5]};
      }

      &:focus,
      &:active {
        background: ${theme.colors.reds[5]};
      }
      `;
    }

    case 'onButton': {
      return `
        color: ${theme.colors.white};
        background: ${theme.colors.green};
      `;
    }

    case 'offButton': {
      return `
        color: ${theme.colors.white};
        background: ${theme.colors.greys[4]};
      `;
    }

    case 'updatingOnOffButton': {
      return css`
        color: ${theme.colors.white};
        animation: 1.5s ${channelStateAnimation} infinite;
      `;
    }

    case 'recordingDisabled': {
      return css`
        ${recordingStyles}
      `;
    }

    case 'recordingOff': {
      return css`
        ${recordingOff}
      `;
    }

    case 'recordingOn': {
      return css`
        ${recordingOn}
      `;
    }

    case 'recordingStarting':
    case 'recordingStopping': {
      return css`
        animation: 1.5s ${recordingStateAnimation} infinite;

        div {
          border-color: ${theme.colors.reds[5]};
          background: ${theme.colors.white};
        }
      `;
    }

    default: {
      return `
        ${baseStyles}
        ${elSize}
      `;
    }
  }
};
