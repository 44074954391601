import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar, MTableHeader } from 'material-table';
// import { createMuiTheme } from '@material-ui/core/styles'
// import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import moment from 'moment';
import { formatMoney } from '../../utils/helpers';
import { theme } from '../../styles/theme';
import { debug, error } from '../../utils/logging';

export default function DonationsTable({ donations }) {
  debug(donations);

  const dontationsModels = donations.map(
    ({ key, dateCreated, name, amount }) => {
      return {
        key,
        dateCreated: moment(new Date(dateCreated)).format('DD/MM/YYYY'),
        name,
        amount: `$${formatMoney(amount / 100.0, 2, '.', ',')}`
      };
    }
  );

  const [columns, setColumns] = useState([
    // { title: 'ID', field: 'id' },
    {
      title: 'Date',
      field: 'dateCreated',
      cellStyle: {
        width: 200,
        maxWidth: 200
      },
      headerStyle: {
        width: 200,
        maxWidth: 200
      }
    },
    {
      title: 'Name',
      field: 'name',
      cellStyle: {
        width: 200,
        maxWidth: 200
      },
      headerStyle: {
        width: 200,
        maxWidth: 200
      }
      // editComponent: props => <div></div>,
    },
    {
      title: 'Amount',
      field: 'amount',
      cellStyle: {
        width: 200,
        maxWidth: 200
      },
      headerStyle: {
        width: 200,
        maxWidth: 200
      }
      // editComponent: props => <div></div>,
    }
  ]);

  // const [dashboardLinks, setDashboardLinks] = useState(null)

  // const theme = createMuiTheme({
  //   overrides: {
  //     MuiInput: {
  //       root: {
  //         height: '30px',
  //       },
  //     },
  //     MuiIconButton: {
  //       root: {
  //         //color: '#fff',
  //         color: '#f50057 !important',
  //         '&:hover': {
  //           backgroundColor: 'rgba(0, 0, 0, 0.04)',
  //           transition: 'all 0.5s ease-in-out',
  //         },
  //       },
  //       active: {
  //         color: '#fff',
  //       },
  //     },
  //   },
  //   palette: {
  //     primary: {
  //       light: '#ff4081',
  //       main: '#f50057',
  //       dark: '#c51162',
  //       contrastText: '#fff',
  //     },
  //   },
  //   typography: { htmlFontSize: 10 },
  // })

  return (
    // <MuiThemeProvider theme={theme}>
    <MaterialTable
      columns={columns}
      components={{
        Header: (props) => <MTableHeader {...props} />,
        Toolbar: (props) => (
          <div style={{ backgroundColor: '#43484e', color: '#fff' }}>
            <MTableToolbar {...props} />
          </div>
        )
      }}
      data={dontationsModels}
      options={{
        pageSize: 10,
        actionsColumnIndex: 3,
        search: false,
        headerStyle: {
          backgroundColor: theme.muiTheme.palette.secondary.main,
          color: '#FFF'
        },
        searchFieldStyle: { fontSize: '16px' }
      }}
      search={false}
      title="Donation History"
    />
    // </MuiThemeProvider>
  );
}
