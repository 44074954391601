import React, { useState, useEffect } from 'react'
import { CreateCampaignDetails } from './create-campaign-details'
import { CreateCampaignStory } from './create-campaign-story'
import { CreateCampaignCover } from './create-campaign-cover'
import { CreateCampaignPayee } from './create-campaign-payee'
import { CreateCampaignSummary } from './create-campaign-summary'
import { CampaignCoverPreview } from './campaign-cover-preview'
import { debug, error } from '../../utils/logging';

const cMap = {
  details: CreateCampaignDetails,
  cover: CreateCampaignCover,
  story: CreateCampaignStory,
  payee: CreateCampaignPayee,
  summary: CreateCampaignSummary,
  preview: CampaignCoverPreview,
}

const CampaignViewSwitcher = props => {
  debug('view prop:', props.view)
  const [view, setView] = useState('details')

  useEffect(() => {
    debug('running useEffect for CampaignViewSwitcher')
    if (props.view) {
      debug('Switch to', props.view)
      setView(props.view)
    }
  }, [props.view])

  const changeView = v => {
    setView(v)
  }

  const Component = cMap[view]

  return <Component changeView={changeView} {...props} />
}

export { CampaignViewSwitcher }
