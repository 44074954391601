import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';

const ManageCampaignActions = ({
  onViewClicked,
  onAction,
  campaignFinished
}) => {
  let options = [
    {
      key: 'billing',
      icon: 'history',
      text: 'Donations',
      value: 'billing',
      selected: false,
      onClick: onAction ? () => onAction('billing') : null
    },
    {
      key: 'payout',
      icon: 'dollar',
      text: 'Payout',
      value: 'payout',
      selected: false,
      onClick: onAction ? () => onAction('payout') : null
    }
  ];

  if (!campaignFinished) {
    options = [
      {
        key: 'edit',
        icon: 'edit',
        text: 'Edit',
        value: 'edit',
        selected: false,
        onClick: onAction ? () => onAction('edit') : null
      },
      ...options,
      {
        key: 'end',
        icon: 'delete',
        text: 'End Campaign',
        value: 'end',
        selected: false,
        onClick: onAction ? () => onAction('end') : null
      }
    ];
  }

  return (
    <Button.Group color="green" floated="right" size="medium">
      <Button
        onClick={() => {
          if (onViewClicked) {
            onViewClicked();
          }
        }}
      >
        View
      </Button>
      <Dropdown
        className="button icon"
        floating
        options={options}
        trigger={<></>}
      />
    </Button.Group>
  );
};

export default ManageCampaignActions;
