import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { Button } from 'semantic-ui-react';
import { useCampaigns } from './campaign.provider';
import { Box, Img, Text } from '../../components/elements';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { VideoPlayer } from '../../components/elements/video-player';
import { debug, error } from '../../utils/logging';

const CampaignCoverPreview = ({ changeView }) => {
  const { currentCampaign, setCurrentCampaign } = useCampaigns();
  const [localLoading, setLocalLoading] = useState(false);

  const viewSwitch = (view) => {
    debug('switching to', view);
    changeView(view);
  };

  let sourceImg = null;
  let sourceVid = null;
  if (currentCampaign.video) {
    sourceVid = currentCampaign.video;
  }
  else if (currentCampaign.image) {
    sourceImg = currentCampaign.image;
  }
  else if (currentCampaign.fileImage) {
    sourceImg = currentCampaign.fileImage.imagePreviewUrl;
  }

  debug('playing', sourceVid);
  return (
    <Box style={{ flexDirection: 'column', margin: '0px 15px' }}>
      <Box alignSelf="center" height={['300px']} mt="20px" width={['100%']}>
        {sourceVid ? (
          // <VideoPlayer srcUrl={sourceVid} />
          // <iframe width="100%" height="100%" src={sourceVid}></iframe>
          <Box as="iframe" height="300px" src={sourceVid} width="100%" />
        ) : (
          <Img src={sourceImg} width={['100%']} />
        )}
      </Box>

      <Box justifyContent="center" mt="20px" width="100%">
        <Button
          color="secondary"
          disabled={localLoading}
          onClick={() => {
            setCurrentCampaign({
              ...currentCampaign,
              image: null,
              fileImage: null,
              video: null
            });
            viewSwitch('upload');
          }}
          style={{ width: '50%', fontSize: '16px' }}
          variant="contained"
        >
          {localLoading ? 'Loading...' : 'Change'}
        </Button>
      </Box>
    </Box>
  );
};
export { CampaignCoverPreview };
