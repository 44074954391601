import React from 'react'
import ReactDatePicker from 'react-date-picker/dist/entry.nostyle'
import styled from 'styled-components'
import { lighten } from 'polished'
import { PropTypes } from 'prop-types'

const BaseDatePicker = styled(ReactDatePicker)`
  display: inline-flex;
  position: relative;

  &,
  & *,
  & *:before,
  & *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
`
// DatePicker
const reactDatePicker = 'react-date-picker'
const disabled = `${reactDatePicker}--disabled`
const wrapper = `${reactDatePicker}__wrapper`
const inputGroup = `${reactDatePicker}__inputGroup`
const button = `${reactDatePicker}__button`
const calendar = `${reactDatePicker}__calendar`

// Calendar
const reactCalendar = 'react-calendar'
const doubleView = `${reactCalendar}--doubleView`
const navigation = `${reactCalendar}__navigation`
const monthView = `${reactCalendar}__month-view`

const yearView = `${reactCalendar}__year-view`
const decadeView = `${reactCalendar}__decade-view`
const centuryView = `${reactCalendar}__century-view`

const tile = `${reactCalendar}__tile`

const selectRange = `${reactCalendar}--selectRange`

const nowColor = lighten(0.3, 'rgb(220, 220, 0)')
const hasActiveColor = lighten(0.3, 'rgb(0, 110, 220)')
const activeColor = 'rgb(0, 110, 220)'

const MyDatePicker = styled(BaseDatePicker).attrs({})`
  .${disabled} {
    background-color: rgb(240, 240, 240);
    color: rgb(109, 109, 109);
  }

  .${wrapper} {
    display: flex;
    background-color: #dddddd;
    padding: 0 1rem;
  }

  .${inputGroup} {
    /* @digit-width: .54em;
  @dot-width: .217em; */
    /* // own padding + inputs padding + digits width + dots width */
    /* min-width: calc(~'(4px * 3) + ' @digit-width * 8 ~' + ' @dot-width * 2); */
    min-width: 2rem;
    flex-grow: 1;
    display: flex;
    padding: 0 2px;
    align-items: baseline;
    box-sizing: content-box;

    &__divider {
      padding: 1px 0;
      white-space: pre;
    }

    &__input {
      min-width: 5rem;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:invalid {
        background: fade(red, 10%);
      }

      &--hasLeadingZero {
        margin-left: 0.54em;
        padding-left: calc(~'1px + .54em');
      }
    }
  }

  .${button} {
    border: 0;
    background: transparent;
    padding: 4px 6px;

    &:enabled {
      cursor: pointer;

      &:hover,
      &:focus {
        .react-date-picker__button__icon {
          stroke: rgb(0, 120, 215);
        }
      }
    }

    &:disabled {
      .react-date-picker__button__icon {
        stroke: rgb(109, 109, 109);
      }
    }

    svg {
      display: inherit;
    }
  }

  .${calendar} {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    &--closed {
      display: none;
    }

    .react-calendar {
      border-width: thin;

      width: 350px;
      max-width: 100%;
      background: white;
      /* border: 1px solid rgb(160, 160, 150); */
      font-family: inherit;
      line-height: 1.125em;

      &,
      & *,
      & *:before,
      & *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      button {
        margin: 0;
        border: 0;
        outline: none;

        &:enabled {
          &:hover {
            cursor: pointer;
          }
        }
      }

      .${doubleView} {
        width: 700px;

        .react-calendar__viewContainer {
          display: flex;
          margin: -0.5em;

          > * {
            width: 50%;
            margin: 0.5em;
          }
        }
      }

      .${navigation} {
        height: 44px;
        margin-bottom: 1em;

        button {
          min-width: 44px;
          background: none;

          &:enabled {
            &:hover,
            &:focus {
              background-color: rgb(230, 230, 230);
            }
          }

          &[disabled] {
            background-color: rgb(240, 240, 240);
          }
        }
      }

      .${monthView} {
        &__weekdays {
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.75em;

          &__weekday {
            padding: 0.5em;
          }
        }

        &__weekNumbers {
          font-weight: bold;

          .react-calendar__tile {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.75em;
            padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
          }
        }

        &__days {
          &__day {
            &--weekend {
              color: rgb(209, 0, 0);
            }

            &--neighboringMonth {
              color: rgb(117, 117, 117);
            }
          }
        }
      }

      .${yearView}, .${decadeView}, .${centuryView} {
        .react-calendar__tile {
          padding: 2em 0.5em;
        }
      }

      .${tile} {
        max-width: 100%;
        text-align: center;
        padding: 0.75em 0.5em;
        background: none;

        &:disabled {
          background-color: rgb(240, 240, 240);
        }

        &:enabled {
          &:hover,
          &:focus {
            background-color: rgb(230, 230, 230);
          }
        }

        &--now {
          background: ${nowColor};

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${nowColor}`)};
            }
          }
        }

        &--hasActive {
          background: ${hasActiveColor};

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${hasActiveColor}`)};
            }
          }
        }

        &--active {
          background: ${activeColor};
          color: white;

          &:enabled {
            &:hover,
            &:focus {
              background: ${lighten(0.1, `${activeColor}`)};
            }
          }
        }
      }

      .${selectRange} {
        .react-calendar__tile {
          &--hover {
            background-color: rgb(230, 230, 230);
          }
        }
      }
    }
  }
`

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Date).isRequired,
}

const DatePicker = ({ value, onChange }) => (
  <MyDatePicker onChange={onChange} value={value} />
)

DatePicker.propTypes = propTypes

export { DatePicker }
