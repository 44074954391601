import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useSnackbar } from 'notistack';
import ReactTooltip from 'react-tooltip';
import { Auth } from 'aws-amplify';
import { Box, Text, Img } from '../../components/elements';
import ProgressBar from '../../components/material/progressbar';
import { formatMoney } from '../../utils/helpers';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import LS, { LS_KEYS } from '../../utils/local-storage';
import { debug, error } from '../../utils/logging';

const Payout = ({ campaign, closeModal }) => {
  const [balances, setBalances] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  // const [payouts, setPayouts] = useState(null)
  const [paid, setPaid] = useState(0);
  const [raisedTotal, setRaisedTotal] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const fetchBalance = async pkCampaign => {
    return fetcher({
      method: 'GET',
      url: `${Config.getCampaignBalances}?campaignId=${pkCampaign}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  const fetchPaymentItems = async (pkCampaign) => {
    return fetcher({
      method: 'GET',
      url: `${Config.getPaymentItems}?campaignId=${campaign.pkCampaign}`,
      headers: { Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}` }
    })
  }

  useEffect(() => {
    setLocalLoading(true);
    fetchBalance(campaign.pkCampaign)
      .then(res => {
        setLocalLoading(false);
        debug(res.data.data);
        setBalances(res.data.data);
        setRaisedTotal(res.data.raisedTotal);
      })
      .catch(err => {
        setLocalLoading(false);
        debug(err);
      });
  }, [campaign]);

  useEffect(() => {
    setLocalLoading(true);

    fetchPaymentItems(campaign.pkCampaign)
      .then(res => {
        debug('<<<<<<<<<<<<<<', res.data.data);
        const amountArray = res.data.data;
        const paymentTotal = amountArray
          .map(a => a.amount)
          .reduce((a, b) => a + b, 0);
        const newPaid = { ...paid };
        setPaid(paymentTotal);
      })
      .catch(err => {
        debug(err);
      });
  }, [campaign]);

  const renderProgress = raised => {
    if (campaign) {
      const finalBalance = raised / 100.0;
      const percentage = (finalBalance / (campaign.goal * 1.0)) * 100;
      return (
        <>
          <Box className="target" mt="20px">
            <Text fontSize="24px" fontWeight="900">
              {`$${formatMoney(finalBalance, 2, '.', ',')}`}
            </Text>
            <Text alignSelf="flex-end" fontSize="1.5rem" margin="5px">
              raised of ${campaign.goal} target
            </Text>
          </Box>
          <ProgressBar percentage={percentage} />
        </>
      );
    }
    return (
      <>
        <Box className="target">
          <Text fontSize="24px" fontWeight="200">
            ${campaign.goal}
          </Text>
          <Text alignSelf="flex-end" fontSize="1.5rem" margin="5px">
            target
          </Text>
        </Box>
        <ProgressBar percentage={0} />
      </>
    );
  };

  const getPendingTooltip = () => {
    if (!balances) return '';

    const pendingArray = balances.filter(b => b.status === 'pending');
    if (pendingArray && pendingArray.length > 0) {
      const availableDate = new Date(
        pendingArray[0].available_on * 1000
      ).toLocaleDateString();
      return `Funds will be available on ${availableDate}`;
    }
    return '';
  };

  const renderDetails = (availableAmt, pendingAmt, totalPayoutsAmt) => {
    const formattedAvailableBalanceAmount = `$${formatMoney(
      availableAmt /* - totalPayoutsAmt */,
      2,
      '.',
      ','
    )}`;

    const formattedPendingBalanceAmount = `$${formatMoney(
      pendingAmt,
      2,
      '.',
      ','
    )}`;

    const formattedTotalPayouts = `$${formatMoney(
      totalPayoutsAmt,
      2,
      '.',
      ','
    )}`;

    const items = [
      {
        label: 'Available Balance',
        value: formattedAvailableBalanceAmount
      },
      { label: 'Pending Balance', value: formattedPendingBalanceAmount },
      {
        label: 'Total Payouts',
        value: formattedTotalPayouts
      }
    ];

    return (
      <Box flexDirection="column" mt="20px">
        <Box justifyContent="space-between">
          <Box>
            <Text>Available Balance</Text>{' '}
            <Text fontSize="10px" ml="2px" mt="6px">
              (less fees)
            </Text>
          </Box>
          <Box>
            <Text>{formattedAvailableBalanceAmount}</Text>
          </Box>
        </Box>
        <Box
          borderBottom="1px solid grey"
          justifyContent="space-between"
          pb="5px"
        >
          <Box>
            <Text>Pending Balance</Text>
            <Text fontSize="10px" ml="2px" mt="6px">
              (less fees)
            </Text>
            {pendingAmt > 0 && (
              <i
                className="fa fa-info-circle"
                style={{ margin: '5px 0 0 5px' }}
              />
            )}
          </Box>
          <Box>
            <Text cursor="pointer" data-tip={getPendingTooltip()}>
              {formattedPendingBalanceAmount}
            </Text>
          </Box>
        </Box>
        <Box justifyContent="space-between" mt="5px">
          <Box>
            <Text>Total Paid</Text>
          </Box>
          <Box>
            <Text>{formattedTotalPayouts}</Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderPayoutText = withdrawalableAmt => {
    const base = 'Payout';
    if (withdrawalableAmt <= 0) {
      return base;
    }
    const formattedWithdrawalableAmount = ` $${formatMoney(
      withdrawalableAmt,
      2,
      '.',
      ','
    )}`;
    return `${base} ${withdrawalableAmt}`;
  };

  let available = 0;
  let pending = 0;
  let totalPaid = 0;

  let raisedAmount = 0;

  if (localLoading) {
    return <Box>Fetching balances...</Box>;
  }

  // calculate all display values
  if (balances) {
    // raised amount
    raisedAmount = balances.map(a => a.net).reduce((a, b) => a + b, 0);
    raisedAmount /= 100.0;

    // available amount
    const availableArray = balances.filter(b => b.status === 'available');
    const availableBalance = availableArray
      .map(a => a.net)
      .reduce((a, b) => a + b, 0);
    available = availableBalance / 100.0;

    // pending amount
    const pendingBalanceArray = balances.filter(b => b.status === 'pending');
    const pendingBalance = pendingBalanceArray
      .map(p => p.net)
      .reduce((a, b) => a + b, 0);
    pending = pendingBalance / 100.0;

    totalPaid = paid / 100.0;
  }

  return (
    <Box
      className="campaign-summary"
      flex={1}
      /* border="1px solid green" */ minWidth={[
        'none',
        'none',
        'none',
        'none',
        '368px'
      ]}
    >
      <ReactTooltip />
      <Box
        bg="white"
        borderRadius=".25rem"
        flex={1}
        flexDirection="column"
        height="fit-content"
        margin={['0 0', '0 0', '0 20px']}
        padding="24px 24px"
        position="relative"
      >
        <Text fontSize="24px" fontWeight="900">
          {campaign.title}
        </Text>
        <Img src={campaign.image} />
        {renderProgress(raisedTotal)}
        {renderDetails(available, pending, totalPaid)}
      </Box>
    </Box>
  );
};
export { Payout };
