import React, { useState } from 'react';
import { Button, Icon, Image, Item, Label } from 'semantic-ui-react';
import { format } from 'date-fns';
import CardMedia from '@material-ui/core/CardMedia';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { Box } from '../../components/elements';
import { formatMoney } from '../../utils/helpers';
import ManageCampaignActions from './manage-campaign-actions';
import { useModal } from '../../components/modules';
import { Billing } from './billing';
import { EditCampaign } from './edit-campaign';
import { Payout } from '../payout/payout';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';
import { debug, error } from '../../utils/logging';

const ManageCardContainer = ({ campaigns, setLastUpdated }) => {
  const { openModal, closeModal, isOpen, Modal } = useModal();
  const [mode, setMode] = useState(null);
  const [updatedDate, setUpdatedDate] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  if (!campaigns) {
    return null;
  }

  const getDescription = (c) => {
    return `This campaign was set for a goal of $${formatMoney(
      c.goal,
      2,
      '.',
      ','
    )} that was created on ${format(new Date(c.dateCreated), 'MMM L, yyyy')}.`;
  };

  const endCampaign = async (pkCampaign) => {
    try {
      const result = await fetcher({
        method: 'PUT',
        url: `${Config.endCampaignEndpoint}?campaignId=${pkCampaign}`,
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        }
      });
      debug(result);
      enqueueSnackbar('Successfully ended campaign', { variant: 'success' });
      setLastUpdated(new Date());
    } catch (error) {
      debug(error);
      enqueueSnackbar('Problem with ending campaign', { variant: 'error' });
    }
  };

  const renderBrowseCard = (campaign) => {
    const {
      pkCampaign,
      goal,
      title,
      isPrivate,
      target,
      isComplete,
      image,
      video,
      story
    } = campaign;
    return (
      <Item key={pkCampaign}>
        {image && (
          <Item.Image
            className="browse-card-item"
            size="small"
            src={image || video}
          />
        )}
        {video && (
          <CardMedia
            component="iframe"
            src={video}
            style={{
              width: '100%',
              flex: '1',
              backgroundSize: 'cover',
              maxWidth: '160px',
              maxHeight: '160px',
              marginRight: '1.5em'
            }}
          />
        )}

        <Item.Content>
          <Item.Header
            as="a"
            onClick={() => {
              navigate(`donate/${pkCampaign}`);
            }}
          >
            {title}
          </Item.Header>
          <Item.Meta>
            <span>{target.entityName}</span>
          </Item.Meta>
          <Item.Description>{getDescription(campaign)}</Item.Description>
          <Item.Extra>
            <ManageCampaignActions
              campaignFinished={isComplete}
              onAction={(action) => {
                if (action === 'end') {
                  endCampaign(pkCampaign);
                }
                else {
                  setSelectedCampaign(campaign);
                  setMode(action);
                  setUpdatedDate(new Date());
                  openModal();
                }
              }}
              onViewClicked={() => {
                navigate(`donate/${pkCampaign}`);
              }}
            />
            <Label style={{ textTransform: 'uppercase' }}>
              {target.entityType}
            </Label>
            <Label style={{ textTransform: 'uppercase' }}>
              {isPrivate ? 'private' : 'public'}
            </Label>
            <Label style={{ textTransform: 'uppercase' }}>
              {isComplete ? 'complete' : 'active'}
            </Label>
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  };

  const renderCampaigns = () => {
    if (campaigns && campaigns.length > 0) {
      return campaigns
        .sort((a, b) => {
          if (a.PkCampaign > b.PkCampaign) return 1;
          if (b.PkCampaign > a.PkCampaign) return -1;

          return 0;
        })
        .map((c) => {
          return renderBrowseCard(c);
        });
    }
  };

  return (
    <Box
      boxShadow="0 0.3125rem 1rem -0.1875rem rgb(0 0 0 / 20%)"
      justifySelf="center"
      margin={['auto', 'auto', 'auto', '1.875rem auto']}
      maxWidth="1000px"
      width="100%"
    >
      <Modal
        closeBtnCb={closeModal}
        containerProps={{ width: ['100%', '450px'] }}
        isOpen={isOpen}
        onBgClick={closeModal}
        onCancel={closeModal}
        onEscapeKey={closeModal}
      >
        {mode === 'payout' && (
          <Payout campaign={selectedCampaign} closeModal={closeModal} />
        )}
        {mode === 'billing' && (
          <Billing campaign={selectedCampaign} closeModal={closeModal} />
        )}
        {mode === 'edit' && (
          <EditCampaign
            campaign={selectedCampaign}
            closeModal={closeModal}
            setLastUpdated={setLastUpdated}
          />
        )}
      </Modal>
      <Item.Group
        divided
        style={{ width: '100%', background: 'white', padding: '1.875rem' }}
      >
        {renderCampaigns()}
      </Item.Group>
    </Box>
  );
};

export { ManageCardContainer };
