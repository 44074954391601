import React, { useState } from 'react'
import { CreateAccount } from './create-account'
import { Login } from './login'
import { ConfirmAccount } from './confirm-account'
import { ForgotPassword } from './forgot-password'
import { ResetPassword } from './reset-password'

const cMap = {
  login: Login,
  'create-account': CreateAccount,
  'forgot-password': ForgotPassword,
  'reset-password': ResetPassword,
  confirm: ConfirmAccount,
}

const AuthViewSwitcher = props => {
  const [view, setView] = useState('login')

  const changeView = v => {
    setView(v)
  }

  const Component = cMap[view]

  return <Component changeView={changeView} {...props} />
}

export { AuthViewSwitcher }
