import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Header, Image, Modal } from 'semantic-ui-react';
import { Box } from '../../components/elements';
import Config from '../../config';
import { fetcher } from '../../network/fetcher';
import TargetCard from './targetcard';

const SelectTargetView = ({ open: _open, onClose }) => {
  const [open, setOpen] = useState(_open);
  const [availableTargets, setAvailableTargets] = useState(null); // useState([])
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [targetFilter, setTargetFilter] = useState({
    showTeams: true,
    showPlayers: false
  });

  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  const createTarget = (name, image, id, entity) => {
    return {
      name,
      image,
      id,
      entity
    };
  };

  const AddToTargets = (targets, target) => {
    if (targets) {
      const index = targets.findIndex(
        (t) => t.id === target.id && t.entity === target.entity
      );
      const alreadyExist = index >= 0;
      if (!alreadyExist) {
        targets.push(target);
      }
    }
  };

  const buildTargets = (data) => {
    const targets = [];
    const { teams } = data;
    teams.forEach((team) => {
      // debug(team);

      // Add teams to target collection
      const teamTarget = team.team;
      const target = createTarget(
        teamTarget.name,
        teamTarget.image,
        teamTarget.pkTeam,
        'team'
      );
      AddToTargets(targets, target);

      // Add association to target collection
      const { association } = teamTarget;
      const associationTarget = createTarget(
        association.name,
        association.image,
        association.pkAssociation,
        'association'
      );
      AddToTargets(targets, associationTarget);

      const teamMembers = team.members;
      teamMembers.forEach((memberTarget) => {
        const targetUser = createTarget(
          memberTarget.name,
          memberTarget.avatar,
          memberTarget.pkUser,
          'user'
        );
        AddToTargets(targets, targetUser);
      });
    });

    targets.sort((a, b) => {
      if (a.entity === b.entity) {
        return 0;
      }
      if (a.entity > b.entity) {
        return 1;
      }
      return -1;
    });
    return targets;
  };

  const fetchTargets = async () => {
    return fetcher({
      method: 'GET',
      url: `${Config.getAvailableTargetsEndpoint}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
  };

  useEffect(() => {
    setLocalLoading(true);
    fetchTargets()
      .then((res) => {
        setLocalLoading(false);

        const targets = buildTargets(res.data);
        setAvailableTargets(targets);
      })
      .catch((err) => {
        setLocalLoading(false);
      });
  }, []);

  const renderTargetCards = () => {
    if (availableTargets) {
      const filteredTargets = availableTargets.filter((a) => {
        if (!targetFilter.showTeams && a.entity === 'team') {
          return false;
        }
        if (!targetFilter.showPlayers && a.entity === 'user') {
          return false;
        }
        return true;
      });

      // debug('filteredTargets', filteredTargets);

      return filteredTargets.map((a) => (
        <TargetCard
          key={`${a.entity}${a.id}`}
          onClick={(t) => setSelectedTarget(t)}
          selectedTarget={selectedTarget}
          target={a}
        />
      ));
    }
    return <div>No targets found</div>;
  };

  return (
    <Modal
      onClose={() => {
        onClose(selectedTarget);
      }}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>Select a Target</Modal.Header>
      <Modal.Content image scrolling>
        <Box
          flexGrow="1"
          flexWrap="wrap"
          justifyContent="center"
          maxHeight="600px"
          minHeight="240px"
          overflowY="auto"
          width="100%"
        >
          {renderTargetCards()}
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => onClose(selectedTarget)}>
          Cancel
        </Button>
        <Button
          content="Ok, let's go!"
          disabled={selectedTarget == null}
          icon="checkmark"
          labelPosition="right"
          onClick={() => onClose(selectedTarget)}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SelectTargetView;
