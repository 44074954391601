import React from 'react'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TumblrShareButton,
  LinkedinIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share'
import PropTypes from 'prop-types'
import { Box, Text, Button } from '../../components/elements'
import { useModal } from '../../components/modules/modal'
import { debug, error } from '../../utils/logging';

const propTypes = {
  shareUrl: PropTypes.string.isRequired,
  closeModal: PropTypes.func,
  campaignId: PropTypes.number.isRequired,
}

const defaultProps = {
  shareUrl: '',
  closeModal: () => {},
  campaignId: 0,
}

const ShareContent = ({ campaignId, shareUrl, closeModal: closeSelf }) => {
  const { openModal, closeModal, isOpen, Modal } = useModal()

  return (
    <Box flex="1" flexDirection={['column']} justifyContent={['center']}>
      <Modal
        closeBtnCb={closeModal}
        containerProps={{
          width: ['100%', '700px'],
          height: ['100%', 'auto'],
          minHeight: 'unset',
        }}
        isOpen={isOpen}
        onBgClick={closeModal}
        onCancel={closeModal}
        onEscapeKey={closeModal}
      >
        <Box flexDirection="column" fontWeight="600">
          <Box as="p">
            <code>{`<script>`}</code>
          </Box>
          <Box as="p" pl="20px">
            <code>{`(function (w, d, s, o, f, js, fjs) {
        w["JS-Widget"] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script", "mw", "https://dev.fundraiser.sportsheadz.com/fundraiser.js");
      mw("campaign", ${campaignId});`}</code>
          </Box>
          <Box as="p">
            <code>{`</script>`}</code>
          </Box>
          <Box as="p">
            <code>{`<div class="sportsheadzfundraiser"></div>`}</code>
          </Box>
        </Box>
      </Modal>
      <Text fontSize={5} fontWeight={[0]} mb={5}>
        Share
      </Text>
      <Text alignSelf="center" fontSize={2} fontWeight={[0]}>
        Select one of the following ways to share this fundraiser.
      </Text>
      <Text
        alignSelf="center"
        fontWeight="600"
        mt="20px"
        mb="20px"
        fontSize={2}
      >
        {shareUrl}
      </Text>
      <Box flex="1" flexDirection={['row']} justifyContent={['center']}>
        <Box m="5px">
          <FacebookShareButton
            hashtag="#SportsheadzFundraiser"
            onShareWindowClose={closeSelf}
            quote="Support your team!"
            url={shareUrl}
          >
            <FacebookIcon round size={32} />
          </FacebookShareButton>
        </Box>
        <Box m="5px">
          <TwitterShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            title="Support your team!"
            url={shareUrl}
          >
            <TwitterIcon round size={32} />
          </TwitterShareButton>
        </Box>
        <Box m="5px">
          <LinkedinShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            url={shareUrl}
          >
            <LinkedinIcon round size={32} />
          </LinkedinShareButton>
        </Box>
        <Box m="5px">
          <RedditShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            url={shareUrl}
          >
            <RedditIcon round size={32} />
          </RedditShareButton>
        </Box>
        <Box m="5px">
          <WhatsappShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            url={shareUrl}
          >
            <WhatsappIcon round size={32} />
          </WhatsappShareButton>
        </Box>
        <Box m="5px">
          <TumblrShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            url={shareUrl}
          >
            <TumblrIcon round size={32} />
          </TumblrShareButton>
        </Box>
        <Box m="5px">
          <EmailShareButton
            hashtag="#SportsheadzFundraiser"
            quote="Support your team!"
            url={shareUrl}
          >
            <EmailIcon round size={32} />
          </EmailShareButton>
        </Box>
      </Box>
    </Box>
  )
}

ShareContent.propTypes = propTypes
ShareContent.defaultProps = defaultProps

export { ShareContent }
