/* eslint-disable consistent-return */
/* eslint-disable no-console */
import _ from 'lodash';
import React, { useReducer, useMemo, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import { fetcher } from '../../network/fetcher';
import LS, { LS_KEYS } from '../../utils/local-storage';
import Config from '../../config';
import { debug, error } from '../../utils/logging';

const AuthContext = React.createContext();

const initialState = {
  error: null,
  styleReference: document.querySelector('style[data-styled]'),
  loading: false,
  user: null
};

const getError = (obj) => {
  try {
    if (!obj) {
      return 'Oops! Something went wrong';
    }

    if (obj.response && obj.response.data) {
      if (obj.response.data.error) {
        return obj.response.data.error;
      }
      return obj.response.data;
    }

    if (obj.response && obj.response.status) {
      switch (obj.response.status) {
        case 400:
          return 'Oops! Something went wrong - Bad Request';
        case 403:
          return 'Invalid email or password';
        case 409:
          return 'This account already exists';
        default:
          return `Oops! Something went wrong - Unmapped Error [${obj.response.status}]`;
      }
    }

    if (obj.config && obj.config.url) {
      if (obj.config.url.includes('localhost')) {
        return `Oops. Something went wrong - Server problem: [${obj.message}]`;
      }
    }

    if (obj.response && obj.response.message) {
      if (obj.config.url.includes('localhost')) {
        return `Oops. Something went wrong - [${obj.response.message}]`;
      }
    }

    if (obj.message) {
      if (obj.config.url.includes('localhost')) {
        return `Oops. Something went wrong : [${obj.message}]`;
      }
    }

    return `Oops. Something went wrong`;
  } catch (error) {
    console.error('MAJOR ERROR');
    console.error(error);
    return 'ERROR';
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'error': {
      const error = getError(action.payload);

      return {
        ...state,
        error,
        loading: false
      };
    }

    case 'fetching': {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case 'user-success': {
      return {
        ...state,
        error: null,
        loading: false,
        user: { ...action.payload }
      };
    }

    case 'load-state': {
      return {
        ...state,
        error: null,
        loading: false,
        ...action.payload
      };
    }

    case 'clear-errors': {
      return {
        ...state,
        error: null
      };
    }

    // case 'set-teams': {
    //   return {
    //     ...state,
    //     ...action.payload
    //   };
    // }

    // case 'set-filtered-team': {
    //   return {
    //     ...state,
    //     ...action.payload
    //   };
    // }

    case 'clear-auth': {
      return initialState;
    }

    default:
      return initialState;
  }
};

function AuthProvider(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);

  const addPayee = async (code, displayName) => {
    const result = await fetcher({
      method: 'POST',
      url: `${Config.addPayeeEndpoint}?code=${code}&displayname=${displayName}`,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug(result);
    return result;
  };

  const deserializeAuth = (encodedState) => {
    // deserialize encodedState
    const jsonState = atob(encodedState);
    const stateObject = JSON.parse(jsonState);
    debug('DESERIALIZED:', stateObject);
    dispatch({ type: 'load-state', payload: { ...stateObject } });
  };

  const serializeAuth = () => {
    // serialize current state
    const currentJsonState = JSON.stringify(state);
    const encodedState = new Buffer(currentJsonState).toString('base64');
    debug('SERIALIZED:', encodedState);
    return encodedState;
  };

  const setFilteredTeam = (filteredTeam) => {
    dispatch({ type: 'set-filtered-team', payload: { filteredTeam } });
  };

  const fetchAuthEndpoint = async () => {
    const result = await fetcher({
      method: 'GET',
      url: `${Config.apiServerHost}api/user/auth`,
      withCredentials: true,
      headers: {
        // eslint-disable-next-line prettier/prettier
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`
      }
    });
    debug('fetchAuthEndpoint Result:', { result });
    return result;
  };

  const onLogout = async () => {
    try {
      await Auth.signOut();
      dispatch({ type: 'clear-auth' });
      if (!window.location.pathname.includes('/campaign/donate')) {
        navigate('/');
      }
    } catch (err) {
      debug(`onLogout Error: ${JSON.stringify(err, null, 2)}`);
    }
  };

  const onLogin = async (payload, cb) => {
    dispatch({ type: 'fetching' });
    try {
      const { Email, Password } = payload;

      debug('cognito login with', Email, Password);
      const user = await Auth.signIn(Email, Password);
      debug('cognito result:', user);

      debug('fetch auth endpoint', user.username);
      const { data } = await fetchAuthEndpoint();
      debug('auth result:', data);

      const { fullName, pkUser, sportsheadzUserId } = data;

      dispatch({
        type: 'user-success',
        payload: { fullName, pkUser, sportsheadzUserId }
      });

      enqueueSnackbar('Login successful.', {
        variant: 'success'
      });

      // Fire callback
      if (cb) {
        cb();
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        debug('Auth.signIn: UserNotConfirmedException');
        enqueueSnackbar('User is not confirmed.', {
          variant: 'error'
        });
      }
      else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
        debug('Auth.signIn: PasswordResetRequiredException');
        enqueueSnackbar('Password reset is needed', {
          variant: 'error'
        });
      }
      else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
        debug('Auth.signIn: NotAuthorizedException');
        enqueueSnackbar('The password is incorrect.', {
          variant: 'error'
        });
      }
      else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        debug('Auth.signIn: UserNotFoundException');
        enqueueSnackbar('Email does not exist', {
          variant: 'error'
        });
      }
      else {
        debug(`Auth.signIn Error: ${JSON.stringify(err, null, 2)}`);
        enqueueSnackbar('Sign in error', {
          variant: 'error'
        });
      }
      onLogout();
      dispatch({ type: 'error', payload: err });
    }
  };

  const clearErrors = () => {
    dispatch({ type: 'clear-errors' });
  };

  const onReset = () => {
    dispatch({ type: 'clear-auth' });
  };

  const checkCognitoAuth = async () => {
    try {
      debug('Cognito auth check!');
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      debug('found token', token);

      const { data } = await fetchAuthEndpoint();
      debug('auth result:', data);

      const { fullName, pkUser, sportsheadzUserId } = data;

      dispatch({
        type: 'user-success',
        payload: { fullName, pkUser, sportsheadzUserId }
      });

      enqueueSnackbar('Login successful.', {
        variant: 'success'
      });
    } catch (error) {
      debug(error);
      dispatch({ type: 'clear-auth' });
      navigate('/');
    }
  };

  useEffect(() => {
    checkCognitoAuth();
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      onReset,
      onLogin,
      clearErrors,
      onLogout,
      addPayee,
      setFilteredTeam,
      deserializeAuth,
      serializeAuth
    }),
    // eslint-disable-next-line prettier/prettier
    // eslint-disable-next-line comma-dangle
    [state]
  );
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AuthContext.Provider value={value} {...props} />;
}

function useAuthService() {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuthService must be used within an AuthProvider`);
  }

  return context;
}

export { AuthProvider, useAuthService };
