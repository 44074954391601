import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../elements/box'
import { Text } from '../elements/text'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const messageStyle = ({ theme, variant }) => {
  if (variant === 'error') {
    return `
      border: ${theme.borders.error};
      background: ${theme.colors.reds[0]};
      color: ${theme.colors.reds[6]}
      width: 90%;
      margin: 0 auto;
    `
  }

  return ``
}

const Message = ({ children, ...rest }) => (
  <Box css={messageStyle} p={2} {...rest}>
    <Text>{children}</Text>
  </Box>
)

Message.propTypes = propTypes

export { Message }
