import React, { useState, Fragment } from 'react'
import Button from '@material-ui/core/Button'
import { Box, Text, Img } from '../../components/elements'
import Product from './product'

const CartProduct = ({ campaign, donation }) => {
  return (
    <Fragment>
      <Box flexDirection="column" width={['100%']} className="cart-product">
        <Box>
          <Text fontWeight="600">YOUR DONATION</Text>
        </Box>
        <Box>
          <Product campaign={campaign} donation={donation}></Product>
        </Box>
      </Box>
    </Fragment>
  )
}

export default CartProduct
