import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import { Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { Box, Text, Input } from '../../components/elements';
import Config from '../../config';
import stripeButton from '../../images/stripe-connect.png';
import { fetcher } from '../../network/fetcher';
import { useCampaigns } from '.';
import LS, { LS_KEYS } from '../../utils/local-storage';
import PayeeRadioButtons from '../../components/material/payeeRadioButtons';
import { debug, error } from '../../utils/logging';

const CreateCampaignPayee = ({ changeView }) => {
  const {
    setCurrentCampaign,
    currentCampaign,
    fetchAvailablePayees,
    availablePayees
  } = useCampaigns();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchAvailablePayees();
  }, []);

  const viewSwitch = view => {
    debug('switching to', view);
    changeView(view);
  };

  const validateCampaign = campaign => {
    if (!campaign) {
      enqueueSnackbar('Please start your campaign', {
        variant: 'error'
      });
      return false;
    }
    const { title, target, postal, payee, goal } = campaign;
    if (!title) {
      enqueueSnackbar('Incomplete campaign: Missing title', {
        variant: 'error'
      });
      return false;
    }
    if (!target) {
      enqueueSnackbar('Incomplete campaign: Missing target', {
        variant: 'error'
      });
      return false;
    }
    if (!payee) {
      enqueueSnackbar('Incomplete campaign: Missing payee information', {
        variant: 'error'
      });
      return false;
    }
    if (!goal) {
      enqueueSnackbar('Incomplete campaign: Missing goaln', {
        variant: 'error'
      });
      return false;
    }
    return true;
  };

  const renderSelectorView = () => {
    return (
      <Box alignSelf="center" mt="20px">
        <PayeeRadioButtons availablePayees={availablePayees} />
      </Box>
    );
  };

  return (
    <>
      <Box
        bg="white"
        boxShadow="0 0 1rem 0rem rgba(0,0,0,0.3)"
        flexDirection="column"
        margin="20px auto"
        padding="50px"
        width={['100%', '500px']}
      >
        <Box className="campaign-details" flexDirection="column" width="100%">
          <Box>
            <Text fontSize={4} fontWeight="700" ml="auto" mr="auto">
              Connect to Payee
            </Text>
          </Box>
          <Box mt="20px">
            <Text>
              Please select your payee account. This is the account that will
              receive the donations, manage the transactions and determine the
              payout.
            </Text>
          </Box>

          {renderSelectorView()}

          <Button
            color="secondary"
            onClick={async () => {
              debug('Next button was clicked');
              if (validateCampaign(currentCampaign)) {
                const result = await fetcher({
                  method: 'POST',
                  url: `${Config.addCampaignEndpoint}`,
                  data: currentCampaign,
                  headers: {
                    Authorization: `Bearer ${(await Auth.currentSession())
                      .getIdToken()
                      .getJwtToken()}`
                  }
                });
                debug(result);
                setCurrentCampaign(null);
                navigate(`/campaign/donate/${result.data.campaignId}`);
              }
            }}
            size="large"
            style={{
              marginTop: '15px',
              height: '45px',
              fontSize: '16px'
            }}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box justifyContent="center" mt="20px">
        <Text
          css={`
            text-decoration-line: underline;
            cursor: pointer;
          `}
          onClick={() => {
            viewSwitch('story');
          }}
        >
          Back
        </Text>
      </Box>
    </>
  );
};

export { CreateCampaignPayee };
