import React from 'react'
import PropTypes from 'prop-types'
import { css, keyframes } from 'styled-components'

const defaultProps = {
  areaBackgroundColor: '',
  dotBackgroundColor: '',
}

const propTypes = {
  areaBackgroundColor: PropTypes.string,
  dotBackgroundColor: PropTypes.string,
}

const ell1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ell2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`

const ell3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const styles = (areaBackgroundColor, dotBackgroundColor) => css`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 29px;
  background: ${areaBackgroundColor || ''};

  & div {
    position: absolute;
    top: 10px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: white;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background: ${dotBackgroundColor || ''};
  }

  & div:nth-child(1) {
    left: 6px;
    animation: ${ell1} 0.6s infinite;
  }

  & div:nth-child(2) {
    left: 6px;
    animation: ${ell2} 0.6s infinite;
  }

  & div:nth-child(3) {
    left: 26px;
    animation: ${ell2} 0.6s infinite;
  }

  & div:nth-child(4) {
    left: 45px;
    animation: ${ell3} 0.6s infinite;
  }
`

const Loading = ({ areaBackgroundColor, dotBackgroundColor }) => (
  <div css={styles(areaBackgroundColor, dotBackgroundColor)}>
    <div />
    <div />
    <div />
    <div />
  </div>
)

Loading.propTypes = propTypes
Loading.defaultProps = defaultProps

export { Loading }
