import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { navigate } from '@reach/router';
import { Button, Divider, Header, Segment, TextArea } from 'semantic-ui-react';
import { Box, Text, Img } from '../../components/elements';
import { fetcher } from '../../network/fetcher';
import Config from '../../config';

import { CircularIndeterminate } from '../../components/material/loader';
import { debug } from '../../utils/logging';

const useStyles = makeStyles((theme) => ({
  inputRootMobile: {
    fontSize: '50px',
    color: '#5F7E19'
  },
  inputRoot: {
    fontSize: '120px',
    color: '#5F7E19'
  },
  labelRoot: {
    marginTop: '-10px',
    fontSize: '14px'
  },
  selectRoot: {
    height: '35px',
    fontSize: '20px'
  }
}));

const CampaignTeaser = ({ campaign }) => {
  const screenNarrow = useMediaQuery('(max-width:692px)');

  return (
    <Box
      className="sidebar"
      // boxShadow="0 0.3125rem 1rem -0.1875rem rgba(0,0,0,.2)"
      flex={1}
      // border="1px solid aqua"
      flexDirection="column"
    >
      <Box
        bg="white"
        borderRadius={[0, 0, 8]}
        boxShadow={[0, 0, '0 0.3125rem 1rem -0.1875rem rgba(0,0,0,.2)']}
        className="sidebar"
        // border="1px solid aqua"
        flexDirection="column"
        m={[0, 0, '20px']}
        p="25px"
      >
        <Header
          size="medium"
          style={{
            textAlign: 'center',
            marginBottom: 16,
            fontWeight: screenNarrow ? 'bold' : 'normal'
          }}
        >
          {campaign.title}
        </Header>
        <Box
          bg="white"
          justifyContent="center"
          maxHeight="315px"
          maxWidth={['100%', '100%', '315px']}
        >
          <Img
            alt="avatar"
            height="auto"
            src={
              campaign.image
                ? campaign.image
                : 'https://secureservercdn.net/198.71.233.179/n8t.f9f.myftpupload.com/wp-content/uploads/2019/09/logo.png'
            }
            width="auto"
          />
        </Box>
      </Box>
    </Box>
  );
};

const DonatePage = ({ campaignId }) => {
  const [campaign, setCampaign] = useState(null);
  const [donationAmount, setDonationAmount] = useState('');
  const [message, setMessage] = useState('');

  const classes = useStyles();

  const fetchCampaign = async () => {
    fetcher({
      method: 'GET',
      url: `${Config.getCampaignEndpoint}?id=${campaignId}`
    }).then((result) => {
      debug(`fetch campaign ${campaignId}`, { result });
      setCampaign(result.data.data);
    });
  };

  useEffect(() => {
    debug('running useEffect from CampaignDonation');
    fetchCampaign();
  }, [campaignId]);

  const handleAmountChange = (newVal) => {
    const numbers = /^[0-9]+$/;
    if (
      newVal === '' ||
      (newVal && newVal.length < 6 && newVal.match(numbers))
    ) {
      setDonationAmount(newVal);
    }
  };

  if (!campaign) {
    return (
      <Box>
        <CircularIndeterminate />
      </Box>
    );
  }
  return (
    <Box flexDirection="column">
      <Box
        alignSelf="center"
        className="donation-body"
        flexDirection={['column', 'column', 'row']}
        maxWidth="1100px"
      >
        <Box
          className="main"
          // boxShadow="0 0.3125rem 1rem -0.1875rem rgba(0,0,0,.2)"
          flex={2}
          // border="1px solid orange"
          flexDirection="column"
        >
          <Box
            bg="white"
            borderRadius={[0, 0, 8]}
            boxShadow="0 0.3125rem 1rem -0.1875rem rgba(0,0,0,.2)"
            className="lowermain"
            flex={1}
            // border="1px solid orange"
            flexDirection="column"
            m={[0, 0, '20px']}
            padding="20px"
          >
            <Header
              size="large"
              style={{ fontWeight: 'normal', marginBottom: 0 }}
            >
              Make a Donation
            </Header>
            <Divider style={{ marginBottom: 20 }} />
            <Box>
              <Text
                color="#5F7E19"
                fontSize={['50px', '50px', '120px']}
                lineHeight={['70px', '70px', '136px']}
                mr="20px"
              >
                $
              </Text>
              <FormControl style={{ backgroundColor: '#F3F9E4' }}>
                <TextField
                  // label="Display Name"
                  id="display-name"
                  InputLabelProps={{ classes: { root: classes.labelRoot } }}
                  InputProps={
                    window.innerWidth < 768
                      ? { classes: { root: classes.inputRootMobile } }
                      : { classes: { root: classes.inputRoot } }
                  }
                  onChange={(e) => handleAmountChange(e.target.value)}
                  placeholder="0.00"
                  size="medium"
                  type="text"
                  value={donationAmount}
                />
              </FormControl>
            </Box>

            <Segment basic size="large" style={{ padding: 0, marginTop: 25 }}>
              <Text style={{ fontSize: 16, fontWeight: 600 }}>
                Enter a Message (Optional)
              </Text>
              <TextArea
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Message..."
                rows={6}
                style={{ width: '100%', resize: 'vertical' }}
              />
            </Segment>
            <Box alignSelf="center">
              <Button
                color="green"
                disabled={!(donationAmount && campaignId)}
                onClick={async () => {
                  debug('Donate button clicked');
                  navigate(
                    `/campaign/checkout?amount=${donationAmount}&campaignId=${campaignId}&message=${encodeURIComponent(
                      message
                    )}`
                  );
                }}
                size="large"
                style={{
                  fontSize: '16px',
                  marginTop: '15px',
                  width: '280px',
                  height: '45px'
                }}
                variant="contained"
              >
                Continue
              </Button>
            </Box>
          </Box>
        </Box>
        <CampaignTeaser campaign={campaign} />
      </Box>
    </Box>
  );
};
export { DonatePage };
