// This example shows you how to set up React Stripe.js and use Elements.
// Learn how to accept a payment using the official Stripe docs.
// https://stripe.com/docs/payments/accept-a-payment#web

import React, { useState, Fragment, useRef, useEffect } from 'react';

import {
  CardElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import { navigate } from '@reach/router';
import { Button } from 'semantic-ui-react';
import { Box, Img } from '../../components/elements';
import Config from '../../config';
import { fetcher } from '../../network/fetcher';
import { debug, error } from '../../utils/logging';
import TrustLogo from '../../images/powered-by-stripe.png';

const titleSyle = { fontWeight: 600 };

const paperStyle = {
  boxShadow: '0 1px 5px 1px rgba(0, 0, 0, 0.15)',
  background: '#ffffff',
  borderRadius: '4px',
  pading: '20px',
  width: '100%'
};

const labelStyle = {
  visibility: 'visible',
  textAlign: 'left',
  margin: 0,
  boxSizing: 'border-box',
  fontFamily: `'Montserrat', 'Segoe UI', Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif`,
  fontSize: '15px',
  lineHeight: '1.4em',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: '42px',
  padding: '10px 0',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: '#000',
  fontWeight: '400',
  borderBottom: '1px solid #f0f5fa',
  flex: 1
};

const selectLabelStyle = {
  visibility: 'visible',
  textAlign: 'right',
  margin: 0,
  boxSizing: 'border-box',
  fontFamily: `'Montserrat', 'Segoe UI', Roboto,
        Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif`,
  fontSize: '15px',
  lineHeight: '1.4em',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: '42px',
  padding: '10px 0',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#8898aa',
  fontWeight: '400'
};

const initialBillingDetails = {
  email: '',
  name: '',
  address: null
};

const initialAddressDetails = {
  city: '',
  country: '',
  line1: '',
  line2: '',
  state: ''
};

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#A7C957',
      color: '#000',
      fontWeight: 500,
      fontFamily: 'Montserrat, Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#718096' }
    },
    invalid: {
      iconColor: '#f00',
      color: '#f00'
    }
  }
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement onChange={onChange} options={CARD_OPTIONS} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange
}) => (
  <div className="FormRow" style={{ display: 'flex' }}>
    <label className="FormRowLabel" htmlFor={id} style={labelStyle}>
      {label}
    </label>
    <input
      autoComplete={autoComplete}
      className="FormRowInput"
      id={id}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      style={{
        flex: 3,
        borderBottom: '1px solid rgb(240, 245, 250)',
        backgroundColor: '#fff',
        paddingLeft: '20px'
      }}
      type={type}
      value={value}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  // <button
  //   className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
  //   type="submit"
  //   disabled={processing || disabled}
  // >
  //   {processing ? 'Processing...' : children}
  // </button>

  <Button
    className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
    color="green"
    disabled={processing || disabled}
    style={{ width: '100%', fontSize: '16px' }}
    type="submit"
    // onClick={async () => {
    //   debug('donate click')
    //   handleSubmit()
    // }}
  >
    {processing ? 'Processing...' : children}
  </Button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg height="16" viewBox="0 0 17 17" width="16">
      <path
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        fill="#FFF"
      />
      <path
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        fill="#6772e5"
      />
    </svg>
    {children}
  </div>
);

const ResetButton = ({ onClick }) => (
  <button className="ResetButton" onClick={onClick} type="button">
    <svg height="32px" viewBox="0 0 32 32" width="32px">
      <path
        d="M15,7.05492878 C10.5000495,7.55237307 7,11.3674463 7,16 C7,20.9705627 11.0294373,25 16,25 C20.9705627,25 25,20.9705627 25,16 C25,15.3627484 24.4834055,14.8461538 23.8461538,14.8461538 C23.2089022,14.8461538 22.6923077,15.3627484 22.6923077,16 C22.6923077,19.6960595 19.6960595,22.6923077 16,22.6923077 C12.3039405,22.6923077 9.30769231,19.6960595 9.30769231,16 C9.30769231,12.3039405 12.3039405,9.30769231 16,9.30769231 L16,12.0841673 C16,12.1800431 16.0275652,12.2738974 16.0794108,12.354546 C16.2287368,12.5868311 16.5380938,12.6540826 16.7703788,12.5047565 L22.3457501,8.92058924 L22.3457501,8.92058924 C22.4060014,8.88185624 22.4572275,8.83063012 22.4959605,8.7703788 C22.6452866,8.53809377 22.5780351,8.22873685 22.3457501,8.07941076 L22.3457501,8.07941076 L16.7703788,4.49524351 C16.6897301,4.44339794 16.5958758,4.41583275 16.5,4.41583275 C16.2238576,4.41583275 16,4.63969037 16,4.91583275 L16,7 L15,7 L15,7.05492878 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z"
        fill="#FFF"
      />
    </svg>
  </button>
);

const CheckoutForm = ({ campaign, donation }) => {
  debug('Receiving:', campaign);
  const { enqueueSnackbar } = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  // const [paymentMethod, setPaymentMethod] = useState(null)
  const [billingDetails, setBillingDetails] = useState(initialBillingDetails);
  const [addressDetails, setAddressDetails] = useState(initialAddressDetails);
  const countrySelectorRef = useRef(null);

  useEffect(() => {
    const country = countrySelectorRef.current.value;
    setAddressDetails({ ...addressDetails, country });
  }, []);

  const reset = () => {
    setError(null);
    setProcessing(false);
    // setPaymentMethod(null)
    setBillingDetails({
      email: '',
      name: ''
    });

    setAddressDetails({
      city: '',
      country: countrySelectorRef.current.value,
      line1: '',
      line2: '',
      state: ''
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    try {
      const billing = { ...billingDetails };
      billing.address = addressDetails;

      debug('Send to stripe:', billing);
      const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: billing
      });

      if (payload.error) {
        setError(payload.error);
        enqueueSnackbar(payload.error, { variant: 'error' });
        return;
      }
      // else {
      //   setPaymentMethod(payload.paymentMethod)
      // }

      const { amount: donationAmount, tip, message } = donation;
      const { pkCampaign: campaignId } = campaign;

      // const donationAmount = 100
      // const tip = 15
      // const campaignId = 55
      // const message = 'Goodluck'

      debug(
        `PAID amount=${
          parseInt(donationAmount) * 100
        }&tip=${tip}&campaignId=${campaignId}&message=${message}`
      );

      const result = await fetcher({
        method: 'GET',
        url: `${Config.getPaymentIntent}?amount=${
          parseInt(donationAmount) * 100
        }&tip=${tip}&campaignId=${campaignId}&message=${message}`
      });
      debug(`PAID result`, result);

      const clientSecret = result.data.data.client_secret;
      debug(clientSecret);

      const confirmResult = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: payload.paymentMethod.id },
        { handleActions: false }
      );
      debug('confirmResult', confirmResult);
      const { error: confirmError } = confirmResult;

      if (confirmError) {
        enqueueSnackbar('There was a problem with your payment', {variant: 'error'});
      }
      else {
        enqueueSnackbar('Thank you. The donation was successfully collected.', {variant: 'success'});
        reset();
        navigate(`/campaign/donate/${campaignId}`);
      }
    } catch (error) {
      debug(error);
      enqueueSnackbar('There was a problem with your payment', {variant: 'error'});
    } finally {
      setProcessing(false);
    }
  };

  const { amount, tip } = donation;
  const tipAmount = ((tip * amount) / 100.0).toFixed(2);
  const total = Number(amount) + (donation.tip * donation.amount) / 100.0;
  const totalAmount = total.toFixed(2);

  debug('BILLINGDETAILS', billingDetails);
  return (
    <>
      <div>
        <form className="Form" onSubmit={handleSubmit}>
          <span style={titleSyle}>BILLING INFORMATION</span>
          <div style={paperStyle}>
            <fieldset className="FormGroup">
              <Field
                autoComplete="name"
                id="name"
                label="Name"
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    name: e.target.value
                  });
                }}
                placeholder="Jane Doe"
                required
                type="text"
                value={billingDetails.name}
              />
              <Field
                autoComplete="email"
                id="email"
                label="Email"
                onChange={(e) => {
                  setBillingDetails({
                    ...billingDetails,
                    email: e.target.value
                  });
                }}
                placeholder="janedoe@gmail.com"
                required
                type="email"
                value={billingDetails.email}
              />
              <Field
                autoComplete="address"
                id="address"
                label="Address"
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    line1: e.target.value
                  });
                }}
                placeholder="123 sportsheadz street"
                required
                type="address"
                value={addressDetails.line1}
              />
              <Field
                autoComplete="city"
                id="city"
                label="City"
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    city: e.target.value
                  });
                }}
                placeholder="Kingston"
                required
                type="city"
                value={addressDetails.city}
              />
              <Field
                autoComplete="state"
                id="state"
                label="Province"
                onChange={(e) => {
                  setAddressDetails({
                    ...addressDetails,
                    state: e.target.value
                  });
                }}
                placeholder="Ontario"
                required
                type="state"
                value={addressDetails.state}
              />
              <div className="FormRow" style={{ display: 'flex' }}>
                <label className="select" style={labelStyle}>
                  <span style={{ flex: 1, textAlign: 'right' }}>Country</span>

                  <div id="country" style={{ flex: 3, paddingLeft: '20px' }}>
                    <select
                      ref={countrySelectorRef}
                      name="country"
                      onChange={(e) => {
                        setAddressDetails({
                          ...addressDetails,
                          country: e.target.value
                        });
                      }}
                      style={{
                        fontSize: '15px',
                        lineHeight: '1.4em',
                        borderStyle: 'none',
                        appearance: 'none',
                        outline: 'none',
                        color: '#313b3f',
                        cursor: 'pointer',
                        background: 'transparent',
                        margin: '0 -15px 0 -30px',
                        padding: '0 15px 0 30px',
                        width: '100%'
                      }}
                    >
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="BE">Belgium</option>
                      <option value="BR">Brazil</option>
                      <option selected="selected" value="CA">
                        Canada
                      </option>
                      <option value="CN">China</option>
                      <option value="DK">Denmark</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="DE">Germany</option>
                      <option value="HK">Hong Kong</option>
                      <option value="IE">Ireland</option>
                      <option value="IT">Italy</option>
                      <option value="JP">Japan</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MY">Malaysia</option>
                      <option value="MX">Mexico</option>
                      <option value="NL">Netherlands</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NO">Norway</option>
                      <option value="PT">Portugal</option>
                      <option value="SG">Singapore</option>
                      <option value="ES">Spain</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                    </select>
                  </div>
                </label>
              </div>
            </fieldset>
          </div>

          <div style={{ marginTop: '40px' }} />
          <span style={titleSyle}>PAYMENT INFORMATION</span>
          <div style={paperStyle}>
            <fieldset className="FormGroup" style={{ padding: '10px 20px' }}>
              <CardField
                onChange={(e) => {
                  setError(e.error);
                  setCardComplete(e.complete);
                }}
              />
            </fieldset>
          </div>

          {error && <ErrorMessage>{error.message}</ErrorMessage>}

          <div style={{ marginTop: '40px' }} />
          <SubmitButton
            disabled={!stripe}
            error={error}
            processing={processing}
          >
            Donate
          </SubmitButton>
          <Box
            alignSelf="center"
            display={['flex', 'flex', 'none']}
            maxWidth="400px"
            mt="40px"
            width="100%"
          >
            <Img
              alt="trust logo"
              height="50px"
              maxWidth="400px"
              onError={(err) => {
                debug(`Bad trust image: ${err}`);
              }}
              src={TrustLogo}
              width="100%"
            />
          </Box>
        </form>
      </div>
    </>
  );
};

const ELEMENTS_OPTIONS = {fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }]};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const DetailedCardForm = ({ stripePromise, campaign, donation }) => {
  return (
    <>
      <Box className="AppWrapper" width={['100%', '100%', '100%', '500px']}>
        <Elements options={ELEMENTS_OPTIONS} stripe={stripePromise}>
          <div style={{ width: '100%' }}>
            <CheckoutForm campaign={campaign} donation={donation} />
          </div>
        </Elements>
      </Box>
    </>
  );
};

export default DetailedCardForm;
