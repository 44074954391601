import Config from '../config';

export const debug = (...o) => {
  if (Config.enableConsoleLogging) {
    console.log(o);
  }
};

export const error = (...o) => {
  if (Config.enableConsoleLogging) {
    console.error(o);
  }
};
