import React, { useEffect, useState, useRef } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Box, Text, Input } from '../../components/elements';
import { Albums } from '../facebook/fb-albums';
import { useCampaigns } from './campaign.provider';
import { useModal } from '../../components/modules/modal';
import { YouTube } from '../youtube/youtube';
import { debug, error } from '../../utils/logging';

const CreateCampaignCover = ({ changeView }) => {
  const [mode, setMode] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const { openModal, closeModal, isOpen, Modal } = useModal();
  const { currentCampaign, setCurrentCampaign } = useCampaigns();
  const [updated, setUpdated] = useState(new Date());

  useEffect(() => {
    if (mode) {
      if (mode === 'facebook' || mode === 'youtube') {
        openModal();
      }
      else if (mode === 'preview') {
        viewSwitch('preview');
      }
    }
  }, [mode, updated]);

  useEffect(() => {
    if (selectedImage) {
      const newCampaign = { ...currentCampaign, image: selectedImage };
      setCurrentCampaign(newCampaign);
      setMode('preview');
    }
  }, [selectedImage]);

  const viewSwitch = (view) => {
    debug('switching to', view);
    changeView(view);
  };

  const handleImageChange = (e) => {
    setSelectedImage('');
    e.preventDefault();

    const reader = new FileReader();
    const readerFile = e.target.files[0];

    reader.onloadend = () => {
      const newCampaign = {
        ...currentCampaign,
        fileImage: { file: readerFile, imagePreviewUrl: reader.result }
      };
      setCurrentCampaign(newCampaign);
      setMode('preview');
    };

    reader.readAsDataURL(readerFile);
  };

  const renderModal = () => {
    if (mode === 'facebook') {
      return (
        <Modal
          closeBtnCb={closeModal}
          containerProps={{
            width: ['100%', '900px'],
            height: ['100%', '800px']
          }}
          isOpen={isOpen}
          onBgClick={closeModal}
          onCancel={closeModal}
          onEscapeKey={closeModal}
        >
          <Albums setSelectedImage={setSelectedImage} />
        </Modal>
      );
    }
    if (mode === 'youtube') {
      return (
        <Modal
          closeBtnCb={closeModal}
          containerProps={{
            width: ['100%', '710px'],
            height: ['100%', '250px'],
            minHeight: '0px'
          }}
          isOpen={isOpen}
          onBgClick={closeModal}
          onCancel={closeModal}
          onEscapeKey={closeModal}
        >
          <YouTube changeView={changeView}>YouTube</YouTube>
        </Modal>
      );
    }
  };

  const fileInputEl = useRef(null);

  return (
    <>
      {mode && renderModal()}
      <Input
        ref={fileInputEl}
        className="fileInput"
        onChange={(e) => handleImageChange(e)}
        style={{ visibility: 'hidden', display: 'none' }}
        type="file"
      />
      <Box
        className="campaign-details"
        color="text"
        flexDirection="column"
        justifyContent="center"
        ml="auto"
        mr="auto"
        width={['100%']}
      >
        <Box
          flexDirection="column"
          justifyContent="center"
          ml="auto"
          mr="auto"
          width="100%"
        >
          <Box height="50px" margin="8px">
            <Button
              onClick={() => {
                setMode('facebook');
                setUpdated(new Date());
              }}
              style={{
                width: '100%',
                backgroundColor: '#3b579d',
                color: 'white',
                fontSize: '16px'
              }}
            >
              <Icon name="facebook" />
              Facebook Photo
            </Button>
          </Box>
          <Box height="50px" margin="8px">
            <Button
              color="red"
              onClick={() => {
                setMode('youtube');
                setUpdated(new Date());
              }}
              style={{
                width: '100%',
                fontSize: '16px'
              }}
            >
              <Icon name="youtube" />
              YouTube Video
            </Button>
          </Box>
          <Box height="50px" margin="8px">
            <Button
              color="green"
              onClick={() => fileInputEl.current.click()}
              style={{ width: '100%', fontSize: '16px' }}
            >
              <Icon name="upload" />
              Upload Photo
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { CreateCampaignCover };
