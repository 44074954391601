import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { navigate } from '@reach/router';
import { useSnackbar } from 'notistack';
import ReactTooltip from 'react-tooltip';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Box, Text } from '../elements';
import { formatMoney } from '../../utils/helpers';
import { useModal } from '../modules/modal';
import CampaignSelector from './campaign-selector';

const useStyles = makeStyles((theme) => ({
  inputRoot: { fontSize: '14px' },
  labelRoot: {
    marginTop: '-10px',
    fontSize: '14px'
    // color: 'red',
    // '&$labelFocused': {
    //   color: 'purple',
    // },
  }
}));

export default function PaymentCard({
  paymentAmount,
  setPaymentAmount,
  campaign,
  setCampaign,
  pkPayee,
  leftoverAmount,
  teams
}) {
  const useCardActionStyles = makeStyles((theme) => ({root: { justifyContent: 'space-evenly' }}));

  const useHeaderStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
      width: '100%',
      backgroundColor: '#A7C957',
      color: '#fff'
    }
  }));

  const usePaymentInputStyles = makeStyles((theme) => ({
    inputRoot: { fontSize: '14px' },
    labelRoot: {
      marginTop: '-10px',
      fontSize: '14px'
      // color: 'red',
      // '&$labelFocused': {
      //   color: 'purple',
      // },
    }
  }));

  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: '100%',
      width: '100%'
    },

    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: { transform: 'rotate(180deg)' },
    avatar: { backgroundColor: '#A7C957' },
    icon: { color: '#A7C957' }
  }));
  const { openModal, closeModal, isOpen, Modal } = useModal();

  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const actionClasses = useCardActionStyles();
  const paymentClasses = usePaymentInputStyles();
  const title = campaign
    ? `${campaign.pkCampaign} - ${campaign.title}`
    : `New Payment`;
  return (
    <Box mt="20px">
      <Modal
        closeBtnCb={closeModal}
        containerProps={{ width: ['100%', '450px'] }}
        isOpen={isOpen}
        onBgClick={closeModal}
        onCancel={closeModal}
        onEscapeKey={closeModal}
      >
        <CampaignSelector
          closeModal={closeModal}
          pkPayee={pkPayee}
          setCampaign={setCampaign}
          teams={teams}
        />
      </Modal>
      <ReactTooltip />
      <Card className={classes.root}>
        <CardHeader
          className={headerClasses.root}
          title={title}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Box justifyContent="space-between" m="10px">
            <Button
              // style={{ marginLeft: '20px' }}
              color="secondary"
              onClick={() => {
                openModal();
              }}
              size="medium"
              variant="outlined"
            >
              Select Campaign
            </Button>
            <FormControl
              style={{
                width: '200px',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextField
                color="secondary"
                id="paymountAmount"
                InputLabelProps={{classes: { root: paymentClasses.labelRoot }}}
                InputProps={{ classes: { root: paymentClasses.inputRoot } }}
                label="Payment Amount"
                onChange={(e) => setPaymentAmount(e.target.value)}
                value={paymentAmount}
              />
              <Text color="primary.0" fontSize="8px">
                {leftoverAmount}
              </Text>
            </FormControl>
          </Box>
        </CardContent>
        <CardActions className={actionClasses.root} disableSpacing />
      </Card>
    </Box>
  );
}
