import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { theme } from '../../styles/theme';

const PurpleSwitch = withStyles({
  switchBase: {
    color: theme.muiTheme.palette.secondary.main, // purple[300],
    '&$checked': {
      color: theme.muiTheme.palette.secondary.main // purple[500],
    },
    '&$checked + $track': {
      backgroundColor: theme.muiTheme.palette.secondary.main // purple[500],
    }
  },
  checked: {},
  track: {}
})(Switch);
export { PurpleSwitch };
